import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const FloatingImage: React.FC<{ imageUrl: string }> = ({ imageUrl }) => {
  return <img alt="" className={cx('floatingImage')} src={imageUrl} />;
};
