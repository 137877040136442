import { createSelector } from '@reduxjs/toolkit';

import { FANCY_NUMBER_TITLE } from 'pages/FancyNumber2022/constants';
import {
  selectCategoriesDependsOnFT,
  selectPriceOptions,
} from 'pages/FancyNumber2022/store/mappers/categories';
import { parseTextIntoReactElement } from 'utils/parseTextIntoReactElement';
import { getIsChangeNumber } from 'pages/FancyNumber2022/store/selectors/change';
import { getEnvBaseUrl } from 'pages/FancyNumber2024/router/utils/getEnvBaseUrl';

const getContent = (state) => state.content;
const contentSelector = (key) => (state) => getContent(state)[key];

export const getFilterContent = contentSelector('filter');
export const getNumbersContent = contentSelector('numbers');
export const getErrorContent = contentSelector('error');
export const getFaqContent = contentSelector('faq');
export const getFaqChangeContent = contentSelector('faqChange');
export const getHeaderContent = contentSelector('header');
export const getMiscContent = contentSelector('misc');
export const getRandomContent = contentSelector('random');
export const getPopup = contentSelector('popup');
export const getAlias = contentSelector('alias');
export const getBaseUrl = contentSelector('baseUrl');
export const getConditionsPage = contentSelector('conditionsPage');
export const getConfirmationPage = contentSelector('confirmationPage');
export const getStatusContent = contentSelector('status');
export const getErrorBoundaryContent = contentSelector('errorBoundary');
export const getAnimationsSettings = contentSelector('animations');

export const getCaptchaTexts = (state) => {
  const popup = getPopup(state);
  return popup ? popup?.captchaTexts : undefined;
};
export const getFTnewCategoriesOfBuyNumbers = (state) =>
  getFilterContent(state)?.newCategoriesOfBuyNumbersEnabled;
export const getPromotionContent = contentSelector('promotion');
export const getPromotionCategory = (state) => getPromotionContent(state)?.categoryAlias;
export const getPromotionTags = (state) => getPromotionContent(state)?.tags;
export const getPromotionBanner = (state) => getPromotionContent(state)?.banner;
export const getPromotionContentByPlace = (place, state) =>
  createSelector(getPromotionCategory, getPromotionTags, (category, tags) => ({
    category,
    tag: tags?.[place],
  }))(state);
export const getFilterByAlias = (alias) => (state) =>
  getFilterContent(state).filters.find((item) => item.alias === alias) || {};

export const getFaqByAlias = createSelector(getFaqContent, getAlias, (faq, alias) =>
  alias ?
    {
      content: {
        ...faq.content,
        categories: faq.content.categories.filter(
          (category) => category.alias?.split(',').includes(alias) || !category.alias,
        ),
        data: faq.content.data.map((categoryData) => ({
          ...categoryData,
          tabs: categoryData.tabs.filter(
            (tab) => tab.alias?.split(',').includes(alias) || !tab.alias,
          ),
        })),
      },
    }
  : faq,
);

export const getHeaderByAlias = createSelector(getHeaderContent, getAlias, (header, alias) =>
  alias ?
    {
      ...header,
      title: header?.titles?.[alias] || FANCY_NUMBER_TITLE,
    }
  : header,
);

export const getFilterPlaceholder = (alias, state) => getFilterByAlias(alias)(state).placeholder;
export const getPrices = (state) => getNumbersContent(state).prices;
export const getPricesForChangeNumber = (state) => getNumbersContent(state).pricesForChangeNumbers;
export const getPricesByCategory = (category, state) =>
  getNumbersContent(state).prices?.[category] || {};
export const getPricesForChangeNumberByCategory = (category, state) =>
  getNumbersContent(state).pricesForChangeNumbers?.[category] || {};

export const mapCategoriesPrices = (content, prices, promotionCategory) =>
  selectCategoriesDependsOnFT(content).map((category) => ({
    ...category,
    price: selectPriceOptions(content, category, prices),
    discount: prices[category?.alias]?.discount,
    name: parseTextIntoReactElement(category?.name),
    isShowPrice: !content?.newCategoriesOfBuyNumbersEnabled, // если есть фича-тоггл, то не показывать цену,
    withTag: category?.alias === promotionCategory,
  }));

export const mapCategoriesPricesForChangeNumber = (content, prices) =>
  content.categories.map((category) => ({
    ...category,
    name: category.nameForChangeNumber || category.name,
    price: prices[category?.alias]?.price,
    discount: prices[category?.alias]?.discount,
    isShowPrice: true,
  }));

export const getCategories = createSelector(
  getFilterContent,
  getPrices,
  getPromotionCategory,
  mapCategoriesPrices,
);
export const getCategoriesForChangeNumber = createSelector(
  getFilterContent,
  getPricesForChangeNumber,
  mapCategoriesPricesForChangeNumber,
);

export const getCategoriesByPage = createSelector(
  getCategoriesForChangeNumber,
  getCategories,
  getIsChangeNumber,
  (categoriesChangeNumber, categoriesBuyNumber, isChangeNumber) =>
    isChangeNumber ? categoriesChangeNumber : categoriesBuyNumber,
);
export const getFaqByPage = createSelector(
  getFaqChangeContent,
  getFaqByAlias,
  getIsChangeNumber,
  (faqChangeNumber, faqBuyNumber, isChangeNumber) =>
    isChangeNumber ? faqChangeNumber : faqBuyNumber,
);

export const getPreviewCounters = (state) => getNumbersContent(state).previewCounters;

export const getEmptyErrorContent = (state) => getErrorContent(state).empty;
export const getCommonError = (state) => getErrorContent(state).common;
export const getOrderErrorContent = (state) => getErrorContent(state).order;

// redesign2024
export const getCloudsCarouselContent = contentSelector('cloudsCarousel');
export const getWidgetContent = contentSelector('widget');
export const getMainNumberBlockContent = contentSelector('mainNumberBlock');

export const getWidgetTabsContent = (state) => getWidgetContent(state)?.tabs;
export const getWidgetNoAuthContent = (state) => getWidgetContent(state)?.noAuth;
export const getWidgetButtonsContent = (state) => getWidgetContent(state)?.buttons;
export const getMainNumberEmptyTitle = (state) => getMainNumberBlockContent(state)?.error?.empty;

export const selectIsRedesign2024Enabled = createSelector(
  getContent,
  (state) => state.ftRedesign2024Enabled,
);

export const selectTabId = createSelector(getContent, (state) => state.tabId);

/** Селектор выборки и подготовки (через ф-цию getEnvBaseUrl) базового url FancyNumber2024 */
export const selectBaseUrl = createSelector(getBaseUrl, (url) => getEnvBaseUrl(url));
