import React from 'react';
import {
  HomeOutlineIcon,
  RouterOutlineIcon,
  TabletIcon,
  TvIcon,
  WiFiOutlineIcon,
} from '@beef/ui-kit';

// TODO: Других вариантов пока не придумали!
// TODO: Попробовать придумать как отвязаться от хардкодных значений в case!
/** Функция получения китовой иконки для насыщения массива объектов CustomTabs */
export const choseIcon = (id: string, fill = '#727281', size = 20) => {
  switch (id) {
    case '2':
      return <HomeOutlineIcon {...{ fill, size }} />;
    case '15':
      return <TabletIcon {...{ fill, size }} />;
    case '35':
      return <TvIcon {...{ fill, size }} />;
    case '60':
      return <RouterOutlineIcon {...{ fill, size }} />;
    case '100':
      return <WiFiOutlineIcon {...{ fill, size }} />;
    default:
      return <HomeOutlineIcon {...{ fill, size }} />;
  }
};
