import React, { memo } from 'react';
import { compose } from 'redux';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, connect } from 'react-redux';

import { ymSimMNPAddToCart } from 'utils/analytics/ymCommonEvents';
import { StyledNumber } from 'pages/FancyNumber2024/components/StyledNumber';
import { Row } from 'pages/FancyNumber2024/components/NumbersBlock/components/NumbersList/Row';
import { Price } from 'pages/FancyNumber2024/components/Price';
import {
  getPricesByCategory,
  getPricesForChangeNumberByCategory,
  getPromotionContent,
  selectIsRedesign2024Enabled,
} from 'pages/FancyNumber2022/store/selectors/content';
import { orderNumber } from 'pages/FancyNumber2022/store/actions/cart';
import { setCurrentNumber } from 'pages/FancyNumber2022/store/actions/change';
import { withNumberRowAnalytics } from 'pages/FancyNumber2022/hocs/withNumberRowAnalytics';
import { useIsAuthenticated } from 'pages/FancyNumber2022/hooks/useIsAuthenticated';
import { useFancyNumberLink } from 'pages/FancyNumber2024/router/hooks/useFancyNumberLink';
import { NumberRowLayoutPropsType } from 'pages/FancyNumber2024/components/NumbersBlock/components/NumbersList/types';

// TODO: переписать компонент при рефакторинге
const NumberRowLayout = memo<NumberRowLayoutPropsType>(
  ({ number, price, discount, promotion, onClickNew, onClickChange, isChangeNumber }) => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    /** Ссылка для перехода на страницу условий */
    const conditionsUrl = useFancyNumberLink('conditions');

    /** Функция-обработчик клика (по номеру) при смене номера (isChangeNumber) в АЗ */
    const changeNumber = () => {
      onClickChange();
      navigate(conditionsUrl);
    };

    /** Функция-обработчик клика по номеру */
    const handleClick = () => (isAuthenticated && isChangeNumber ? changeNumber() : onClickNew());

    return (
      <Row
        isBought={number.isBought}
        isInCart={number.isInCart}
        numberNode={
          <StyledNumber
            color={number.isBought || number.isInCart ? 'grey95' : undefined}
            highlightColor="#ffc800"
            isRedesign2024
            isSecondary={number.isBought}
            number={number}
            size="size3-m"
          />
        }
        onClick={handleClick}
        priceNode={<Price discount={discount} price={price} size="size3-m" />}
        tagText={promotion}
      />
    );
  },
);

const mapStateToProps = (state: RootStateOrAny, { number, isChangeNumber }) => {
  const { category } = number;

  const { price, discount } = getPricesByCategory(category, state);

  const { price: priceForChangeNumber, discount: discountForChangeNumber } =
    getPricesForChangeNumberByCategory(category, state);

  const promotion = getPromotionContent(state)[category];

  const tagTextForNumber =
    promotion?.categoryAlias === number.category && promotion.tags?.phoneNumber?.tagText;

  const isRedesign2024 = selectIsRedesign2024Enabled(state);

  return isChangeNumber ?
      {
        isChangeNumber,
        price: priceForChangeNumber,
        discount: discountForChangeNumber,
        isRedesign2024,
      }
    : {
        price,
        discount,
        promotion: tagTextForNumber,
        isRedesign2024,
      };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClickNew: () => {
    ymSimMNPAddToCart();
    dispatch(orderNumber(ownProps.number));
  },
  onClickChange: () => {
    dispatch(setCurrentNumber(ownProps.number));
  },
});

export const NumberRow = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNumberRowAnalytics,
)(NumberRowLayout);
