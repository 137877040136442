import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classnames from 'classnames/bind';

import { LottieAnimation } from 'pages/FancyNumber2024/components/LottieAnimation';
import { useBannerLayout } from 'pages/FancyNumber2024/components/BannerLayout/hooks/useBannerLayout';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

/** Компонент заголовка страницы */
export const BannerLayout: FC = () => {
  const { title, isVisible } = useBannerLayout();

  return (
    <section className={cx('wrapper')}>
      {title && (
        <Text
          align="left"
          className={cx('wrapper__title')}
          color="primary"
          lineHeight={24}
          size="l"
        >
          {title}
        </Text>
      )}

      {isVisible && <LottieAnimation />}
    </section>
  );
};
