import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import { ChevronDownIcon, ExpandingPanelStateless } from '@beef/ui-kit';
import { RootStateOrAny, connect } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';

import { MainContent } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent';
import { WidgetType } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/types';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { getWidgetContent } from 'pages/FancyNumber2022/store/selectors/content';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TNewNumberContainer = {
  content: WidgetType;
};

const variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.2 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.2 },
  },
};

// TODO: переписать компонент при рефакторинге
const ExpPanelIcon = () => (
  <div className={cx('icon-wrapper')}>
    <ChevronDownIcon fill="#FFFFFF" size="s" />
  </div>
);

export const _NewNumberContainer: FC<TNewNumberContainer> = ({ content }) => {
  const isMobile = useIsMobileDimension();
  const [isOpenPanel, setIsOpenPanel] = useState<boolean>(true);

  const handlePanelClick = useCallback(() => {
    setIsOpenPanel((prev) => !prev);
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        animate="visible"
        className={cx('wrapper')}
        exit="exit"
        initial="hidden"
        layout
        variants={variants}
      >
        {isMobile ?
          <ExpandingPanelStateless
            className={cx('wrapper__exp-panel-main')}
            headerClassname={cx('wrapper__exp-panel-header')}
            icon={<ExpPanelIcon />}
            isOpen={isOpenPanel}
            onClick={handlePanelClick}
            size="size2-m"
            title={content?.title}
          >
            <MainContent />
          </ExpandingPanelStateless>
        : <MainContent />}
      </motion.div>
    </AnimatePresence>
  );
};

const mapStateToProps = (state: RootStateOrAny) => ({
  content: getWidgetContent(state),
});

export const NewNumberContainer = connect(mapStateToProps)(_NewNumberContainer);
