import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { IconHeadphonesFilled } from '@carnica/graphics';
import { TextPropColor } from '@carnica/ui-kit/types';
import { withRenderFlag } from '@beef/react';

import withContent from 'pages/ContactPage/utils/withContent';

import { StatusText as _StatusText, IStatusText } from '../StatusText';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const StatusText: React.FC<IStatusText & { shouldRender: boolean }> = withRenderFlag(_StatusText);

export interface ICardHeading {
  statusDotColor: string;
  statusLabel: string;
  statusTextColor: TextPropColor;
  text: string;
  textColor: TextPropColor;
}

const _CardHeading: React.FC<ICardHeading> = ({
  text,
  textColor,
  statusTextColor,
  statusDotColor,
  statusLabel,
}) => {
  return (
    <div className={cx('heading')}>
      <IconHeadphonesFilled className={cx('heading-icon')} color="primary-invert" size={24} />
      <div className={cx('heading-content')}>
        <Text className={cx('title')} color={textColor} variant="body-small">
          {text}
        </Text>
        <StatusText
          dotColor={statusDotColor}
          shouldRender={statusLabel?.length > 0}
          text={statusLabel}
          textColor={statusTextColor}
        />
      </div>
    </div>
  );
};

export const CardHeading = withContent(_CardHeading);
