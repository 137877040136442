import React, { FC } from 'react';

import { PageContainer } from 'pages/FancyNumber2024/components/PageContainer';
import { ConditionsContainer } from 'pages/FancyNumber2024/components/ConditionsContainer';

/** Контейнер страницы условий обновления номера FancyNumber2024.
 * Используем дополнительный PageContainer из-за особенности верстки главного компонента _FancyNumberLayout2024 */
export const FancyNumberConditions: FC = () => (
  <PageContainer>
    <ConditionsContainer />
  </PageContainer>
);
