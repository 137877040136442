import React from 'react';
import PropTypes from 'prop-types';

import { enhancedConnect } from '../../../enhancedConnect';

interface ILogic {
  children: React.ReactNode;
  operation: CrudeBinaryOperations;
  valueA: any;
  valueB: any;
}

export enum CrudeBinaryOperations {
  EQ = '===',
  NEQ = '!=',
  GTE = '>',
  GT = '>=',
  LTE = '<=',
  LT = '<',
}

const checkLogic = (operation: CrudeBinaryOperations, valueA: any, valueB: any) => {
  switch (operation) {
    case CrudeBinaryOperations.EQ:
      return valueA === valueB;
    case CrudeBinaryOperations.NEQ:
      return valueA !== valueB;
    case CrudeBinaryOperations.GTE:
      return valueA >= valueB;
    case CrudeBinaryOperations.GT:
      return valueA > valueB;
    case CrudeBinaryOperations.LTE:
      return valueA <= valueB;
    case CrudeBinaryOperations.LT:
      return valueA < valueB;
    default:
      throw new Error('Bad Operation');
  }
};

const _CrudeLogic: React.FC<ILogic> = ({ children, operation, valueA, valueB }) =>
  checkLogic(operation, valueA, valueB) ? (children as JSX.Element) : null;

export const CrudeLogic = enhancedConnect({
  children: PropTypes.node,
  operation: PropTypes.oneOf(Object.values(CrudeBinaryOperations)),
  valueA: PropTypes.any,
  valueB: PropTypes.any,
})(_CrudeLogic);
