import React, { FC } from 'react';
import classnames from 'classnames/bind';

import { ConditionsStepsItem, TConditionsStepsItem } from '../ConditionsStepsItem';
import styles from './styles.pcss';

export type TConditionsSteps = {
  steps?: TConditionsStepsItem[];
};

const cx = classnames.bind(styles);

/** Компонент визуализации пошаговой замены номера */
export const ConditionsSteps: FC<TConditionsSteps> = ({ steps }) =>
  steps?.length ?
    <ul className={cx('wrapper')}>
      {steps.map((step) => (
        <ConditionsStepsItem key={step.id} {...step} />
      ))}
    </ul>
  : null;
