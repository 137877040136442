import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button } from '@carnica/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TButtons = {
  acceptText: string;
  declineText?: string;
  onAccept: () => void;
  onDecline?: () => void;
};

/** Компонент кнопок для Status */
export const Buttons: FC<TButtons> = ({ acceptText, declineText, onAccept, onDecline }) => (
  <div className={cx('wrapper')}>
    {acceptText && (
      <Button onClick={onAccept} size="l" type="button" view="primary" width="full">
        {acceptText}
      </Button>
    )}
    {declineText && (
      <Button onClick={onDecline} size="l" type="button" view="secondary" width="full">
        {declineText}
      </Button>
    )}
  </div>
);
