import { useCallback, useEffect, useState } from 'react';
import { useIsDesktopDimension, useIsTabletDimension } from '@beef/ui-kit/hooks';

import { useIsAuthenticated } from 'pages/FancyNumber2022/hooks/useIsAuthenticated';
import { isChangeNumberLocation } from 'pages/FancyNumber2022/utils';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { TABS_ALIAS } from 'pages/FancyNumber2024/constants';

export const useMainLayoutHook = ({ tabId }: { tabId: string }) => {
  const isAuthenticated = useIsAuthenticated();
  const isChangeNumber = isChangeNumberLocation();
  const isMobile = useIsMobileDimension();
  const isTablet = useIsTabletDimension();
  const isDesktop = useIsDesktopDimension();

  const [block, setBlock] = useState(TABS_ALIAS.new);
  const [isWidgetVisible, setIsWidgetVisible] = useState(true);
  const [isButtonFixed, setIsButtonFixed] = useState(true);
  const [isShowBtn, setIsShowBtn] = useState<boolean>(false);

  const [widgetElement, setWidgetElement] = useState<HTMLDivElement | null>(null);

  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

  // Callback ref для widgetRef
  const handleWidgetRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setWidgetElement(node);
    } else {
      setWidgetElement(null);
    }
  }, []);

  // Callback ref для containerRef
  const handleContainerRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setContainerElement(node);
    } else {
      setContainerElement(null);
    }
  }, []);

  // Обновление состояния блока на основе аутентификации и локации смены номера
  useEffect(() => {
    if (!isAuthenticated && isChangeNumber) {
      setBlock(TABS_ALIAS.change);
      setIsShowBtn(false);
      return;
    }

    // Если пользователь авторизован, то показывать номера, хоть и на вкладке смены номера
    if (isAuthenticated && isChangeNumber) {
      setBlock(TABS_ALIAS.new);
      setIsShowBtn(true);
      return;
    }

    setBlock(TABS_ALIAS.new);
    setIsShowBtn(true);
  }, [isAuthenticated, isChangeNumber, tabId]);

  // IntersectionObserver для отслеживания видимости widgetRef (ChosenComponent)
  useEffect(() => {
    if (!widgetElement) {
      return;
    }

    const widgetObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsWidgetVisible(entry.isIntersecting);
        });
      },
      {
        root: null,
        rootMargin: '-100px 0px 0px 0px',
        threshold: [0, 1],
      },
    );

    widgetObserver.observe(widgetElement);

    // eslint-disable-next-line consistent-return
    return () => {
      if (widgetElement) {
        widgetObserver.unobserve(widgetElement);
      }
      widgetObserver.disconnect();
    };
  }, [widgetElement]);

  // IntersectionObserver для отслеживания видимости containerElement (aside)
  useEffect(() => {
    if (!containerElement) {
      return;
    }

    const containerObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach(({ isIntersecting }) => {
          if (!isIntersecting) {
            setIsButtonFixed(false);
          } else {
            setIsButtonFixed(true);
          }
        });
      },
      {
        root: null,
        rootMargin: '-800px 0px 0px 0px',
        threshold: [0, 0.5, 1],
      },
    );

    containerObserver.observe(containerElement);

    // eslint-disable-next-line consistent-return
    return () => {
      if (containerElement) {
        containerObserver.unobserve(containerElement);
      }
      containerObserver.disconnect();
    };
  }, [containerElement]);

  return {
    handleContainerRef,
    handleWidgetRef,
    isWidgetVisible,
    isButtonFixed,
    isMobile,
    isTablet,
    isDesktop,
    isAuthenticated,
    isShowBtn,
    block,
  };
};
