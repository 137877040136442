import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { TariffCard } from '../TariffCard';
import { InputBlock } from '../InputBlock';
import { FadeAnimation } from '../FadeAnimation';

const cx = classNames.bind(styles);

/**
 * Виджет переноса номера с отображением тарифа
 */
export const TariffMnpWidget: FC = memo(() => (
  <>
    {/* Карточка с информацией о тарифе MNP */}
    <FadeAnimation>
      <TariffCard />
    </FadeAnimation>

    {/* Блок с формой переноса номера */}
    <FadeAnimation className={cx('input-block-wrapper')}>
      <InputBlock className={cx('input-block')} id="mnp-widget-input-block" />
    </FadeAnimation>
  </>
));
