import { combineReducers } from '@reduxjs/toolkit';
import { composeStoreInitializer } from '@beef/redux';
import { emptyObj } from '@beef/utils';

export const getContentData = (state) => state.data || emptyObj;

const rootReducer = combineReducers({
  data: (state = {}) => state,
  renderUrl: (state = '/') => state,
  baseUrl: (state = '/') => state,
});

export type RootState = ReturnType<typeof rootReducer>;
export const initializeStore = composeStoreInitializer(rootReducer);
