import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import withContent from 'pages/ContactPage/utils/withContent';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface HeadingProps {
  subtitle: string;
  title: string;
}

const _Heading: React.FC<HeadingProps> = ({ title, subtitle }) => {
  return (
    <div className={cx('heading')}>
      <Text align="center" className={cx('title')} variant="display-medium">
        {title}
      </Text>
      <Text align="center" className={cx('subtitle')} color="secondary" variant="body-medium">
        {subtitle}
      </Text>
    </div>
  );
};

export const Heading = withContent(_Heading);
