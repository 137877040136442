import { useEffect, useMemo, useState } from 'react';

import { padString } from 'pages/FancyNumber2024/utils/padString';
import { FILTER_FAVORITE, FILTER_SIMILAR } from 'pages/FancyNumber2022/constants';
import { NumbersTitlePropsType } from 'pages/FancyNumber2024/components/NumbersBlock/components/NumbersTitle/types';

// TODO: Переписать!
export const useNumbersTitle = ({
  content,
  inputDataFields,
  isLoadNumbersError,
  isError,
  emptyContent,
  filter,
  loading,
  defCode,
  query,
  isEmptyNumbers,
}: Partial<NumbersTitlePropsType>) => {
  const [headerTitle, setHeaderTitle] = useState(content?.title);
  const [headerDesc, setHeaderDesc] = useState('');

  const filledFieldsCount = useMemo(
    () => inputDataFields.filter((field) => field?.value !== '').length,
    [inputDataFields],
  );

  useEffect(() => {
    /* Если произошла ошибка загрузки номеров (isLoadNumbersError), общая ошибка (isError) или номера загружены и
     * объект пустой (!loading && isEmptyNumbers), выводим заголовок "таких номеров у нас нет" */
    if (isLoadNumbersError || isError || (!loading && isEmptyNumbers)) {
      setHeaderTitle(emptyContent?.title);
      // TODO: временно отключаем description по согласованию
      // setHeaderDesc(emptyContent?.description);
      return;
    }

    // TODO: быстрый фикс из-за смены логики на общий инпут
    if (query.length > 0) {
      /* Если выбран вариант FILTER_FAVORITE (unlock) */
      if (filter === FILTER_FAVORITE) {
        if (loading && defCode && query) {
          setHeaderTitle(`${defCode} и ${query}`);
          setHeaderDesc('');
        }
        return;
      }

      /* Если выбран вариант FILTER_SIMILAR (lock) */
      if (filter === FILTER_SIMILAR) {
        /* Логика блока изменена, т.к. принято решение использовать единый инпут для всех вариато поиска номера.
         * Старый код пока не удаляем. */
        const filledFieldsCountForSimilar: string[] = padString(query ?? '');

        const tNumber = filledFieldsCountForSimilar?.reduce(
          (acc, inputField, index) => {
            /* Добавление пробелов между символами с индексами 3 и 5 */
            if (index === 3 || index === 5) {
              acc += ' ';
            }

            if (inputField === ' ') {
              acc += '•';
            } else {
              acc += inputField;
            }

            return acc;
          },
          `+7 ${defCode === 'XXX' ? '•••' : defCode} `,
        );

        if (loading) {
          setHeaderTitle(tNumber);
          setHeaderDesc('');
        }
        return;
      }
    }

    /* Текст по-умолчанию */
    setHeaderTitle(content?.title);
    setHeaderDesc('');
  }, [
    loading,
    filledFieldsCount,
    query,
    isLoadNumbersError,
    defCode,
    isError,
    filter,
    emptyContent,
  ]);

  return {
    headerTitle,
    headerDesc,
  };
};
