import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { cnTheme } from '@carnica/themes';
import PropTypes from 'prop-types';

import { enhancedConnect } from 'utils/lowCodeHelpers';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _LayoutWrapper = ({ children }: { children: ReactNode }) => (
  <div className={cx('container', cnTheme('light'))}>
    <div className={cx('wrapper')}>{children}</div>
  </div>
);

export const LayoutWrapper = enhancedConnect({
  children: PropTypes.node,
})(_LayoutWrapper);
