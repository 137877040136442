import { withDotSeparatedProps } from '@beef/landing-constructor/library-utils';
import PropTypes from 'prop-types';

import { AppLayout as _AppLayout } from '../layout/AppLayout';
import { ColumnContainer as _ColumnContainer } from '../layout/ColumnContainer';
import { ColumnWrapper as _ColumnWrapper } from '../layout/ColumnWrapper';
import { Heading as _Heading } from './Heading';
import { BlockWrapper as _BlockWrapper } from './BlockWrapper';
import {
  CustomCard as _CustomCard,
  CustomCardsWrapper as _CustomCardsWrapper,
} from './old/CardsLayout';

export { AppBlock } from './AppBlock';
export { Banner } from './Banner';
export { BlockHeading } from './BlockHeading';
export { Messengers } from './Messengers';
export { PhoneNumbers } from './PhoneNumbers';
export * from './ChatBlock';

export const AppLayout = withDotSeparatedProps(_AppLayout, {
  mode: PropTypes.oneOf([null, 'light', 'dark']),
  children: PropTypes.node,
  className: PropTypes.string,
});

export const BlockWrapper = withDotSeparatedProps(_BlockWrapper, {
  withPadding: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
});

export const ColumnContainer = withDotSeparatedProps(_ColumnContainer, {
  children: PropTypes.node,
  className: PropTypes.string,
});

export const ColumnWrapper = withDotSeparatedProps(_ColumnWrapper, {
  children: PropTypes.node,
  className: PropTypes.string,
});

export const Heading = withDotSeparatedProps(_Heading, {
  title: PropTypes.string,
  subtitle: PropTypes.string,
});

export const CustomCardsWrapper = withDotSeparatedProps(_CustomCardsWrapper, {
  type: PropTypes.oneOf(['imageCard', 'infoCard']),
  title: PropTypes.string,
  description: PropTypes.string,
  'link.text': PropTypes.string,
  'link.href': PropTypes.string,
  'link.withIcon': PropTypes.bool,
  'link.withMobileText': PropTypes.string,
  size: PropTypes.oneOf(['l', 'm', 's']),
  children: PropTypes.node,
  className: PropTypes.string,
});

export const CustomCard = withDotSeparatedProps(_CustomCard, {
  title: PropTypes.string,
  text: PropTypes.string,
  bordered: PropTypes.bool,
  'img.src': PropTypes.string,
  'img.title': PropTypes.string,
  'button.text': PropTypes.string,
  'button.link': PropTypes.string,
  'button.variant': PropTypes.oneOf(['primary', 'secondary']),
  className: PropTypes.string,
});
