import { axiosBeelineRequest } from '@beef/utils';

import { apiCreateMnpOrder } from './constants';

export interface ICreateMNPOrderResponse {
  Errors: Record<string, unknown>;
  IsSucceeded: boolean;
  View: IViewCreateMNPOrder;
  camelCase: boolean;
}

export interface IViewCreateMNPOrder {
  Options: string[];
  OrderId?: string;
  OrderPlace: number;
  Price?: number;
  ProductId?: number;
  ShopSlug: string;
  StartBalance?: number;
  TariffId: number;
}

/**
 * Создание заказа на перенос номера
 */
export const createMNPOrder = async (phone: string, region: number) => {
  try {
    return await axiosBeelineRequest<ICreateMNPOrderResponse>({
      method: 'post',
      url: apiCreateMnpOrder,
      data: {
        leadName: 'PodborSim',
        phone,
        region,
      },
    });
  } catch (error) {
    return String(error);
  }
};
