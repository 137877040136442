import { useMemo } from 'react';

import { useAppSelector } from 'pages/MNPLanding/store';
import { selectTariffBlock } from 'pages/MNPLanding/store/slices/commonSlice/selectors';
import { selectMnpTariff } from 'pages/MNPLanding/store/slices/additionalDataSlice/selectors';

export const useTariffCardData = () => {
  /** Данные о тарифе полученные из QP */
  const { optionsTitle, options, info, backgroundImageUrl } = useAppSelector(selectTariffBlock);

  /** Данные о тарифе сформированные на backend */
  const { presetText, presetPriceText } = useAppSelector(selectMnpTariff);

  /** Формируем массив ссылок на картинки */
  const optionsImages = useMemo(() => options?.map(({ image }) => ({ image })) ?? [], [options]);

  return { optionsImages, optionsTitle, info, backgroundImageUrl, presetText, presetPriceText };
};
