import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { TextPropColor } from '@carnica/ui-kit/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export interface IStatusText {
  dotColor: string;
  text: string;
  textColor: TextPropColor;
}

export const StatusText: React.FC<IStatusText> = ({ dotColor, text, textColor }) => {
  return (
    <div className={cx('status')}>
      <div
        className={cx('status-dot')}
        style={{
          backgroundColor: dotColor,
        }}
      >
        <div
          className={cx('status-dot__pulse')}
          style={{
            backgroundColor: dotColor,
          }}
        />
      </div>
      <Text className={cx('status-text')} color={textColor} variant="body-small">
        {text}
      </Text>
    </div>
  );
};
