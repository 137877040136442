import { parseFancyNumberUrl } from '../utils';
import { FILTER_FAVORITE } from '../constants';

export const dataConverter = ({ data, renderUrl }) => {
  const { categories, filter, numbers, type } = parseFancyNumberUrl(renderUrl);

  return {
    content: { baseUrl: renderUrl, ...data },
    category: {
      active: categories.reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
    },
    numbers: {
      randomNumberCategory: data.random.category,
    },
    filter: {
      active: filter || data.filter.defaultFilter || FILTER_FAVORITE,
      query: numbers || '',
    },
    change: {
      isChangeNumber: type === 'change',
    },
  };
};
