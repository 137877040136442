import React, { useContext } from 'react';
import { Link, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { URL_CONFIG } from 'pages/Services/ScopeZeroBalanceServices/constants';
import { TrustPaymentContext } from 'pages/Services/TrustPayment/context';
import { notificationYM } from 'utils/analytics/trustPaymentAnalytics';

import { TrustPaymentModuleButton } from '../TrustPaymentModuleButton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ServiceNotificationWrapper = () => {
  const {
    content: { isAvailableToConnect, trustedPaymentInfo },
  } = useContext(TrustPaymentContext);

  const message = trustedPaymentInfo?.message;
  const variant = 'secondary';
  const textColor = 'grey95';

  React.useEffect(() => {
    if (message) {
      const textButton = isAvailableToConnect ? 'Доверительный платёж' : 'Пополнить баланс';
      notificationYM(message, textButton, 'notification_view', URL_CONFIG.topUpAccountUrl);
    }
  }, [isAvailableToConnect, message]);

  return message ?
      <div className={cx('notification-item-wrapper')}>
        <div className={cx('notification-item', { [`notification-item--${variant}`]: !!variant })}>
          <Text color={variant === 'secondary' ? 'grey95' : 'white'} size="size5-r-s">
            {message}
          </Text>
        </div>
        <div
          className={cx('notification-item__button', {
            [`notification-item__button--${variant}`]: !!variant,
          })}
        >
          {isAvailableToConnect ?
            <TrustPaymentModuleButton
              color={textColor}
              size="m"
              text="Доверительный платёж"
              variant="plain"
            />
          : <Link
              href={URL_CONFIG.topUpAccountUrl}
              isNoUnderline
              onClick={() =>
                notificationYM(
                  message,
                  'Пополнить баланс',
                  'notification_click',
                  URL_CONFIG.topUpAccountUrl,
                )
              }
            >
              <Text color={textColor}>Пополнить баланс</Text>
            </Link>
          }
        </div>
      </div>
    : null;
};
