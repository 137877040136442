import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import { AnimatePresence, motion } from 'framer-motion';

import { useAppSelector } from 'pages/MNPLanding/store';
import { selectIsErrorWidgetShown } from 'pages/MNPLanding/store/slices/mnpFormSlice/selectors';

import { InputBlock } from '../MNPForm/components/InputBlock';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Форма переноса номера внизу экрана */
export const MobileStickyForm: FC = memo(() => {
  const isErrorWidgetShown = useAppSelector(selectIsErrorWidgetShown);

  return (
    <AnimatePresence mode="wait">
      {!isErrorWidgetShown && (
        <motion.div exit={{ opacity: 0 }}>
          <InputBlock className={cx('input-block')} />
        </motion.div>
      )}
    </AnimatePresence>
  );
});
