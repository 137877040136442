import React from 'react';
import { PageFixer } from '@beef/smart-kit';
import PropTypes from 'prop-types';

import { MNPLanding } from 'pages/MNPLanding';
import { useDetectIncognitoMode } from 'utils/hooks/useDetectIncognitoMode';

import { Faq, Header, blockComponents } from './components';
import { usePushEcommerceShowMnp } from './hooks';

const MnpOrderOld = ({
  data: {
    extData: { blocks, faq, header },
    id,
    isMnpService,
  },
}) => {
  usePushEcommerceShowMnp(id);

  return (
    <>
      {header && <Header data={header} id={id} isMnpService={isMnpService} />}
      <PageFixer size="m">
        {blocks.map(({ content, id: blockId, type }) => {
          const ContentSection = blockComponents[type];

          return (
            <ContentSection data={content} id={id} isMnpService={isMnpService} key={blockId} />
          );
        })}
        {faq.data.length > 0 && <Faq data={faq} />}
      </PageFixer>
    </>
  );
};

MnpOrderOld.propTypes = {
  data: PropTypes.shape({
    extData: PropTypes.shape({
      blocks: PropTypes.arrayOf(PropTypes.shape({})),
      faq: PropTypes.shape({}),
      header: PropTypes.shape({}),
    }),
    id: PropTypes.number,
    isMnpService: PropTypes.bool,
  }),
};

export const MnpOrderNew = (props) => {
  useDetectIncognitoMode();

  if (props?.data?.extData?.isMnpTargetDesign) {
    return <MNPLanding {...props} />;
  }
  return <MnpOrderOld {...props} />;
};
