import React, { memo } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { SliderPropsType } from '../../types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

// TODO: переписать компонент при рефакторинге
/** Компонент с описанием/слайдером под инпутом ввода цифр */
export const Slider = memo<SliderPropsType>(
  ({
    position,
    maxSliderHPos,
    handleSliderChange,
    locked,
    content,
    isMobile,
    hasAtLeastOneFilledInputCell,
    isShowRangeInput,
  }) => {
    /* Если !locked (favourite), отображаем только информационный текст */
    if (!locked) {
      return (
        <Text
          className={cx('under-input-text')}
          color="textIconElementTertiary"
          size={isMobile ? 'size4-r' : 'size5-r-s'}
        >
          {content?.descriptions?.underInput}
        </Text>
      );
    }

    /* Если locked (similar) && maxSliderHPos >= 5 (isShowRangeInput), отображаем input */
    return isShowRangeInput ?
        <input
          className={cx('range-slider')}
          disabled={!(locked || !hasAtLeastOneFilledInputCell)}
          max={maxSliderHPos}
          min="0"
          onChange={handleSliderChange}
          type="range"
          value={position}
        />
      : null;
  },
);
