import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface CardContainerProps {
  children?: React.ReactNode;
  className?: string;
  outsideChildren?: React.ReactNode;
}

export const CardContainer: React.FC<CardContainerProps> = ({
  children,
  className,
  outsideChildren,
}) => {
  return (
    <div className={cx('card', className)}>
      {outsideChildren}
      <div className={cx('card-wrapper')}>{children}</div>
    </div>
  );
};
