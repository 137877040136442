import React, { FC, ReactNode } from 'react';
import { cnTheme } from '@carnica/themes';
import classnames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

type TPageContainer = {
  children: ReactNode;
};

/** Компонент-обертка общих страниц FancyNumber2024 */
export const PageContainer: FC<TPageContainer> = ({ children }) => (
  <section className={cx('wrapper', cnTheme('light'))}>{children}</section>
);
