import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIsMobileDimension } from '@beef/ui-kit/hooks';
import type { Swiper as SwiperClass } from 'swiper/types';

import { debounce } from 'utils/timed-functions';
import { InputFieldsType } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/types';
import {
  DEFAULT_DEF_CODE,
  DEFAULT_DEF_CODE_DOTS,
  FILTER_FAVORITE,
  FILTER_SIMILAR,
} from 'pages/FancyNumber2022/constants';

const MAX_LENGTH_NUMBER = 7;

const initialInputDataFields: InputFieldsType = Array.from({ length: MAX_LENGTH_NUMBER }).map(
  (_, idx) => ({
    id: idx + 1,
    name: `inputPhoneField-${idx + 1}`,
    placeholder: ' ',
    value: '',
    max: 1,
  }),
);

// TODO: Переписать!
export const useMainContent = ({
  onResetFilter,
  onSetResetWidgetFilters,
  onSetFilterDefCode,
  defCodes,
  isResetWidgetFilters,
  onSetInputDataFields,
  onSetActiveFilter,
}) => {
  // Локальное состояние для исходных данных
  const [originalInputDataFields, setOriginalInputDataFields] =
    useState<InputFieldsType>(initialInputDataFields);
  // Локальное состояние для сдвинутых данных
  const [inputDataFields, setInputDataFields] = useState<InputFieldsType>(initialInputDataFields);
  const [secondInputValue, setSecondInputValue] = useState<string>('');
  const [selectedRegion, setSelectedRegion] = useState<string>(DEFAULT_DEF_CODE_DOTS);
  const [locked, setLocked] = useState<boolean>(false);
  const [position, setPosition] = useState<number>(0);
  const [hasInitialized, setHasInitialized] = useState<boolean>(false);

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const centeredInputRef = useRef<HTMLInputElement | null>(null);
  const swiperRef = useRef<SwiperClass | null>(null);

  const hasAtLeastOneFilledInputCell = useMemo(
    () => originalInputDataFields.some((field) => Boolean(field.value)),
    [originalInputDataFields],
  );

  const filledFieldsCount = useMemo(
    () => originalInputDataFields.filter((field) => field?.value !== '').length,
    [originalInputDataFields],
  );

  const maxSliderHPos = useMemo(() => MAX_LENGTH_NUMBER - filledFieldsCount, [filledFieldsCount]);

  // TODO: по согласованию с DL временно отключаем логику (без удаления кода) с RangeInput! (нерабочая логика).
  /** Флаг определяет показ элемента input в компоненте Slider при условии,
   * что длина введенного номера является - корректной.
   * Общий остаток (maxSliderHPos) введенных цифр, должен быть <= MIN_POS_SIZE (5).
   * Для включения добавить maxSliderHPos <= MIN_POS_SIZE вместо false */
  const isShowRangeInput = false;

  const isMobile = useIsMobileDimension();

  // сдвиг параметров в input с точками
  const shiftData = useCallback((dataFields: InputFieldsType, pos: number) => {
    const shifted = Array.from({ length: 7 }, (_, i) => ({ ...dataFields[i] }));

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 7; i++) {
      const originalIndex = i - pos;
      if (originalIndex >= 0 && originalIndex < dataFields.length) {
        shifted[i].value = dataFields[originalIndex].value;
      } else {
        shifted[i].value = '';
      }
    }
    return shifted;
  }, []);

  useEffect(() => {
    // Обработчик сброса виджета
    const handleResetWidget = () => {
      onResetFilter();

      const initialIndex = 0;
      if (swiperRef.current) {
        swiperRef.current.slideTo(initialIndex);
      }

      setSelectedRegion(defCodes[0]);
      setSecondInputValue('');
      setOriginalInputDataFields(initialInputDataFields);
      onSetResetWidgetFilters(false);
    };

    if (isResetWidgetFilters) {
      handleResetWidget();
    }
  }, [onResetFilter, isResetWidgetFilters]);

  useEffect(() => {
    const shiftedFields = shiftData(originalInputDataFields, position);
    setInputDataFields(shiftedFields);

    onSetInputDataFields(shiftedFields);
  }, [originalInputDataFields, position, shiftData, onSetInputDataFields]);

  useEffect(() => {
    /* Выставляем активный фильтр по тиму FILTER_SIMILAR/FILTER_FAVORITE, в зависимости от locked */
    onSetActiveFilter(locked ? FILTER_SIMILAR : FILTER_FAVORITE);

    /* Сбрасываем выбранный пользователем код (если такой имеется) и проматываем в начало выбора кодов (RegionSelector),
    а также удаляем ранее введенный номера для поиска (secondInputValue).
     * Требуется для корректной работы компонента NumbersTitle */
    if (swiperRef.current) {
      setSelectedRegion(DEFAULT_DEF_CODE_DOTS);
      swiperRef.current.slideTo(0);
      setSecondInputValue('');
    }
  }, [locked, onSetActiveFilter]);

  const handleSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newPosition = parseInt(e.target.value, 10);
    setPosition(newPosition);
  };

  const handleSlideChange = debounce((swiper: SwiperClass) => {
    const chosenDefCode = defCodes[swiper.realIndex];

    /** Код для поля filter в store (defCode).
     * НЕ должен быть равен DEFAULT_DEF_CODE_DOTS, иначе ломается логика запросов номеров */
    const filterDefCode =
      chosenDefCode === DEFAULT_DEF_CODE_DOTS ? DEFAULT_DEF_CODE : chosenDefCode;

    onSetFilterDefCode(filterDefCode);
    setSelectedRegion(chosenDefCode);
  }, 100);

  return {
    handleSlideChange,
    handleSliderChange,
    isMobile,
    maxSliderHPos,
    hasAtLeastOneFilledInputCell,
    centeredInputRef,
    inputRefs,
    hasInitialized,
    setHasInitialized,
    setLocked,
    selectedRegion,
    secondInputValue,
    inputDataFields,
    setSelectedRegion,
    setPosition,
    swiperRef,
    setSecondInputValue,
    setOriginalInputDataFields,
    locked,
    position,
    initialInputDataFields,
    originalInputDataFields,
    isShowRangeInput,
  };
};
