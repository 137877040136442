import { useEffect, useRef } from 'react';
import { UseInViewOptions, useInView } from 'framer-motion';

import { LottieRef } from 'pages/CarnicaDSLandings/ui';

export const useLottieInView = (options: UseInViewOptions) => {
  const containerRef = useRef(null);
  const lottieRef: LottieRef = useRef(null);
  const isInView = useInView(containerRef, options);
  useEffect(() => {
    if (isInView) {
      // нужно выбить из основного стэка, иначе не стартует, когда вьюпорт прямо на блоке после старта страницы
      setTimeout(() => {
        lottieRef.current?.play();
      }, 100);
    }
  }, [isInView]);

  return { containerRef, lottieRef, isInView };
};
