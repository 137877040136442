import React from 'react';

import withContent from 'pages/ContactPage/utils/withContent';

import { CardContainer } from './components/CardContainer';
import { ChatBlockProps } from './types';
import { FloatingImage } from './components/FloatingImage';

const _ChatBlock: React.FC<ChatBlockProps> = ({ children, floatingImageUrl }) => {
  return (
    <CardContainer outsideChildren={<FloatingImage imageUrl={floatingImageUrl} />}>
      {children}
    </CardContainer>
  );
};

export const ChatBlock = withContent(_ChatBlock);
