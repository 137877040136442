import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  DEFAULT_CODE_LENGTH,
  DEFAULT_START_TIMER,
  SMS_REQUEST_ERRORS,
} from 'pages/FancyNumber2024/components/ConfirmationContainer/constants';
import { useThrowAsyncError } from 'pages/FancyNumber2024/hooks/useThrowAsyncError';
import { getError, getIsLoadingSms } from 'pages/FancyNumber2022/store/selectors/change';
import { manageSms } from 'pages/FancyNumber2024/store/changeSlice/asyncThunks/manageSms';
import { useFancyNumberLink } from 'pages/FancyNumber2024/router/hooks/useFancyNumberLink';
import { resetError, setSmsLoadingStatus } from 'pages/FancyNumber2022/store/actions/change';
import { createCaptionText } from 'pages/FancyNumber2024/components/ConfirmationContainer/utils/createCaptionText';

import { useTimerOptions } from './useTimerOptions';

/** Хук управления и подготовки данных для компонента SMSContainer */
export const useSMSContainer = (timerSettings: Record<string, string>) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const throwAsyncError = useThrowAsyncError();

  const { start, reset, isRunning, time, setTime } = useTimerOptions();

  /** Ссылка для перехода на результирующую страницу */
  const resultUrl = useFancyNumberLink('result');

  /** State для работы с китовым компонентом CodeInput, для ввода SMS */
  const [code, setCode] = useState<string | undefined>();

  /** Флаг отражает статус отправки запроса (метод изменения номера телефона) */
  const isLoadingSms = useSelector(getIsLoadingSms);

  /** Текущая ошибка в старом store (FancyNumber2022) */
  const currentError = useSelector(getError);

  /** Текст с "таймингом" получения/отправки SMS */
  const caption = createCaptionText(time, isRunning, timerSettings);

  /** Функция ввода кода в поле CodeInput */
  const enterCode = (value: string) => {
    /* Если на момент ввода нового кода есть ошибка валидации кода, убираем ее */
    if (currentError === SMS_REQUEST_ERRORS.incorrectSmsConfirmationCode) {
      dispatch(resetError());
    }

    /* Устанавливаем текущее значение, введенное юзером, в state */
    setCode(value);

    /* Если длина введенного кода равна DEFAULT_CODE_LENGTH (5),
     отправляем запрос с кодом из SMS и данными по номеру телефона */
    if (value?.length === DEFAULT_CODE_LENGTH) {
      dispatch(manageSms(value))
        // @ts-expect-error ошибка типизации
        ?.unwrap()
        .then((errorCode: keyof typeof SMS_REQUEST_ERRORS) => {
          /* Переход на результирующую страницу в случае, если
           errorCode не равен ошибке валидации введенного пользователем кода (incorrectSmsConfirmationCode) и корректного ответа от бека */
          if (errorCode !== SMS_REQUEST_ERRORS.incorrectSmsConfirmationCode) {
            navigate(resultUrl);
          }
        })
        .catch((e: Error) => throwAsyncError(e));
    }
  };

  /** Функция повторной отправки SMS.
   * Сбрасывает таймер до дефолтного значения и перезапускает его */
  const resendSms = () => {
    /* Установка дефолтного времени таймера, его сброс и рестарт */
    setTime(DEFAULT_START_TIMER);
    reset();
    start();
    /* Повторный запрос на получение кода */
    dispatch(manageSms())
      // @ts-expect-error ошибка типизации
      ?.unwrap()
      .catch((e: Error) => throwAsyncError(e));
  };

  /* Старт таймера при переходе на страницу */
  useEffect(() => {
    start();

    /* Если покидаем страницу, сбрасываем таймер и флаг загрузки отправки SMS-кода */
    return () => {
      reset();
      // @ts-expect-error ошибка типизации файла с actions (отсутствует! файл js-формата)
      dispatch(setSmsLoadingStatus(false));
    };
  }, []);

  /** Props для компонента CodeInput */
  const codeInputProps = {
    /* Подпись к форме ввода SMS */
    caption,
    /* Флаг блокировки отправки повторного запроса */
    captionDisabled: isRunning,
    /* Функция повторного запроса SMS-кода */
    onCaptionClick: resendSms,
    /* Функция записи SMS-кода */
    onChange: enterCode,
    /* Текущий код SMS */
    value: code,
    /* Ошибка проверки SMS */
    error: currentError === SMS_REQUEST_ERRORS.incorrectSmsConfirmationCode,
  };

  /** Результирующий объект */
  return {
    isLoadingSms,
    codeInputProps,
  };
};
