import { useMemo } from 'react';

import { IErrorWidgetContent } from 'pages/MNPLanding/store/slices/commonSlice/types';
import { useAppSelector } from 'pages/MNPLanding/store';
import {
  selectErrorWidgetType,
  selectLastSubmittedPhoneNumber,
} from 'pages/MNPLanding/store/slices/mnpFormSlice/selectors';
import { selectErrorWidgets } from 'pages/MNPLanding/store/slices/commonSlice/selectors';

import { ErrorWidgetContentOverrides } from '../types';

/** Хук для формирования контента виджета с ошибками */
export const useErrorWidgetContent = () => {
  const errorWidgets = useAppSelector(selectErrorWidgets);
  const phoneNumber = useAppSelector(selectLastSubmittedPhoneNumber);
  const errorType = useAppSelector(selectErrorWidgetType);

  /** Дополняем заголовки ошибок полученных из QP если необходимо */
  const titles: ErrorWidgetContentOverrides = useMemo(
    () => ({
      isLastChangeDate: {
        title: `${phoneNumber} ${errorWidgets.isLastChangeDate.title} `,
      },
      ourNumber: {
        title: `${phoneNumber} ${errorWidgets.ourNumber.title} `,
      },
      isOperatorCodeBeeline: {
        title: `${phoneNumber} ${errorWidgets.isOperatorCodeBeeline.title} `,
      },
      isRequest: {
        title: `${errorWidgets.isRequest.title}\n${phoneNumber}`,
      },
    }),
    [errorWidgets, phoneNumber],
  );

  /** Формируем итоговый контент с добавлением переопределенных данных */
  const content: IErrorWidgetContent = useMemo(() => {
    const title = titles[errorType]?.title;

    return {
      ...errorWidgets[errorType],
      title: title || errorWidgets[errorType].title,
    };
  }, [errorWidgets, errorType, titles]);

  return content;
};
