import React from 'react';
import { withErrorBoundary } from '@beef/react';
import { withDotSeparatedProps } from '@beef/landing-constructor/library-utils';
import { ValidationMap } from 'prop-types';

import { CrashedComponent } from 'utils/lowCodeHelpers/boundary';

import { withCrudeMapping } from './crude-mapping/hocs/withCrudeMapping';

interface EnhancedConnectOptions {
  autoMapping: boolean;
  errorBoundary: boolean;
}

// Add automatic component disable during errors
export const enhancedConnect =
  <P>(
    propsShape?: ValidationMap<P>,
    { errorBoundary = true, autoMapping = true } = {} as EnhancedConnectOptions,
  ) =>
  <T>(WrappedComponent: React.ComponentType<T>) => {
    let Enhanced = WrappedComponent as any;
    if (autoMapping) Enhanced = withCrudeMapping()(Enhanced);
    if (errorBoundary) Enhanced = withErrorBoundary({ fallback: CrashedComponent })(Enhanced);
    if (propsShape) Enhanced = withDotSeparatedProps(Enhanced, propsShape);
    Enhanced.propTypes = {
      ...WrappedComponent.propTypes,
      ...Enhanced.propTypes,
    };

    // Enhanced.propTypes = WrappedComponent.propTypes;
    return Enhanced as React.ComponentType<T & P>;
  };
