import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { compose } from '@beef/redux';
import { Text } from '@beef/ui-kit';

import { withStatusEvent } from 'pages/FancyNumber2022/hocs/withStatusEvent';
import {
  getCommonError,
  getEmptyErrorContent,
} from 'pages/FancyNumber2022/store/selectors/content';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

// TODO: переписать компонент при рефакторинге
const StatusLayout = ({ image, title, description }) => (
  <div className={cx('status')}>
    <img alt="" src={image} />
    <div className={cx('status_heading')}>
      <Text size="size4-m">{title}</Text>
    </div>
    <Text color="grey60" size="size5-r-s">
      {description}
    </Text>
  </div>
);

StatusLayout.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export const EmptyList = compose(
  withStatusEvent('not_found'),
  connect(getEmptyErrorContent),
)(StatusLayout);

export const Error = compose(withStatusEvent('error'), connect(getCommonError))(StatusLayout);
