import React from 'react';
import classNames from 'classnames/bind';
import { Accordion, Text } from '@carnica/ui-kit';
import { connect } from 'react-redux';
import { compose } from '@beef/redux';
import { withRenderFlag } from '@beef/react';

import { TRegionGroup } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/RegionGroup/types';
import {
  selectCurrentRegionGroup,
  withRegionDistrictMapper,
} from 'pages/LizaAlertLandingPage/store/selectors';
import { RegionAllDistrictsCheckbox } from 'pages/LizaAlertLandingPage/components/VolunteersForm/connected';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const _RegionGroup = ({ title, children }: TRegionGroup) => {
  return (
    <Accordion
      className={cn('region-group')}
      key={title}
      label={
        <Text className={cn('title')} color="primary" tagName="span" variant="display-extrasmall">
          {title}
        </Text>
      }
    >
      <RegionAllDistrictsCheckbox />
      <div className={cn('wrapper')}>{children}</div>
    </Accordion>
  );
};

export const RegionGroup = compose<React.FC<TRegionGroup>>(
  withRegionDistrictMapper,
  connect((state) => ({
    title: selectCurrentRegionGroup(state).name,
    shouldRender: selectCurrentRegionGroup(state),
  })),
  withRenderFlag,
)(_RegionGroup);
