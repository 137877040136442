import axios from 'axios';

import { SERVICE_CONTEXTS_ENDPOINT } from 'constants/Endpoints';
import {
  SCREEN_ID_CONFIG,
  TRUST_PAYMENT_SOCS,
} from 'pages/Services/ScopeZeroBalanceServices/constants';

import { ResponseData, ServiceContextInfo } from './types';

export const fetchServicesContextInfo = async () => {
  return (
    await axios.get<ResponseData>(SERVICE_CONTEXTS_ENDPOINT, {
      params: { screenId: SCREEN_ID_CONFIG.services },
    })
  ).data;
};

export const convertServiceContextInfoToBySocMap = (serviceContexts: ServiceContextInfo[]) => {
  try {
    return serviceContexts.reduce<Record<string, ServiceContextInfo>>((acc, next) => {
      acc[next.name] = next;
      return acc;
    }, {});
  } catch (error) {
    return {};
  }
};

export type PredicateFn = (serviceSoc: string) => boolean;

export type CaseFn = (props: {
  serviceSoc?: string;
  servicesInfoContextsMap: Record<string, ServiceContextInfo>;
}) => ServiceContextInfo | undefined;

export const getServiceInfoContext =
  ({
    cases,
    servicesInfoContextsMap,
  }: {
    cases: [predicateFn: PredicateFn, caseFn: CaseFn][];
    servicesInfoContextsMap: Record<string, ServiceContextInfo>;
  }) =>
  (serviceSoc: string) => {
    const [, caseFn] = cases.find(([predicate]) => predicate(serviceSoc) === true) ?? [];

    if (caseFn) {
      return caseFn({ serviceSoc, servicesInfoContextsMap });
    }

    return undefined;
  };

export const isTrustPayment: PredicateFn = (serviceSoc: string) => {
  return TRUST_PAYMENT_SOCS.includes(serviceSoc);
};

export const getTrustPaymentContextInfo: CaseFn = ({
  servicesInfoContextsMap: servicesInfoContexts,
}) => {
  const [TP2, TP2_2] = TRUST_PAYMENT_SOCS;

  return servicesInfoContexts[TP2_2] ?? servicesInfoContexts[TP2];
};
