import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classnames from 'classnames/bind';

import { useConditionsContainer } from './hooks/useConditionsContainer';
import { ConditionsButton } from './componetns/ConditionsButton';
import { ConditionsSteps } from './componetns/ConditionsSteps';
import { ConditionsBill } from './componetns/ConditionsBill';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

/** Компонент-обертка над блоком условий обновления номера.
 * Содержит в себе пошаговую визуализацию обновления и мини-чек */
export const ConditionsContainer: FC = () => {
  /* Готовим данные для компонента ConditionsContainer */
  const { pageTitle, stepsProps, billProps, btnProps } = useConditionsContainer();

  return (
    <section className={cx('wrapper')}>
      {pageTitle && (
        <Text align="left" color="primary" lineHeight={52} size="xl" tagName="h1">
          {pageTitle}
        </Text>
      )}
      <div className={cx('wrapper__content')}>
        <ConditionsSteps {...stepsProps} />
        <ConditionsBill {...billProps} />
      </div>
      <ConditionsButton className={cx('wrapper__button')} {...btnProps} />
    </section>
  );
};
