import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import { Card } from 'pages/MNPLanding/components/Card';

import styles from './styles.pcss';
import { useInputBlock } from './hooks/useInputBlock';
import { InputForm } from './components/InputForm';
import { IInputBlockProps } from './types';

const cx = classNames.bind(styles);

/**
 * Блок с формой переноса номера
 */
export const InputBlock: FC<IInputBlockProps> = ({ className, id }) => {
  const { title } = useInputBlock();

  return (
    <Card border className={cx('card', className)} id={id}>
      <Text variant="headline-small">{title}</Text>
      <InputForm />
    </Card>
  );
};
