import React from 'react';
import classNames from 'classnames/bind';
import { cnTheme } from '@carnica/themes';
import { useGetHeaderHeight } from '@carnica/smart-kit';

import { useIsLargeDesktopScreen } from 'pages/MNPLanding/hooks/media/useIsLargeDesktopScreen';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const MNPLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isLargeDesktop = useIsLargeDesktopScreen();
  const headerHeight = useGetHeaderHeight();

  return (
    <main
      className={cx('layout', cnTheme('light'))}
      id="main-mnp-redesign-layout"
      style={{ paddingTop: isLargeDesktop ? 0 : headerHeight }}
    >
      {children}
    </main>
  );
};
