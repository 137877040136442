import React, { memo } from 'react';
import { IconLockFilled, IconLockOpenFilled } from '@carnica/graphics';
import classNames from 'classnames/bind';

import { LockButtonPropsType } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

// TODO: переписать компонент при рефакторинге
export const LockButton = memo<LockButtonPropsType>(({ locked, onSetLocked }) => {
  const toggleLock = () => {
    onSetLocked(!locked);
  };

  return (
    <button className={cx('lock')} onClick={toggleLock} type="button">
      {locked ?
        <IconLockFilled color="primary-invert" />
      : <IconLockOpenFilled color="tertiary" />}
    </button>
  );
});
