import React, { memo } from 'react';
import { IconLockFilled, IconLockOpenFilled } from '@carnica/graphics';
import classNames from 'classnames/bind';

import { DescriptionPropsType } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

// TODO: переписать компонент при рефакторинге
export const Description = memo<DescriptionPropsType>(({ locked, content }) => (
  <div className={cx('description')}>
    {!locked ?
      <>
        <IconLockOpenFilled color="secondary-invert" /> {content?.descriptions?.locked}
      </>
    : <>
        <IconLockFilled color="secondary-invert" /> {content?.descriptions?.unlocked}
      </>
    }
  </div>
));
