import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';

import { useIsAuthenticated } from 'pages/FancyNumber2022/hooks/useIsAuthenticated';
import { TABS_ALIAS } from 'pages/FancyNumber2024/constants';
import { isChangeNumberLocation } from 'pages/FancyNumber2022/utils';
import { smoothScrollToTop } from 'pages/FancyNumber2024/utils';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { ThunkDispatchType } from 'pages/SPN/types';
import { setTabId } from 'pages/FancyNumber2022/store/actions/content';
import { setChangeNumber } from 'pages/FancyNumber2022/store/actions/change';
import { loadFancyNumberData, setError } from 'pages/FancyNumber2022/store/actions/numbers';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TabProps = {
  children: React.ReactNode;
  id: string;
  label: string;
  link: string;
};

type TabsProps = {
  hasRequestError: boolean;
  onSetTabId: (id: string) => void;
  setIsChangeNumber: (flag: boolean) => void;
  tabs: TabProps[];
};

// TODO: переписать компонент при рефакторинге
export const _CustomTabs: React.FC<TabsProps> = ({
  tabs,
  onSetTabId,
  setIsChangeNumber,
  hasRequestError = false,
}) => {
  const dispatch = useDispatch();
  const isChangeNumber = isChangeNumberLocation();
  const [activeId, setActiveId] = useState(isChangeNumber ? TABS_ALIAS.change : TABS_ALIAS.new);
  const [activeIndex, setActiveIndex] = useState(isChangeNumber ? 1 : 0);
  /* PrevState c activeIndex для корректной работы loadFancyNumberData (костыль!) */
  const [prevActiveIndex, setPrevActiveIndex] = useState(activeIndex);

  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const isMobile = useIsMobileDimension();

  useEffect(() => {
    /* Если текущий activeIndex !== prevActiveIndex, выполняем загрузку номеров через loadFancyNumberData */
    if (activeIndex !== prevActiveIndex) {
      /* Сбрасываем ошибку запроса, если такая имеется */
      if (hasRequestError) {
        // @ts-expect-error ошибка типизации из-за js-файла!
        dispatch(setError(false));
      }

      /* Запрашиваем доступные номера */
      dispatch(loadFancyNumberData());
    }
    /** Если присутствует query-параметр type в URL, выставляем соответствующий таб, как активный.
     * Иначе выставляем базовый таб TABS_ALIAS.new */
    if (isChangeNumber) {
      setActiveId(TABS_ALIAS.change);
      setActiveIndex(1);
      onSetTabId(TABS_ALIAS.change);
      /* Меняем флаг isChangeNumber в store */
      setIsChangeNumber(true);
      return;
    }

    setActiveId(TABS_ALIAS.new);
    setActiveIndex(0);
    onSetTabId(TABS_ALIAS.new);
  }, [isAuthenticated, isChangeNumber, activeIndex]);

  /** Обработчик клика по табу (новый номер/смена номера) */
  const handleTabClick = (id: string, index: number, link: string) => {
    /* Если кликаем по уже активному табу, ничего не делаем */
    if (id === activeId) return;

    /* Записываем в  данные PrevActiveIndex текущий activeIndex для корректного вызова loadFancyNumberData в useEffect */
    setPrevActiveIndex(activeIndex);
    /* Если кликаем по новому табу, выставляем его данные в локальные state и прокручиваем страницу */
    setActiveIndex(index);
    setActiveId(id);
    onSetTabId(id);

    if (!isMobile && !isAuthenticated) {
      smoothScrollToTop({ top: 100 });
    }

    if (!isMobile && isAuthenticated) {
      smoothScrollToTop({ top: 485 });
    }

    if (isMobile && !isAuthenticated) {
      smoothScrollToTop({ top: 347 });
    }

    /** Добавляем в store состояние isChangeNumber, определенное через id таба из параметра функции */
    setIsChangeNumber(id === TABS_ALIAS.change);
    navigate(link);
  };

  return (
    <div
      className={cx('tabs-container')}
      style={{ '--tab-count': tabs.length, '--active-index': activeIndex } as React.CSSProperties}
    >
      <section className={cx('tab_inner-wrapper')}>
        <ul className={cx('tabs-list')}>
          {tabs.map((tab, index) => (
            <li
              className={cx('tabs-element', {
                'tabs-element--active': tab?.id === activeId,
              })}
              key={index}
            >
              <button
                className={cx('tab-button', { 'tab-button--active': tab?.id === activeId })}
                onClick={() => handleTabClick(tab?.id, index, tab?.link)}
                type="button"
              >
                <Text size="size5-m" tagType="span">
                  {tab?.label}
                </Text>
              </button>
            </li>
          ))}
        </ul>
      </section>

      <main className={cx('tab-content')}>
        {tabs.map(
          (tab, index) =>
            tab?.id === activeId && (
              <motion.div className={cx('tab-panel', 'tab-panel--active')} key={index} layout>
                {tab.children}
              </motion.div>
            ),
        )}
      </main>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  // @ts-expect-error ошибка из-за js-файла с actions, которые не типизированы
  onSetTabId: (id: string) => dispatch(setTabId(id)),
  // @ts-expect-error ошибка из-за js-файла с actions, которые не типизированы
  setIsChangeNumber: (flag: boolean) => dispatch(setChangeNumber(flag)),
});

export const CustomTabs = connect(null, mapDispatchToProps)(_CustomTabs);
