import { createReducer } from '@reduxjs/toolkit';

import { CATEGORY_ALIAS } from 'pages/FancyNumber2022/constants';
import { allFiltersTurnedOff } from 'pages/FancyNumber2022/utils';

import { setActiveCategories, setAllCategory, setCategory } from '../actions/category';

export const category = createReducer(
  {
    active: {
      all: true,
    },
  },
  {
    [setCategory.type]: (state, action) => {
      /* Работы с отдельными фильтрами (бесплатные, золотые и т.д.).
       * Если выбирается любой из фильтров (кроме "all"), отключаем "all" и включаем выбранный фильтр (по key) */
      state.active.all = false;
      state.active[action.payload.key] = action.payload.value;
      /* Если отключаются все категории фильтра, выставляем активным "Все" (all) */
      if (allFiltersTurnedOff(state.active)) {
        state.active.all = true;
      }
    },
    [setActiveCategories.type]: (state, action) => {
      action.payload.forEach((key) => {
        state.active[key] = true;
      });
    },
    [setAllCategory.type]: (state, action) => {
      /* Если текущая выбранная категрия в фильтре CATEGORY_ALIAS.all (all), переводим все оставшиеся категории в false */
      if (action.payload.key === CATEGORY_ALIAS.all) {
        Object.keys(state.active).forEach((key) => {
          state.active[key] = false;
        });
        /* Выбираем только категорию фильтра "Все" (all) */
        state.active[action.payload.key] = action.payload.value;
      }
    },
  },
);
