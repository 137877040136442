import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { IconCallingFilled } from '@carnica/graphics';
import { withDotSeparatedProps } from '@beef/landing-constructor/library-utils';
import PropTypes from 'prop-types';
import { withRenderFlag } from '@beef/react';

import {
  StatusText as _StatusText,
  IStatusText,
} from 'pages/ContactPage/components/ChatBlock/components/StatusText';
import withContent from 'pages/ContactPage/utils/withContent';
import { TBlockHeading } from 'pages/ContactPage/components/BlockHeading/types';

import styles from './styles.pcss';

const StatusText: React.FC<IStatusText & { shouldRender: boolean }> = withRenderFlag(_StatusText);

const cn = classNames.bind(styles);

const _BlockHeading = ({ title, description }: TBlockHeading) => {
  return (
    <div className={cn('heading')}>
      <IconCallingFilled className={cn('icon')} size={24} />
      <div className={cn('texts')}>
        <Text className={cn('title')} variant="body-small">
          {title}
        </Text>
        <StatusText
          dotColor="#FEC800"
          shouldRender={description?.length > 0}
          text={description}
          textColor="tertiary"
        />
      </div>
    </div>
  );
};

export const BlockHeading = withDotSeparatedProps(withContent(_BlockHeading), {
  title: PropTypes.string,
  description: PropTypes.string,
});
