import React, { useContext } from 'react';
import { Button, Text } from '@beef/ui-kit';
import TrustPayment from '@beep/trustpayment';
import { openAuthModal } from '@beef/layout-kit/utils';
import PropTypes from 'prop-types';

import { TrustPaymentContext } from 'pages/Services/TrustPayment/context';

export const TrustPaymentModuleButton = ({ variant, size, text, color, handlers = {} }) => {
  const {
    content: { isAvailableToConnect },
    isAuthenticated,
  } = useContext(TrustPaymentContext);

  return (
    <div className="ModuleButton_container">
      <TrustPayment handlers={handlers} isAuth={isAuthenticated} openAuthModal={openAuthModal}>
        {(onClick) => (
          <Button disabled={!isAvailableToConnect} onClick={onClick} size={size} variant={variant}>
            <Text color={color}>{text}</Text>
          </Button>
        )}
      </TrustPayment>
    </div>
  );
};

TrustPaymentModuleButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string,
};
