import React, { FC } from 'react';
import { RootStateOrAny, connect } from 'react-redux';
import classnames from 'classnames/bind';
import { cnTheme } from '@carnica/themes';

import { getCategoriesByPage } from 'pages/FancyNumber2022/store/selectors/content';
import { SelectType } from 'pages/FancyNumber2024/components/NumbersBlock/components/CategoriesSelect/Select/types';

import styles from './styles.pcss';

const cx = classnames.bind(styles);
const lTheme = cnTheme('light');

// TODO: переписать компонент при рефакторинге
export const SelectLayout: FC<SelectType> = ({ options, children }) => (
  <div className={cx('categories', lTheme)}>{options.map(children)}</div>
);

const mapStateToProps = (state: RootStateOrAny) => ({
  options: getCategoriesByPage(state),
});

export const Select = connect(mapStateToProps)(SelectLayout);
