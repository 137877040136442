import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ColumnWrapper: React.FC<{
  children: React.ReactNode;
  className: string;
}> = ({ children, className }) => {
  return <div className={cx('column-wrapper', className)}>{children}</div>;
};
