import { connect } from 'react-redux';
import { map } from '@beef/utils';

import { selectCrudeMappingContext, selectCrudeMappingState } from '../store';

const retrievePath = (object: any, path: string[]): any => {
  if (!path.length) return object;
  return retrievePath(object[path[0]], path.slice(1));
};

const parseCrudeMapping = (
  value: any,
  mapping: (mapPath: any[]) => any,
  localMapping: (localPath: any) => any,
) => {
  if (typeof value !== 'string' || value[0] !== '$') return value;
  if (value[1] === '.' || value[1] === undefined) return mapping(value.substring(2).split('.'));
  if (value[1] === '$' && (value[2] === '.' || value[2] === undefined))
    return localMapping(value.substring(3).split('.'));
  return value;
};

export const withCrudeMapping = () =>
  connect((state, ownProps) =>
    map(ownProps, (value) =>
      parseCrudeMapping(
        value,
        (path) => retrievePath(selectCrudeMappingState(state), path),
        (path) => retrievePath(selectCrudeMappingContext(state), path),
      ),
    ),
  );
