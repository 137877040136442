import { onReloadWithCrash } from 'pages/FancyNumber2024/utils/onReloadWithCrash';

/** Общая функция-обработчик клика по кнопке.
 * Принимает в качестве параметров ссылку для кнопки и url главной страницы */
export const resultHandleClick = ({ link, mainUrl }: { link?: string; mainUrl: string }) => {
  /* Если есть ссылка, открываем ее в новой вкладке браузера */
  if (link) {
    window.open(link, '_blank');
    return;
  }

  /* Уходим на стартовую страницу со сбросом store */
  onReloadWithCrash(mainUrl);
};
