import React from 'react';
import classNames from 'classnames/bind';
import { Avatar, Text } from '@carnica/ui-kit';

import { MenuUrlTarget } from 'pages/ContactPage/utils/utils';

import { TCards } from './types';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const Cards = ({ cards, onClick, disabledCursorPointer }: TCards) => {
  return (
    <div className={cn('cards')}>
      {cards.map(({ title, description, icon, href, isBlankUrlTarget }) => (
        <a
          className={cn('card', disabledCursorPointer && 'default-cursor')}
          href={href}
          onClick={() => onClick?.(title)}
          target={MenuUrlTarget.get(isBlankUrlTarget as boolean)}
        >
          <Text variant="body-small">{title}</Text>
          {description && (
            <Text className={cn('description')} color="secondary" variant="caption-medium">
              {description}
            </Text>
          )}
          {icon && <Avatar image={icon} size="s" />}
        </a>
      ))}
    </div>
  );
};
