import { createAction } from '@reduxjs/toolkit';

import { CATEGORY_ALIAS } from 'pages/FancyNumber2022/constants';
import { setAliasAction } from 'pages/FancyNumber2022/store/actions/content';
import { getActiveFilter } from 'pages/FancyNumber2022/store/selectors/filter';
import {
  getFilterContent,
  selectIsRedesign2024Enabled,
} from 'pages/FancyNumber2022/store/selectors/content';

import { getActiveCategories, getActiveCategoriesArray } from '../selectors/category';
import { updatePageUrl } from './routing';

export const setCategory = createAction('set category');
export const setActiveCategories = createAction('set active categories');
export const setAllCategory = createAction('set all category');

/** Action переключения фильтра категории КН */
export const toggleActiveCategory = (key) => (dispatch, getState) => {
  const isRedesign2024 = selectIsRedesign2024Enabled(getState()) ?? false;

  /* Проверка клика (по key) на фильтр.
   * Если key === CATEGORY_ALIAS.all, запускаем action setAllCategory с установкой фильтра "Все" (all).
   * Иначе, action - setCategory с выбором конкретного фильтра */
  if (key === CATEGORY_ALIAS.all) {
    dispatch(
      setAllCategory({
        key,
        value: !getActiveCategories(getState())[key],
      }),
    );
  } else {
    dispatch(
      setCategory({
        key,
        value: !getActiveCategories(getState())[key],
      }),
    );
  }

  if (isRedesign2024) {
    /** Флаг определяет, выбраны ли все доступные категории в фильтре.
     * - getFilterContent возвращает объект с доступными фильтрами из контента.
     * - getActiveCategoriesArray возвращает массив активных фильтров.
     * Если количество доступных фильтров -1 ("all" не учитываем, поэтому длина на 1 меньше) равно количеству активных фильтров,
     * отключим все активные фильтры (золотой, бронзовый и т.д.) и активируем фильтр "Все" (all) через setAllCategory */
    const isChooseAllAvailableCategories =
      getFilterContent(getState()).categories.length - 1 ===
      getActiveCategoriesArray(getState()).length;

    /* Проверяем, включены ли все доступные фильтры, кроме "Все" (all).
     * Если включены (isChooseAllAvailableCategories), сбрасываем их и включаем базовый вильтр ("Все") */
    if (isChooseAllAvailableCategories) {
      dispatch(
        setAllCategory({
          key: CATEGORY_ALIAS.all,
          value: true,
        }),
      );
    }
  }

  dispatch(
    setAliasAction(
      getActiveCategoriesArray(getState()).length ?
        getActiveCategoriesArray(getState())[0]
      : getActiveFilter(getState()),
    ),
  );
  updatePageUrl({
    categories: getActiveCategoriesArray(getState()),
  });
};
