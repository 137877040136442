import { useSelector } from 'react-redux';

import store from 'store';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { getConfirmationPage } from 'pages/FancyNumber2022/store/selectors/content';
import { TConfirmationContent } from 'pages/FancyNumber2024/components/ConfirmationContainer/components/ConfirmationContent';

import { createConfirmationTexts } from '../utils/createConfirmationTexts';

/** Хук подготовки данных для страницы подтверждения */
export const useConfirmationContent = () => {
  const isMobile = useIsMobileDimension();

  /** Номер телефона авторизованного пользователя из global store */
  const { accountText: connectedNumber } = store.getState().external?.user ?? {};

  /** Контентные данные страницы "подтверждения" (confirmation) */
  const { pageTitle, deskText, mobText, timerText, codeLength, timerRestartText } =
    useSelector(getConfirmationPage);

  /** Описание для страницы на основании текущего устройства */
  const description = isMobile ? mobText : deskText;

  /** Контентные данные компонента ConfirmationContent (подготовленные заголовок и описание страницы) */
  const contentProps: TConfirmationContent = {
    /* Заголовок страницы */
    pageTitle,
    /* Настройки для главного заголовка (Text), в зависимости от устройства (isMobile) */
    pageTitleSettings: {
      size: isMobile ? 'l' : '2xl',
      lineHeight: isMobile ? 42 : 66,
    },
    /* Описание страницы, подготовленное через функцию createConfirmationTexts */
    subTitle: createConfirmationTexts(description, connectedNumber),
    /* Настройки для описания (Text), в зависимости от устройства (isMobile) */
    subTitleSettings: {
      align: isMobile ? 'left' : 'center',
    },
  };

  /** Контентные данные компонента SMSContainer */
  const SMSProps = {
    timerSettings: {
      timerText,
      timerRestartText,
    },
    codeLength,
  };

  return {
    SMSProps,
    contentProps,
  };
};
