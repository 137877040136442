import React from 'react';
import { connect } from 'react-redux';
import { FinalForm, withFinalField } from '@beef/form';
import { Button, Checkbox, Input, PhoneInput, Text } from '@carnica/ui-kit';
import { compose } from '@beef/redux';
import { withRenderFlag } from '@beef/react';
import { ECaptchaStatus, InvisibleCaptcha } from '@beef/captcha';
import type { ExtendedSmartCaptchaProps } from '@beef/captcha';
import classNames from 'classnames/bind';

import {
  selectCurrentDistrict,
  selectCurrentHelpType,
  selectCurrentLocationSelect,
  selectCurrentRegionDistrict,
  selectCurrentRegionGroup,
  selectDistricts,
  selectRegionGroups,
  selectSubmitButtonIsEnabled,
  selectVolunteersFormCaptcha,
  selectVolunteersFormContent,
  selectVolunteersFormValues,
  withDistrictMapper,
  withRegionGroupMapper,
} from 'pages/LizaAlertLandingPage/store/selectors';
import {
  addLocationSelect,
  clearFormValue,
  generateSms,
  refreshSmartCaptcha,
  setFormValues,
  setIsOpenConfirmationModal,
  setIsVisibleSmartCaptcha,
} from 'pages/LizaAlertLandingPage/store/volunteerFormSlice';
import { AppDispatch, RootState } from 'pages/LizaAlertLandingPage/store';
import { EFormInputNames } from 'pages/LizaAlertLandingPage/components/VolunteersForm/types';
import { HeadingRaw } from 'pages/LizaAlertLandingPage/components/Heading';
import { RowContainer } from 'pages/LizaAlertLandingPage/components/RowContainer';
import { VOLUNTEERS_FORM_ID } from 'pages/LizaAlertLandingPage/consts';
import { getValidators } from 'pages/LizaAlertLandingPage/components/VolunteersForm/utils';
import { TRowContainer } from 'pages/LizaAlertLandingPage/components/RowContainer/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const VolunteersFinalForm = connect(
  (state: RootState) => ({
    values: selectVolunteersFormValues(state),
    className: cn('volunteers-form'),
    id: VOLUNTEERS_FORM_ID,
  }),
  (dispatch: AppDispatch) => ({
    onChange: (values: Record<string, unknown>) => dispatch(setFormValues(values)),
    onSubmit: () => {
      dispatch(setIsVisibleSmartCaptcha(true));
      dispatch(setIsOpenConfirmationModal(true));
    },
    getValidators,
  }),
)(FinalForm);

export const SubmitButton = connect((state: RootState) => ({
  children: selectVolunteersFormContent(state).submitButtonText,
  type: 'submit',
  size: 'l',
  disabled: !selectSubmitButtonIsEnabled(state),
}))(Button);

export const HelpTypeCheckbox = connect((state: RootState) => {
  const { name, id } = selectCurrentHelpType(state);
  const fieldName = `${EFormInputNames.helpType}_${id}`;

  return {
    children: name,
    name: fieldName,
    checked: selectVolunteersFormValues(state)[fieldName],
    onChangeProxy: (value: boolean) => (value ? id : value),
    className: cn('checkbox'),
  };
})(withFinalField(Checkbox));

export const NameInput = connect(
  (state: RootState) => ({
    name: EFormInputNames.name,
    label: selectVolunteersFormContent(state).nameFieldLabel,
    placeholder: selectVolunteersFormContent(state).nameFieldPlaceholder,
    className: cn('input'),
    withMobileOffset: false,
  }),
  (dispatch) => ({
    onClear: () => dispatch(clearFormValue(EFormInputNames.name)),
  }),
)(withFinalField(Input));

export const LastNameInput = connect(
  (state: RootState) => ({
    name: EFormInputNames.lastName,
    label: selectVolunteersFormContent(state).lastNameFieldLabel,
    placeholder: selectVolunteersFormContent(state).lastNameFieldPlaceholder,
    className: cn('input'),
    withMobileOffset: false,
  }),
  (dispatch) => ({
    onClear: () => dispatch(clearFormValue(EFormInputNames.lastName)),
  }),
)(withFinalField(Input));

export const PhoneNumberInput = connect(
  (state: RootState) => ({
    name: EFormInputNames.phoneNumber,
    label: selectVolunteersFormContent(state).phoneNumberFieldLabel,
    placeholder: selectVolunteersFormContent(state).phoneNumberFieldPlaceholder,
    className: cn('input'),
    withMobileOffset: false,
  }),
  (dispatch) => ({
    onClear: () => dispatch(clearFormValue(EFormInputNames.phoneNumber)),
  }),
)(withFinalField(PhoneInput));

export const HelpTypesHeading = connect((state: RootState) => ({
  title: selectVolunteersFormContent(state).helpTypesTitle,
  size: 'headline-small',
  indent: 'xs',
}))(HeadingRaw);

export const YourContactsHeading = connect((state: RootState) => ({
  title: selectVolunteersFormContent(state).yourContactsTitle,
  size: 'headline-small',
  indent: 'xs',
}))(HeadingRaw);

export const VolunteersFormHeading = connect((state: RootState) => ({
  title: selectVolunteersFormContent(state).formTitle,
  size: 'display-small',
  description: selectVolunteersFormContent(state).formDescription,
}))(HeadingRaw);

export const RegionGroupList = compose<React.FC<TRowContainer>>(
  withRegionGroupMapper,
  connect((state: RootState) => ({
    shouldRender: selectRegionGroups(state).length > 0,
    gap: 'xs',
  })),
  withRenderFlag,
)(RowContainer);

export const RegionAllDistrictsCheckbox = connect((state: RootState) => {
  const { id } = selectCurrentRegionGroup(state);
  const fieldName = `${EFormInputNames.location}_${selectCurrentLocationSelect(state).id}_${
    EFormInputNames.district
  }_${id}`;

  return {
    key: fieldName,
    children: selectVolunteersFormContent(state).allRegionDistrictCheckboxLabel,
    name: fieldName,
    checked: selectVolunteersFormValues(state)[fieldName],
    onChangeProxy: (value: boolean) => (value ? id : value),
    className: cn('checkbox', 'districts-checkbox'),
  };
})(withFinalField(Checkbox));

export const RegionDistrictCheckbox = connect((state: RootState) => {
  const { name, id } = selectCurrentRegionDistrict(state);
  const fieldName = `${EFormInputNames.location}_${selectCurrentLocationSelect(state).id}_${
    EFormInputNames.district
  }_${id}`;

  return {
    key: fieldName,
    children: name,
    name: fieldName,
    checked: selectVolunteersFormValues(state)[fieldName],
    onChangeProxy: (value: boolean) => (value ? id : value),
    className: cn('checkbox'),
  };
})(withFinalField(Checkbox));

export const DistrictList = compose<React.FC<TRowContainer>>(
  withDistrictMapper,
  connect((state: RootState) => ({
    gap: 'm',
    shouldRender: selectDistricts(state).length > 0,
  })),
  withRenderFlag,
)(RowContainer);

export const DistrictCheckbox = connect((state: RootState) => {
  const { name, id } = selectCurrentDistrict(state);
  const fieldName = `${EFormInputNames.location}_${selectCurrentLocationSelect(state).id}_${
    EFormInputNames.district
  }_${id}`;

  return {
    key: fieldName,
    children: name,
    name: fieldName,
    checked: selectVolunteersFormValues(state)[fieldName],
    onChangeProxy: (value: boolean) => (value ? id : value),
    className: cn('checkbox'),
  };
})(withFinalField(Checkbox));

export const AddLocationButton = connect(
  (state: RootState) => ({
    children: selectVolunteersFormContent(state).cityAddButton,
    size: 'l',
    type: 'button',
  }),
  {
    onClick: addLocationSelect,
  },
)(Button);

export const ConsentCheckbox = connect((state: RootState) => ({
  children: selectVolunteersFormContent(state).consentCheckboxLabel,
  name: EFormInputNames.consent,
  checked: selectVolunteersFormValues(state)[EFormInputNames.consent],
  className: cn('consent-checkbox', 'checkbox'),
}))(withFinalField(Checkbox));

export const ConsentText = connect((state: RootState) => ({
  children: selectVolunteersFormContent(state).consentText,
  variant: 'caption-medium',
  color: 'secondary',
  className: cn('consent-text'),
}))(Text);

export const YandexCaptcha: React.FC<Pick<ExtendedSmartCaptchaProps, 'clientKey'>> = connect(
  (state: RootState) => ({
    enabled: selectVolunteersFormCaptcha(state).isVisible,
    refresh: selectVolunteersFormCaptcha(state).refresh,
    hideShield: true,
  }),
  (dispatch: AppDispatch) => ({
    setToken: (token: string) => dispatch(generateSms(token)),
    onChangeStatus: (status: ECaptchaStatus) => {
      dispatch(setIsVisibleSmartCaptcha(false));
      if (status === ECaptchaStatus.Success || status === ECaptchaStatus.Declined) return;
      dispatch(refreshSmartCaptcha());
    },
  }),
)(InvisibleCaptcha);
