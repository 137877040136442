import React, { FC } from 'react';

import { Status } from 'pages/FancyNumber2024/components/Status';

import { useResultContent } from './hooks/useResultContent';

/** Компонент-обертка над результирующей страницей */
export const ResultContainer: FC = () => {
  /* Готовим данные для компонента Status */
  const statusContent = useResultContent();

  return <Status {...statusContent} />;
};
