import React, { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Button, CloseIcon, Input, Label, Link, ReloadIcon } from '@beef/ui-kit';

import { CAPTCHA_STATUS } from 'pages/FTTB2021/hoc/CaptchaWithCallbackPopupHOC';
import { requestCaptcha } from 'pages-components/Captcha/services';
import { CaptchaFNProps } from 'pages-components/Captcha/CaptchaFN/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const DEFAULT_CAPTCHA_WIDTH = 140;
const DEFAULT_CAPTCHA_HEIGHT = 32;

const CaptchaFN = memo<CaptchaFNProps>(
  ({
    name,
    width = DEFAULT_CAPTCHA_WIDTH,
    height = DEFAULT_CAPTCHA_HEIGHT,
    onBlur,
    onFocus,
    changeCaptchaToken,
    changeCaptchaStatus,
    msg,
    setCaptchaKey,
    onMount,
    onChange,
    errorMsg,
    changeCaptchaMsg,
    newCaptcha,
    changeNewCaptcha,
    onSubmit,
    launchCaptcha,
  }) => {
    const [pic, setPic] = useState<null | string>(null);
    const [captcha, setCaptcha] = useState('');
    const [captchaKey, setCaptchaKeyState] = useState('');

    useEffect(() => {
      if (launchCaptcha) {
        onSubmit();
      }
    }, [launchCaptcha]);

    const fetchCaptcha = useCallback(() => {
      const params = new URLSearchParams({
        w: width.toString(),
        h: height.toString(),
        ...(captchaKey ? { key: captchaKey } : {}),
        r: new Date().getTime().toString(),
      }).toString();

      requestCaptcha(`/captcha.ashx?${params}`)
        .then(({ pic: reqPic, key }) => {
          if (!captchaKey && key) {
            setCaptchaKey?.(key);
          }
          setPic(reqPic);
          setCaptchaKeyState(key);
        })
        .catch(console.error);

      setCaptcha('');
      changeCaptchaMsg?.('');
      changeCaptchaToken?.('');
      changeCaptchaStatus?.(CAPTCHA_STATUS.success);
    }, [
      width,
      height,
      captchaKey,
      setCaptchaKey,
      changeCaptchaMsg,
      changeCaptchaToken,
      changeCaptchaStatus,
    ]);

    useEffect(() => {
      fetchCaptcha();
      onMount?.();
    }, []);

    useEffect(() => {
      if (newCaptcha) {
        fetchCaptcha();
        setCaptcha('');
        changeNewCaptcha?.(false);
      }
    }, [newCaptcha, fetchCaptcha, changeNewCaptcha]);

    const handleChange = (value: string) => {
      setCaptcha(value);
      onChange?.(value);
    };

    const handleClear = () => {
      setCaptcha('');
      onChange?.('');
    };

    return (
      <div className={cx('wrapper')}>
        <div className={cx('main_block')}>
          <Label className={cx('captcha-label')} id="captchaFN-input">
            {msg?.title}
          </Label>
          <div className={cx('input-container')}>
            <Input
              className={cx('captcha-input')}
              error={errorMsg}
              errorMessage={errorMsg}
              id="captchaFN-input"
              mask="_____"
              name={name}
              onBlur={onBlur}
              onChange={(e) => handleChange(e?.target?.value)}
              onFocus={onFocus}
              size="m"
              testName="Captcha-input"
              type="text"
              value={captcha}
            />
            {captcha && (
              <Button
                className={cx('clear-button')}
                icon={<CloseIcon fill="#98A0B0" size="m" />}
                onClick={handleClear}
                type="reset"
                variant="plain"
              />
            )}
          </div>
          <div className={cx('captcha-block')}>
            <img alt=" " className={cx('captcha-img')} role="presentation" src={pic} />
            <Link
              className={cx('captcha-reset-btn')}
              icon={<ReloadIcon fill="#FFFFFF" size="m" />}
              onClick={fetchCaptcha}
              testName="Captcha_reset"
              variant="light"
            />
          </div>
        </div>
      </div>
    );
  },
);

export default CaptchaFN;
