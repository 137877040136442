import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface AppContainerProps {
  children?: React.ReactNode;
  className?: string;
}

export const AppContainer: React.FC<AppContainerProps> = ({ children, className }) => {
  return <div className={cx('container', className)}>{children}</div>;
};
