import React from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { Android, IOS } from 'pages/CarnicaDSLandings/utils/PlatformContainers';
import { Mobile } from 'pages/MainPage/utils/ResponsiveContainers';

import styles from './styles.pcss';
import { AppContentProps, AppStoresLinks } from '../../types';
import { StoreLink } from '../StoreLink';

const cx = classNames.bind(styles);

export const ContentBlock: React.FC<AppContentProps & AppStoresLinks> = ({
  description,
  title,
  iconUrl,
  googleStore,
  appStore,
  ruStore,
  huaweiStore,
}) => {
  return (
    <div className={cx('content')}>
      <img alt="" className={cx('icon')} src={iconUrl} />
      <Text className={cx('title')} size="l">
        {title}
      </Text>
      <Text className={cx('description')} color="secondary" size="xs">
        {description}
      </Text>
      <Mobile>
        <div className={cx('store-links')}>
          <IOS>
            <StoreLink
              fill="dark"
              href={appStore.href}
              imageUrl={appStore.imageFullUrl}
              label="AppStore"
              mode="full"
            />
          </IOS>
          <Android>
            <StoreLink
              fill="dark"
              href={googleStore.href}
              imageUrl={googleStore.imageUrl}
              label="Google Play"
            />
          </Android>
          <Android>
            <StoreLink
              fill="dark"
              href={huaweiStore.href}
              imageUrl={huaweiStore.imageUrl}
              label="App Gallery"
            />
          </Android>
          <Android>
            <StoreLink
              fill="dark"
              href={ruStore.href}
              imageUrl={ruStore.imageUrl}
              label="Ru Store"
            />
          </Android>
        </div>
      </Mobile>
    </div>
  );
};
