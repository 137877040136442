import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useThrowAsyncError } from 'pages/FancyNumber2024/hooks/useThrowAsyncError';
import { manageSms } from 'pages/FancyNumber2024/store/changeSlice/asyncThunks/manageSms';

/** Хук отправки запроса на получение SMS-кода для подтверждения обновления номера телефона */
export const useGetSms = () => {
  const dispatch = useDispatch();
  const throwAsyncError = useThrowAsyncError();

  /* Единожды отправляем запрос на получение SMS-кода, при переходе на страницу */
  useEffect(() => {
    dispatch(manageSms())
      // @ts-expect-error ошибка типизации
      ?.unwrap()
      .catch((e: Error) => throwAsyncError(e));
  }, []);
};
