import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import { Metric } from 'pages/MNPLanding/analytics';
import { Card } from 'pages/MNPLanding/components/Card';

import styles from './styles.pcss';
import { useListBlock } from './hooks/useListBlock';

const cx = classNames.bind(styles);

/**
 * Список условий для переноса номера
 */
export const ListBlock: FC = () => {
  const { icon, list, title, isMobile, isMnpTariffEnabled } = useListBlock();

  return (
    <Metric
      data={title}
      events={{
        yaMNPMetric: [isMnpTariffEnabled ? 'handleViewTariffListBlock' : 'handleViewListBlock'],
      }}
      mode="viewport"
    >
      <Card
        border={isMnpTariffEnabled && !isMobile}
        className={cx('card', { 'card--tariff': isMnpTariffEnabled })}
        style={isMobile ? {} : { backgroundImage: `url(${icon})` }}
      >
        <div className={cx('content')}>
          <Text className={cx('title')} variant="headline-small">
            {title}
          </Text>

          <ul className={cx('list')}>
            {list.map(({ id, text }) => (
              <Text key={id} tagName="li" variant="body-small">
                {text}
              </Text>
            ))}
          </ul>
        </div>
      </Card>
    </Metric>
  );
};
