import React, { memo, useCallback, useEffect, useState } from 'react';
import { RootStateOrAny, connect } from 'react-redux';

import { CAPTCHA_STATUS } from 'pages/FTTB2021/hoc/CaptchaWithCallbackPopupHOC';
import { CaptchaPopupProps } from 'pages/FancyNumber2022/components/CaptchaWrapper/components/CaptchaPopup/types';
import CaptchaFN from 'pages-components/Captcha/CaptchaFN';
import { getLaunchCaptcha } from 'pages/FancyNumber2022/store/selectors/numbers';
import { ThunkDispatchType } from 'pages/SPN/types';
import { setLaunchCaptchaRequest } from 'pages/FancyNumber2022/store/actions/numbers';
import { wrapAction } from 'pages/FancyNumber2024/utils';

// TODO: переписать компонент при рефакторинге
export const _CaptchaPopup = memo<CaptchaPopupProps>(
  ({ data, launchCaptcha, onSetLaunchCaptcha }) => {
    const {
      reCaptchaModel,
      onBackCallBtnClick,
      texts,
      captchaStatus,
      changeCaptchaStatus,
      captchaMsg,
      changeCaptchaMsg,
      captchaToken,
      changeCaptchaToken,
      isFetching,
    } = data;

    const [newCaptcha, setNewCaptcha] = useState(false);
    const [isFetchingCompleted, setIsFetchingCompleted] = useState(false);

    useEffect(() => {
      if (isFetchingCompleted && !isFetching) {
        setNewCaptcha((prevState) => !prevState);
        setIsFetchingCompleted(false);
      }
    }, [isFetching, isFetchingCompleted, newCaptcha]);

    const handleSubmit = useCallback(() => {
      if (captchaToken && captchaStatus === CAPTCHA_STATUS.success) {
        onBackCallBtnClick();
        setIsFetchingCompleted(true);
      }
    }, [captchaStatus, captchaToken, onBackCallBtnClick]);

    const inputOnBlur = useCallback(() => {
      if (!captchaToken) {
        changeCaptchaStatus(CAPTCHA_STATUS.fail);
        changeCaptchaMsg(texts.captchaTexts.title);
        onSetLaunchCaptcha(false);
      } else if (captchaToken.length < 5) {
        changeCaptchaStatus(CAPTCHA_STATUS.fail);
        changeCaptchaMsg(texts.captchaTexts.errorMsg);
        onSetLaunchCaptcha(false);
      } else {
        changeCaptchaStatus(CAPTCHA_STATUS.success);
        changeCaptchaMsg('');
        onSetLaunchCaptcha(false);
      }
    }, [captchaToken, changeCaptchaMsg, changeCaptchaStatus, texts.captchaTexts]);

    const inputOnFocus = () => changeCaptchaMsg('');

    return (
      <CaptchaFN
        {...reCaptchaModel}
        changeCaptchaMsg={changeCaptchaMsg}
        changeCaptchaStatus={changeCaptchaStatus}
        changeCaptchaToken={changeCaptchaToken}
        changeNewCaptcha={setNewCaptcha}
        errorMsg={captchaMsg}
        launchCaptcha={launchCaptcha}
        msg={texts.captchaTexts}
        newCaptcha={newCaptcha}
        onBlur={inputOnBlur}
        onFocus={inputOnFocus}
        onSubmit={handleSubmit}
        status={captchaStatus}
      />
    );
  },
);

const mapStateToProps = (state: RootStateOrAny) => ({
  launchCaptcha: getLaunchCaptcha(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  onSetLaunchCaptcha: wrapAction(dispatch, setLaunchCaptchaRequest),
});

export const CaptchaPopup = connect(mapStateToProps, mapDispatchToProps)(_CaptchaPopup);
