import { TConditionsStepsItem } from 'pages/FancyNumber2024/components/ConditionsContainer/componetns/ConditionsStepsItem';

/** Функция подмешивает в массив conditionsSteps размер текста в зависимости от флага isMobile */
export const createConditionsSteps = (
  isMobile: boolean,
  conditionsSteps: TConditionsStepsItem[],
) => {
  return conditionsSteps?.map((conditionsStep: TConditionsStepsItem) => ({
    size: isMobile ? 'xs' : 's',
    ...conditionsStep,
  }));
};
