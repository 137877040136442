import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Text } from '@carnica/ui-kit';

import { enhancedConnect } from 'utils/lowCodeHelpers';
import { TTextInfo } from 'pages/LizaAlertLandingPage/components/TextInfo/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const _TextInfo = ({ title, description }: TTextInfo) => (
  <div className={cn('wrapper')}>
    <Text className={cn('title')} variant="display-small">
      {title}
    </Text>
    <Text className={cn('description')} color="tertiary" variant="body-paragraph-small">
      {description}
    </Text>
  </div>
);

export const TextInfo = enhancedConnect({
  title: PropTypes.string,
  description: PropTypes.string,
})(_TextInfo);
