import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { Buttons, TButtons } from './components/Buttons';
import { Content, TContent } from './components/Content';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TStatus = TContent & TButtons;

/** Компонент статуса (финальной/информационной страницы для FancyNumber2024) */
export const Status: FC<TStatus> = ({
  title,
  subTitle,
  acceptText,
  declineText,
  onAccept,
  onDecline,
  icon,
}) => (
  <div className={cx('wrapper')}>
    <Content {...{ icon, title, subTitle }} />
    <Buttons {...{ acceptText, declineText, onAccept, onDecline }} />
  </div>
);
