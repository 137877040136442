import { EIconsUrl } from 'pages/FancyNumber2024/components/AttentionContainer/constants';
import { resultHandleClick } from 'pages/FancyNumber2024/components/ResultContainer/utils/resultHandleClick';

/** Функция выборки контента из объекта errors
 * по текущей возникшей ошибке при обновлении номера телефона (currentError) */
export const createErrorsContent = ({
  errors,
  currentError,
  mainUrl,
}: {
  currentError: string;
  // TODO: типизировать после реализации отдельного store
  errors: Record<any, any>;
  mainUrl: string;
}) => {
  /** Текущий контент ошибки по currentError */
  const { buttons, title, subTitle, acceptLink, declineLink } = errors?.[currentError] ?? {};

  return {
    /* Текущая иконка (ошибка) */
    icon: EIconsUrl.error,
    /* Заголовок страницы с ошибкой */
    title,
    /* Описание ошибки */
    subTitle,
    /* Функция-обработчик главной кнопки */
    onAccept: () => resultHandleClick({ link: acceptLink, mainUrl }),
    /* Функция-обработчик дополнительной кнопки */
    onDecline: () => resultHandleClick({ link: declineLink, mainUrl }),
    /* Текста для кнопок */
    ...buttons,
  };
};
