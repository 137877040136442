import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { axiosBeelineRequest } from '@beef/utils';

import {
  GENERATE_SMS_API,
  VALIDATION_SMS_API,
  VOLUNTEERS_FORM_API,
} from 'pages/LizaAlertLandingPage/consts';
import {
  selectVolunteersConfirmationModal,
  selectVolunteersFormValues,
} from 'pages/LizaAlertLandingPage/store/selectors';
import { EFormInputNames } from 'pages/LizaAlertLandingPage/components/VolunteersForm/types';
import type { AppDispatch, RootState } from 'pages/LizaAlertLandingPage/store';
import { EFieldErrors } from 'pages/LizaAlertLandingPage/components/VolunteersForm/utils';

export interface IVolunteerFormSlice {
  [key: string]: any;
}

export enum EFetchStatus {
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
}

export enum EConfirmationModalStep {
  code = 'code',
  success = 'success',
  error = 'error',
}

const initialState: IVolunteerFormSlice = {
  validateCodeFetchStatus: '',
  formValues: {
    [EFormInputNames.consent]: true,
  },
  locationSelects: [
    {
      id: 0,
    },
  ],
  smartCaptcha: {
    isVisible: false,
    token: '',
    refresh: 0,
  },
  confirmationModal: {
    isOpen: false,
    step: EConfirmationModalStep.code,
    code: '',
    codeError: '',
  },
};

const volunteersFormSlice = createSlice({
  name: 'volunteersForm',
  initialState,
  reducers: {
    setFormValues(state, { payload }) {
      state.formValues = payload;
    },
    clearFormValue(state, { payload }) {
      state.formValues = {
        ...state.formValues,
        [payload]: '',
      };
      if (!payload.startsWith(EFormInputNames.location)) return;
      const dependentValues = Object.fromEntries(
        Object.entries(state.formValues)
          .filter(([name]) => name.startsWith(payload))
          .map(([name]) => [name, '']),
      );
      state.formValues = {
        ...state.formValues,
        ...dependentValues,
      };
    },
    removeLocationSelect(state, { payload }) {
      state.formValues = {
        ...state.formValues,
        [payload.name]: '',
      };
      state.locationSelects = state.locationSelects.filter(({ id }: any) => id !== payload.id);
    },
    resetForm(state) {
      const clearedValues = Object.fromEntries(
        Object.entries(state.formValues).map(([name]) => [name, '']),
      );
      state.formValues = {
        ...clearedValues,
        [EFormInputNames.consent]: true,
      };
      state.locationSelects = [
        {
          id: 0,
        },
      ];
      state.confirmationModal.code = '';
    },
    addLocationSelect(state) {
      state.locationSelects.push({
        id: state.locationSelects.at(-1).id + 1,
      });
    },
    setIsOpenConfirmationModal(state, { payload }) {
      state.confirmationModal.isOpen = payload;
    },
    setConfirmationModalStep(state, { payload }) {
      state.confirmationModal.step = payload;
    },
    setConfirmationCode(state, { payload }) {
      state.confirmationModal.codeError = '';
      state.confirmationModal.code = payload;
    },
    setIsVisibleSmartCaptcha(state, { payload }) {
      state.smartCaptcha.isVisible = payload;
    },
    setSmartCaptchaToken(state, { payload }) {
      state.smartCaptcha.token = payload;
    },
    refreshSmartCaptcha(state) {
      state.smartCaptcha.refresh += 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateCode.pending, (state) => {
        state.confirmationModal.validateCodeFetchStatus = EFetchStatus.loading;
      })
      .addCase(validateCode.fulfilled, (state) => {
        state.confirmationModal.validateCodeFetchStatus = EFetchStatus.loaded;
      })
      .addCase(validateCode.rejected, (state, { payload }) => {
        if (payload.response.status === 401) {
          state.confirmationModal.codeError = EFieldErrors.code;
        }
        state.confirmationModal.validateCodeFetchStatus = EFetchStatus.error;
      });
    builder.addMatcher(isAnyOf(generateSms.rejected, sendVolunteerForm.rejected), (state) => {
      state.confirmationModal.step = EConfirmationModalStep.error;
    });
  },
});

export const {
  setFormValues,
  refreshSmartCaptcha,
  clearFormValue,
  addLocationSelect,
  setIsOpenConfirmationModal,
  setIsVisibleSmartCaptcha,
  setConfirmationCode,
  setConfirmationModalStep,
  removeLocationSelect,
  resetForm,
} = volunteersFormSlice.actions;

export const generateSms = createAsyncThunk<void, string>(
  'volunteerForm/generateSms',
  // eslint-disable-next-line consistent-return
  async (smartCaptchaToken, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const phoneNumber = selectVolunteersFormValues(state)[EFormInputNames.phoneNumber];
    try {
      await axiosBeelineRequest({
        method: 'post',
        url: GENERATE_SMS_API,
        data: { phoneNumber },
        headers: { 'X-SmartCaptcha-Token': smartCaptchaToken },
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const validateCode = createAsyncThunk(
  'volunteerForm/validateCode',
  // eslint-disable-next-line consistent-return
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const phoneNumber = selectVolunteersFormValues(state)[EFormInputNames.phoneNumber];
    const { code } = selectVolunteersConfirmationModal(state);
    try {
      await axiosBeelineRequest({
        method: 'get',
        url: VALIDATION_SMS_API,
        params: { phoneNumber, code },
        originalResponse: true,
      });
      // eslint-disable-next-line no-void
      void dispatch(sendVolunteerForm());
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const sendVolunteerForm = createAsyncThunk(
  'volunteerForm/sendVolunteerForm',
  // eslint-disable-next-line consistent-return
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const formValues = selectVolunteersFormValues(state);
    const groupedValues = Object.entries(formValues).reduce(
      (grouped, [name, value]) => {
        if (name.startsWith(`${EFormInputNames.location}_`) && value)
          grouped.locations.push(value.key || value);
        if (name.startsWith(EFormInputNames.helpType) && value) grouped.helpTypes.push(value);
        return grouped;
      },
      { helpTypes: [], locations: [] },
    );
    const body = {
      firstName: formValues[EFormInputNames.name],
      lastName: formValues[EFormInputNames.lastName],
      phoneNumber: formValues[EFormInputNames.phoneNumber],
      locations: groupedValues.locations,
      helpTypes: groupedValues.helpTypes,
      searchKind: 'all',
    };
    try {
      await axiosBeelineRequest({
        method: 'post',
        url: VOLUNTEERS_FORM_API,
        data: body,
      });
      dispatch(setConfirmationModalStep(EConfirmationModalStep.success));
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const refreshStatus = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  const currentStep = selectVolunteersConfirmationModal(state).step;
  dispatch(refreshSmartCaptcha());
  if (currentStep === EConfirmationModalStep.success) {
    dispatch(setIsOpenConfirmationModal(false));
    dispatch(setConfirmationModalStep(EConfirmationModalStep.code));
    dispatch(resetForm());
    return;
  }
  dispatch(setConfirmationModalStep(EConfirmationModalStep.code));
};

export const closeConfirmationModal = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  const currentStep = selectVolunteersConfirmationModal(state).step;
  dispatch(setIsOpenConfirmationModal(false));
  if (currentStep !== EConfirmationModalStep.success) return;
  dispatch(resetForm());
  dispatch(refreshSmartCaptcha());
  dispatch(setConfirmationModalStep(EConfirmationModalStep.code));
};

export const volunteersForm = volunteersFormSlice.reducer;
