import React, { memo } from 'react';
import { Text } from '@beef/ui-kit';
import classnames from 'classnames/bind';

import { numberToHtml } from 'utils/fancyNumberBeautifier';
import { StyledNumberPropsType } from 'pages/FancyNumber2024/components/StyledNumber/types';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

// TODO: переписать компонент при рефакторинге
/**
 * Renders fancy number with highlighting of combinations and with appropriate indents
 */
export const StyledNumber = memo<StyledNumberPropsType>(
  ({ number, size = 'size5-r-s', highlightColor = '#FFE069', color }) => {
    // for redesign2024
    const additionalStylesOptionsForRedesign2024 = {
      begin: ['border-top-left-radius:4px', 'border-bottom-left-radius:4px'],
      end: ['border-top-right-radius:4px', 'border-bottom-right-radius:4px'],
    };

    return (
      <Text className={cx('styled-text-wrapper')} color={color} size={size}>
        {numberToHtml({
          number: {
            formattedValue: number?.formattedValue,
            value: number?.value,
            isBought: false,
          },
          color: highlightColor,
          additionalStylesOptionsForRedesign2024,
        })}
      </Text>
    );
  },
);
