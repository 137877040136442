import React, { FC } from 'react';

import { PageContainer } from 'pages/FancyNumber2024/components/PageContainer';
import { ConfirmationContainer } from 'pages/FancyNumber2024/components/ConfirmationContainer';
import { useGetSms } from 'pages/FancyNumber2024/components/ConfirmationContainer/hooks/useGetSms';

/** Контейнер страницы подтверждения оформления услуги FancyNumber2024.
 * Используем дополнительный PageContainer из-за особенности верстки главного компонента _FancyNumberLayout2024 */
export const FancyNumberConfirmation: FC = () => {
  /** Отправляем запрос на получение SMS-кода для подтверждения обновления номера телефона */
  useGetSms();

  return (
    <PageContainer>
      <ConfirmationContainer />
    </PageContainer>
  );
};
