import { connect } from 'react-redux';

import { getContentData } from 'pages/ContactPage/store';

const getValueByPath = (obj: Record<string, any>, path: string) => {
  if (!path.length) {
    return obj;
  }

  const [firstKey, ...remainingKeys] = path.split('.');

  if (!obj || !Object.prototype.hasOwnProperty.call(obj, firstKey)) {
    throw new Error(`Cannot find property ${firstKey} in content.`);
  }

  return getValueByPath(obj[firstKey], remainingKeys.join('.'));
};

const withContent = connect(
  (state) => ({
    content: getContentData(state),
  }),
  null,
  (stateProps, dispatchProps, ownProps: Record<string, any>) => {
    const newOwnProps = { ...ownProps };

    Object.keys(newOwnProps).forEach((propName) => {
      if (typeof newOwnProps[propName] === 'string' && newOwnProps[propName].startsWith('$.')) {
        try {
          newOwnProps[propName] = getValueByPath(
            stateProps.content,
            newOwnProps[propName].slice(2),
          );
        } catch (error) {
          console.error(error.message);
        }
      }
    });

    return { ...newOwnProps, ...dispatchProps };
  },
);

export default withContent;
