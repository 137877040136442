import type { AxiosInstance } from 'axios';

export interface IErrorContent {
  buttonText: string;
  content: string;
  icon: string;
  title: string;
}

export interface ICartData {
  ab?: Record<string, 'false' | 'true'>;
  actionStats?: {
    quantity: number;
  };
  bnpl?: Array<{
    available: boolean;
    bnplPayment: number;
    type?: string;
  }>;
  client?: {
    clientType?: string;
  };
  id?: string;
  items?: Array<Record<string, unknown>>;
  orderNumber?: string;
  promoCodeInfo?: Record<string, unknown>;
  regionId: number;
  regionPath?: string;
  stats?: {
    quantity: number;
  };
}

export interface IDispatchProps {
  handleSetCartData: (v: ICartData) => void;
  onRequestError: (v: IErrorContent) => void;
  onStoreButtonClick: () => void;
}

export interface IStateProps {
  additionalOptions?: {
    chosenSocs?: string;
  };
  boughtNumbers: Array<string>;
  cartApi: AxiosInstance;
}

export interface IOwnProps {
  [key: string]: any;
  availableInShop: boolean;
  esimData?: {
    appButtons: Record<string, unknown>;
    descriptionList: Array<{ text: string; tooltip: string }>;
    qrCodeUrl: string;
  };
  fancyNumberModalChoice: boolean;
  onNumberChange?: () => void;
}

export enum OptionStatus {
  Sim = 'sim',
  Esim = 'esim',
}

export enum SubmitStatus {
  Initial = 'initial',
  Pending = 'pending',
  Success = 'success',
}
