import React, { FC } from 'react';
import classnames from 'classnames/bind';

import RightStars from '../../../../assets/right-stars.svg';
import LeftStars from '../../../../assets/left-stars.svg';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

type TConditionsStar = {
  className?: string;
  type?: 'left' | 'right';
};
/** Компонент "звёзд" для ConditionsNumber (фон блока в чеке) */
export const ConditionsStar: FC<TConditionsStar> = ({ className, type = 'left' }) => (
  <div className={cx('wrapper', className)}>
    {type === 'left' ?
      <LeftStars />
    : <RightStars />}
  </div>
);
