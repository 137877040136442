import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ProductTile } from '@beef/legacy-ui-kit';

import wideCard from '../../utils/wideCard';
import { ServiceInfo } from '../ServiceInfo';

const getServiceInfoComponent = (serviceInfoContext) => () => (
  <ServiceInfo
    color={serviceInfoContext?.color}
    shouldRender={!!serviceInfoContext.text}
    text={serviceInfoContext?.text}
    tooltipText={serviceInfoContext?.additionalText}
  />
);

const ServiceTile = ({ service, className, onClick, serviceInfoContext = {} }) => {
  const addYandexToParams = useMemo(
    () => (params) =>
      params?.reduce((acc, curr) => {
        if (service?.soc === 'YANDEX_PD' && curr.label === 'Абонентская плата') {
          return [
            ...acc,
            {
              key: 'SubscriptionFee',
              label: 'Абонентская плата',
              legal: null,
              template: null,
              unit: '₽',
              value: '199',
              subscriptionFeeDiscount: '<br>первые 90 дней бесплатно',
            },
          ];
        }
        return [...acc, curr];
      }, []),
    [service?.parameters],
  );

  return (
    <ProductTile
      arrowLink
      availableForConnect={service.availableForConnect}
      buttonLink={`${service.link}?connect=true`}
      buttonText={service.connectionButton || 'Подробнее'}
      className={className}
      connectedText={service.connectedText || 'Подключено'}
      description={service.description}
      forArchiveTariff={service.forArchiveTariff}
      hideSubscribeButton={service.hideSubscribeButton}
      id={service.id}
      isConnected={service.isConnected}
      link={service.link}
      onClick={onClick}
      parameters={addYandexToParams(service?.parameters)}
      title={service.title}
      {...wideCard(service)}
      ServiceInfoComponent={getServiceInfoComponent(serviceInfoContext)}
      serviceImage={service.serviceImage}
    />
  );
};

ServiceTile.propTypes = {
  serviceInfoContext: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string,
    tooltipText: PropTypes.string,
  }),
  service: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ServiceTile;
