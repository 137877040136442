import { ChangeEvent, useEffect } from 'react';

import {
  IInputField,
  IInputFieldsProps,
  InputFieldsType,
} from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/types';

// TODO: переписать hook при рефакторинге
export const useInputFields = ({
  position,
  originalInputDataFields,
  setOriginalInputDataFields,
  onSetFilterQuery,
  setSecondInputValue,
  inputRefs,
  locked,
  initialInputDataFields,
  inputDataFields,
  hasAtLeastOneFilledInputCell,
}: Partial<IInputFieldsProps>) => {
  useEffect(() => {
    // очищение поля при переключении
    if (!locked) {
      setOriginalInputDataFields?.(initialInputDataFields);
    } else {
      setSecondInputValue?.('');
    }
  }, [locked]);

  // фокус на первый инпут при загрузке страницы
  useEffect(() => {
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (position === 0 && !hasAtLeastOneFilledInputCell) {
      if (inputRefs.current && inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    }
  }, [position, hasAtLeastOneFilledInputCell]);

  const shouldInputBeDisabled = (index: number, originalIndex: number) => {
    if (originalIndex < 0 || originalIndex >= originalInputDataFields.length) {
      return true;
    }

    if (originalInputDataFields[originalIndex]?.value) {
      return false;
    }

    const nextEmptyOriginalIndex = originalInputDataFields.findIndex((field) => !field.value);

    return nextEmptyOriginalIndex !== originalIndex;
  };

  // Обработчик изменения значения в инпутах
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    let { value } = e.target;

    // Ограничение ввода только одной цифры
    value = value.slice(0, 1);

    const originalIndex = index - position;

    if (originalIndex < 0 || originalIndex >= originalInputDataFields?.length) {
      return;
    }

    if (value.length === 1 && originalInputDataFields[originalIndex]?.value !== value) {
      setOriginalInputDataFields((prevFields: InputFieldsType) => {
        const updatedFields = prevFields.map((field: IInputField, idx: number) =>
          idx === originalIndex ? { ...field, value } : field,
        );

        // Обновляем запрос в Redux
        const query = updatedFields.reduce((acc, inputField) => {
          if (inputField.value) {
            acc += inputField.value;
          }
          return acc;
        }, '');

        onSetFilterQuery(query);

        return updatedFields;
      });

      // Переход к следующему инпуту
      if (inputRefs?.current?.length && index < inputRefs.current.length - 1) {
        setTimeout(() => {
          inputRefs.current[index + 1].focus();
        }, 0);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const originalIndex = index - position;

    if (
      e.key === 'Backspace' &&
      originalIndex >= 0 &&
      !originalInputDataFields[originalIndex]?.value &&
      index > 0
    ) {
      e.preventDefault();

      const prevOriginalIndex = index - 1 - position;
      if (prevOriginalIndex >= 0) {
        setOriginalInputDataFields((prevFields: InputFieldsType) => {
          const updatedFields = prevFields.map((field: IInputField, idx: number) =>
            idx === prevOriginalIndex ? { ...field, value: '' } : field,
          );

          const query = updatedFields.reduce((acc, inputField) => {
            if (inputField.value) {
              acc += inputField.value;
            }
            return acc;
          }, '');

          onSetFilterQuery?.(query);

          return updatedFields;
        });

        setTimeout(() => {
          inputRefs.current[index - 1]?.focus();
        }, 0);
      }
    }
  };

  const handleOnFocusInput = (index: number) => {
    const originalIndex = index - position;

    // Redirect focus to the next input with a value if current input is disabled
    if (shouldInputBeDisabled(index, originalIndex)) {
      const nextIndexWithValue = inputDataFields.findIndex(
        (field, idx) => field.value && idx >= index,
      );
      if (nextIndexWithValue >= 0 && inputRefs.current[nextIndexWithValue]) {
        setTimeout(() => {
          inputRefs.current[nextIndexWithValue].focus();
        }, 0);
      }
    }
  };

  // Обработчик изменения значения в центрированном инпуте (при lock)
  const handleChangeCenteredInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    /* Убираем из значения value все символы, кроме цифр от 0 до 9 включительно */
    const preparedValue = value.replace(/[^0-9]/g, '');

    if (preparedValue.length > 7) return;

    onSetFilterQuery(preparedValue);
    setSecondInputValue(preparedValue);
  };

  return {
    handleChangeCenteredInput,
    handleOnFocusInput,
    handleKeyDown,
    handleOnChange,
    shouldInputBeDisabled,
  };
};
