import axios from 'axios';

import { BY_EXACT_MASK, ENDPOINT, MAX_PHONE_NUMBER_LENGTH } from '../../constants';
import { mapChangeNumbersResponse, mapNumbersResponse } from '../mappers/numbers';
import { getFancyNumberPattern } from '../mappers/filters';

export const fetchSettings = () =>
  axios(ENDPOINT.settings).then(({ data: { defCodes } }) => defCodes);

export const fetchAllNumbers = async () => {
  return axios({
    url: ENDPOINT.all,
    method: 'GET',
  }).then(({ data }) => mapNumbersResponse(data, true));
};

export const fetchFavoriteNumbers = async (number, options = {}) => {
  return axios({
    url: ENDPOINT.favorite,
    method: options?.method ?? 'POST',
    params: { number },
    ...options,
  }).then(({ data }) => mapNumbersResponse(data));
};

const mapFilterToRule = {
  'krasiviy-hvost': 2,
  'pohozhiy-nomer': 6,
};

// redesign2024
const mapFilterToRuleR24 = {
  'lyubimoe-chislo': 2,
  'pohozhiy-nomer': 2,
};

/** Функция запроса доступных номеров для замены текущего номера (/changenumber/availablenumbers/) */
export const fetchChangeNumbers = async ({ query, filter, defCode, isRedesign2024Enabled }) => {
  /** Переменная определяет вариант объекта с кодами для ruleId, как chooseMapFilterRule */
  const chooseMapFilterRule = isRedesign2024Enabled ? mapFilterToRuleR24 : mapFilterToRule;

  /** Переменная определяет точное значение rule для параметров запроса (/changenumber/availablenumbers/).
   * Если длина введенного номера равна MAX_PHONE_NUMBER_LENGTH, то в качестве rule добавляем BY_EXACT_MASK (12) */
  const ruleId =
    isRedesign2024Enabled && query?.length === MAX_PHONE_NUMBER_LENGTH ?
      BY_EXACT_MASK
    : chooseMapFilterRule[filter];

  return axios(ENDPOINT.change, {
    params: {
      pattern: query,
      rule: ruleId || 9,
      defcode: defCode === 'XXX' || defCode === '•••' ? undefined : defCode,
    },
  }).then(({ data }) => mapChangeNumbersResponse(data));
};

export const fetchSimilarNumbers = async (
  { filter, number, defCode, isRedesign2024Enabled },
  options = {},
) => {
  /** Значение в поле type (в редизайне не ипользуется) */
  const filterValue = isRedesign2024Enabled ? '' : filter;

  /** Параметры запроса метода "similar" */
  const params = {
    pattern: getFancyNumberPattern(filter, number),
    defnumber: defCode,
  };

  /** Если формируется filterValue, добавляем его в type (актуально для старых "Красивых номеров") */
  if (filterValue) {
    params.type = filterValue;
  }

  return axios({
    url: ENDPOINT.similar,
    method: options?.method ?? 'POST',
    params,
    ...options,
  }).then(({ data }) => mapNumbersResponse(data));
};
