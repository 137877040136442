import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Text } from '@carnica/ui-kit';

import { enhancedConnect } from 'utils/lowCodeHelpers';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const SEPARATOR = '×';

const _CombinedLogo = ({ children }: { children: React.ReactNode }) => (
  <div className={cn('wrapper')}>
    {React.Children.map(children, (child, index) => (
      <React.Fragment key={index}>
        {child}
        {React.Children.count(children) - 1 !== index && <Text size={40}>{SEPARATOR}</Text>}
      </React.Fragment>
    ))}
  </div>
);

export const CombinedLogo = enhancedConnect({
  children: PropTypes.node,
})(_CombinedLogo);
