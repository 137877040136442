import React, { useEffect } from 'react';
import { Button } from '@carnica/ui-kit';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { selectVolunteersFormContent } from 'pages/LizaAlertLandingPage/store/selectors';
import {
  refreshSmartCaptcha,
  setIsVisibleSmartCaptcha,
} from 'pages/LizaAlertLandingPage/store/volunteerFormSlice';
import { BASE_COUNTDOWN_TIME } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/ConfirmationModal/components/CountdownButton/hooks/useCountdown';
import { RootState } from 'pages/LizaAlertLandingPage/store';
import { TCountdownButton } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/ConfirmationModal/components/CountdownButton/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);
const _CountdownButton = ({
  buttonText,
  countdownButtonText,
  onMount,
  onButtonClick,
  countdown,
  startCountdown,
}: TCountdownButton) => {
  useEffect(() => {
    if (onMount && !countdown) {
      onMount();
      startCountdown(BASE_COUNTDOWN_TIME);
    }
  }, []);

  const handleButtonClick = () => {
    onButtonClick?.();
    startCountdown(BASE_COUNTDOWN_TIME);
  };

  return (
    <Button
      className={cn('countdown-button')}
      disabled={!!countdown}
      mode="inline"
      onClick={handleButtonClick}
      view={countdown ? 'secondary' : 'primary'}
    >
      {countdown ? `${countdownButtonText} ${countdown}` : buttonText}
    </Button>
  );
};

export const CountdownButton = connect(
  (state: RootState) => ({
    buttonText: selectVolunteersFormContent(state).confirmationModal.code.resendButtonText,
    countdownButtonText:
      selectVolunteersFormContent(state).confirmationModal.code.countdownResendButtonText,
  }),
  (dispatch) => ({
    onMount: () => dispatch(setIsVisibleSmartCaptcha(true)),
    onButtonClick: () => {
      dispatch(refreshSmartCaptcha());
      dispatch(setIsVisibleSmartCaptcha(true));
    },
  }),
)(_CountdownButton);
