import { useMemo } from 'react';

import { useAppSelector } from 'pages/MNPLanding/store';
import { selectErrorWidgetType } from 'pages/MNPLanding/store/slices/mnpFormSlice/selectors';

import { useMnpFormSubmit } from '../../InputBlock/components/InputForm/hooks/useMnpFormSubmit';
import { ErrorWidgetButtonProps } from '../types';

/** Хук для определения свойств кнопок на виджете с ошибками */
export const useErrorWidgetButtonProps = () => {
  const errorType = useAppSelector(selectErrorWidgetType);
  const { isFormSubmitting } = useMnpFormSubmit();

  /** Определяем свойства основной кнопки */
  const primaryButtonProps: ErrorWidgetButtonProps = useMemo(
    () => ({
      standardError: {
        loading: isFormSubmitting,
      },
    }),
    [isFormSubmitting],
  );

  return { primaryButtonProps: primaryButtonProps[errorType] };
};
