import React, { FC } from 'react';
import classnames from 'classnames/bind';

import { Picture } from './components/Picture';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

/** Компонент-обертка над Picture */
export const NotAuthBlock: FC = () => (
  <section className={cx('wrapper')}>
    <Picture />
  </section>
);
