import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { ListBlock } from '../ListBlock';
import { QRBlock } from '../QRBlock';

const cx = classNames.bind(styles);

/**
 * Краткая информация о переносе номера
 */
export const BriefInfo: FC = () => (
  <div className={cx('wrapper')}>
    {/* Блок с переходом в мобильное приложение */}
    <QRBlock />
    {/* Список условий */}
    <ListBlock />
  </div>
);
