import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@carnica/ui-kit';

import { Card } from 'pages/MNPLanding/components/Card';
import { Metric } from 'pages/MNPLanding/analytics';

import {
  useErrorWidgetButtonProps,
  useErrorWidgetContent,
  useErrorWidgetHandlers,
  useErrorWidgetMetrics,
  useErrorWidgetScroll,
} from './hooks';
import styles from './styles.pcss';
import { FadeAnimation } from '../FadeAnimation';

const cx = classNames.bind(styles);

/**
 * Виджет с информацией об ошибке, полученной после отправки формы переноса номера
 */
export const ErrorWidget = memo(() => {
  const { imageUrl, description, buttons, title } = useErrorWidgetContent();
  const { onClickPrimaryButton, onClickSecondaryButton } = useErrorWidgetHandlers();
  const { primaryButtonProps } = useErrorWidgetButtonProps();
  const { metricEvents } = useErrorWidgetMetrics();

  useErrorWidgetScroll();

  return (
    <FadeAnimation className={cx('wrapper')}>
      <Metric events={{ yaMNPMetric: metricEvents.viewWidget ?? [] }} mode="viewport">
        <Card
          border
          className={cx('card')}
          id="error-widget"
          style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}}
        >
          <Text variant="headline-small">{title}</Text>
          <Text className={cx('description')} color="secondary" variant="body-medium">
            {description}
          </Text>
          {onClickPrimaryButton && buttons?.primary !== undefined && (
            <Metric
              asChild
              data={buttons.primary}
              events={{ yaMNPMetric: metricEvents.clickPrimaryButton ?? [] }}
              mode="click"
            >
              <Button
                className={cx('button')}
                onClick={onClickPrimaryButton}
                size="l"
                {...primaryButtonProps}
              >
                {buttons.primary}
              </Button>
            </Metric>
          )}
          {onClickSecondaryButton && buttons?.secondary !== undefined && (
            <Metric
              asChild
              data={buttons.secondary}
              events={{ yaMNPMetric: metricEvents.clickSecondaryButton ?? [] }}
              mode="click"
            >
              <Button
                className={cx('button')}
                onClick={onClickSecondaryButton}
                size="l"
                view="secondary-invert"
              >
                {buttons.secondary}
              </Button>
            </Metric>
          )}
        </Card>
      </Metric>
    </FadeAnimation>
  );
});
