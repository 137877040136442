import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import axios from 'axios';
import { Button, Preloader, Text } from '@beef/ui-kit';
import { PageContainer, PageFixer, PageRow } from '@beef/smart-kit';

import { useWrongRegionPopup } from 'pages/TariffsCatalog2021/hooks/useWrongRegionPopup';
import { ResultPopupContent } from 'pages-components/ResultPopup/ResultPopup';
import PopupWithBack from 'pages-components/PopupWithBack/PopupWithBack';
import { FAQContainer } from 'pages/TariffsCatalog2021/components/FAQContainer';
import { useDetectIncognitoMode } from 'utils/hooks/useDetectIncognitoMode';

import { Error } from './components/Error';
import { BLOCKS_COMPONENT } from './constants';
import styles from './styles.module.pcss';

const cx = classNames.bind(styles);

const TariffsCatalog2021 = ({ data }) => {
  const [blocks, setBlocks] = useState(data?.list);
  const authStatus = data.isAuthenticated ? 'az' : 'nz';
  const [status, setStatus] = useState('initial');
  const [showAllTariffs, setShowAllTariffs] = useState(false);
  const { isWrongRegion, wrongRegionPopupData, closeWrongRegionPopup } = useWrongRegionPopup();

  useDetectIncognitoMode();

  const blocksProps = useMemo(() => {
    if (blocks?.length) {
      return blocks.reduce((acc, block, idx) => {
        acc[idx] = {};
        if (block.type === 'group') {
          acc[idx].showAllTariffs = showAllTariffs;

          if (idx === blocks.length - 1) {
            acc[idx].isLastBlock = true;
            acc[idx].archiveLink = data.archiveLink;
          }
        }

        return acc;
      }, {});
    }

    return null;
  }, [blocks, showAllTariffs, data]);

  useEffect(() => {
    if (data && data.xhrNeeded) {
      setStatus('fetching');
      axios
        .get(`${data.tariffApiUrl}ajax2021/`)
        .then((resp) => {
          setBlocks(resp.data.list);
          setStatus('success');
        })
        .catch(() => setStatus('error'));
    }
  }, [data]);

  if (status === 'fetching') {
    return (
      <div className={cx('preloader')}>
        <Preloader />
      </div>
    );
  }

  if (status === 'error') {
    return <Error />;
  }

  if (blocks?.length > 0) {
    return (
      <div className={cx('tariffs-catalog')}>
        <PageContainer size="s">
          <PageFixer backgroundColor="main" size="m">
            <PageRow>
              <div className={cx('tariffs-catalog__title')}>
                <Text color="grey95" size="size0-b" tagType="h1">
                  {data.pageTitle}
                </Text>
                {data?.subTitle?.discount && (
                  <div className={cx('discount')}>
                    <Text color="white" size="size4-m" tagType="span">
                      {data.subTitle.discount}
                    </Text>
                  </div>
                )}
                {data?.subTitle?.text && (
                  <div className={cx('sub-title-text')}>
                    <Text size="size4-r" tagType="span">
                      {data.subTitle.text}
                    </Text>
                  </div>
                )}
              </div>
            </PageRow>
          </PageFixer>
          {blocks.map((block, idx) => {
            const Component = BLOCKS_COMPONENT[block.type];
            return (
              <PageFixer backgroundColor="main" key={idx} size="m">
                <PageRow space={48}>
                  <Component {...blocksProps[idx]} authStatus={authStatus} data={block} />
                </PageRow>
              </PageFixer>
            );
          })}
          <PageFixer backgroundColor="main" size="m">
            <PageRow>
              {!showAllTariffs && (
                <div className={cx('show-tariffs-button-wrapper')}>
                  <Button
                    fullWidth
                    onClick={() => setShowAllTariffs(true)}
                    size="l"
                    type="button"
                    variant="tertiary"
                  >
                    Показать все тарифы
                  </Button>
                </div>
              )}
            </PageRow>
            {data?.faq && <FAQContainer authStatus={authStatus} faq={data?.faq} />}
          </PageFixer>
        </PageContainer>
        {isWrongRegion && (
          <PopupWithBack onClose={closeWrongRegionPopup} opened={isWrongRegion}>
            <ResultPopupContent data={wrongRegionPopupData} />
          </PopupWithBack>
        )}
      </div>
    );
  }

  return null;
};

TariffsCatalog2021.propTypes = {
  data: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    archiveLink: PropTypes.string,
    tariffApiUrl: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    pageTitle: PropTypes.string,
    subTitle: PropTypes.shape({}),
    xhrNeeded: PropTypes.bool,
  }),
};

export { TariffsCatalog2021 };
