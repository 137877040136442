import React from 'react';

export const componentOnly = (fn: (child: JSX.Element) => any) => (child: JSX.Element) => {
  if (!child || typeof child !== 'object') return child;
  return fn(child);
};

/** only works with 'children' field */
export const cloneNthChildren = (
  children: any,
  depth: number,
  fn: (elements: any) => any,
): React.ReactNode => {
  if (depth === 0) return fn(children);
  return (
    children &&
    React.Children.map(
      children,
      componentOnly((child) =>
        React.cloneElement(child, undefined, cloneNthChildren(child.props.children, depth - 1, fn)),
      ),
    )
  );
};
