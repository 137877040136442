import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { reduce } from '@beef/utils';
import PropTypes from 'prop-types';

import {
  CrudeMappingModule,
  selectCrudeMappingContext,
  selectCrudeMappingState,
} from '../../store';
import { enhancedConnect } from '../../../enhancedConnect';

interface IProps {
  alias: string;
  children: React.ReactNode;
  extra?: Record<string, any>;
  value: any;
}

const mapKeys = <K extends string | symbol, V, NK extends string | symbol>(
  object: Record<K, V>,
  fn: (key: K, value: V) => NK,
): Record<NK, V> =>
  reduce(
    object,
    (accum, value, key) => ({
      ...accum,
      [fn(key as K, value)]: value,
    }),
    {} as Record<NK, V>,
  );

export const CrudeProvider: React.FC<IProps> = ({ children, alias, value, extra = {} }) => {
  const prevContext = useSelector<unknown, Record<string, any>>(selectCrudeMappingContext);
  const prevState = useSelector<unknown, Record<string, any>>(selectCrudeMappingState);
  const getPrevState = useMemo(() => () => prevState, [prevState]);
  const nextContext = useMemo(
    () => () => ({
      ...prevContext,
      [alias]: value,
      ...mapKeys(extra, (key) => `${alias}__${key}`),
    }),
    [prevContext, alias, value, extra],
  );
  return (
    <CrudeMappingModule context={nextContext} state={getPrevState}>
      {children}
    </CrudeMappingModule>
  );
};

export const CrudeContext = enhancedConnect({
  children: PropTypes.node,
  alias: PropTypes.string,
  value: PropTypes.any,
  extra: PropTypes.any,
})(CrudeProvider);
