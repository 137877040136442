import { createTimerNumbers } from './createTimerNumbers';

/** Функция формирует описание таймера (caption) для компонента CodeInput */
export const createCaptionText = (
  time: number,
  isRunning: boolean,
  timerSettings: Record<string, string>,
) => {
  /** Доступные минуты и секунды по таймеру */
  const { minutes, seconds } = createTimerNumbers(time);

  /** Контентные данные для таймера */
  const { timerText, timerRestartText } = timerSettings;

  /** Текст с "таймингом" получения/отправки SMS.
   * Если таймер запущен (isRunning), показываем текст с обратным отсчетом.
   * В противном случае, текст повторной отправки кода (timerRestartText) */
  return isRunning ?
      `${timerText} ${minutes}:${seconds.toString().padStart(2, '0')}`
    : timerRestartText;
};
