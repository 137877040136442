import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { Accordion as DSAccordion, Text } from '@carnica/ui-kit';
import { IconChevronDown, IconChevronUp } from '@carnica/graphics';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const OpenIcon = (params: any) => <IconChevronDown {...params} />;
const CloseIcon = (params: any) => <IconChevronUp {...params} />;

export const Accordion: React.FC<{
  children: ReactNode;
  icon?: ReactNode;
  isOpen?: boolean;
  title: string;
  withDivider?: boolean;
}> = ({ title, icon, withDivider, isOpen, children, ...otherProps }) => {
  return (
    <DSAccordion
      className={cn('accordion', { 'accordion--with-divider': withDivider })}
      closeIcon={CloseIcon}
      isOpen={isOpen}
      label={
        <div className={cn('label')}>
          {icon}
          <Text className={cn('title', { 'title--with-icon': !!icon })} variant="body-small">
            {title}
          </Text>
        </div>
      }
      openIcon={OpenIcon}
      {...otherProps}
    >
      {children}
    </DSAccordion>
  );
};
