import { axiosBeelineRequest } from '@beef/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ServerPhoneValidationErrorTypes } from 'pages/MNPLanding/store/slices/mnpFormSlice/types';

import { apiValidateMnpOrderV2 } from './constants';

/**
 * Намеренная опечатка в слове 'Available'.
 * Флаг возвращается из стороннего метода, который пока решили не менять.
 * Может потребовать исправления в случае перехода на новую версию метода.
 */
export type ValidateMNPOrderV2Response = {
  [key in 'isAvaliable' | ServerPhoneValidationErrorTypes]: boolean;
};

/**
 * Валидация номера телефона методом 'v2/validatemnporder'
 */
export const validateMNPOrderV2 = createAsyncThunk<ValidateMNPOrderV2Response, string>(
  'mnpApi/validateMNPOrderV2',
  async (phoneNumber, { rejectWithValue }) => {
    try {
      return await axiosBeelineRequest<ValidateMNPOrderV2Response>({
        method: 'post',
        url: apiValidateMnpOrderV2,
        data: {
          phone: phoneNumber,
        },
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
