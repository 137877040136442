import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { TFaqWrapper } from 'pages/LizaAlertLandingPage/components/FaqWrapper/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const FaqWrapper: React.FC<TFaqWrapper> = ({ children, className }) => {
  return <div className={cn('faq-wrapper', className)}>{children}</div>;
};

FaqWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
