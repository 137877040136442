import React from 'react';
import { withRenderFlag } from '@beef/react';
import { InfoIcon, Tag, Tooltip, VariantTagType } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const SERVICE_INFO_COLOR_CONFIG: Record<string, string> = {
  yellow: 'gold',
  red: 'warning',
};

const cn = classNames.bind(styles);

type ServiceInfoProps = {
  color: string;
  shouldRender: boolean;
  text: string;
  tooltipText: null | string;
};

export const ServiceInfo: React.FC<ServiceInfoProps> = withRenderFlag(
  ({ color, text, tooltipText }) => {
    return (
      <Tag
        className={cn('tag')}
        rightSideNode={
          tooltipText ?
            <div className={cn('tooltip')}>
              <Tooltip message={tooltipText} position="bottom">
                <InfoIcon fill="black" size={20} />
              </Tooltip>
            </div>
          : undefined
        }
        size="m"
        textColor="black"
        variant={(SERVICE_INFO_COLOR_CONFIG[color] as VariantTagType) ?? 'gold'}
      >
        {text}
      </Tag>
    );
  },
);
