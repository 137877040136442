import React from 'react';

// eslint-disable-next-line import/no-named-as-default
import ServicesExtension from 'pages/ProductCard/components/ServicesExtension/ServicesExtension';
import { ProvidedSharingServiceExtension } from 'pages/ProductCard/components/ServicesExtension/SharingService/SharingService';
import { useDetectIncognitoMode } from 'utils/hooks/useDetectIncognitoMode';

import TariffCard from '../ProductCard/Tariff';

export const CommonTariffCard = (props) => {
  useDetectIncognitoMode();

  return <TariffCard {...props} ServicesExtension={ServicesExtension} />;
};
export const SharingServiceExtension = ProvidedSharingServiceExtension;
