import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ErrorWidgetType, IInitialState } from './types';

const initialState: IInitialState = {
  errorWidgetType: 'standardError',
  isErrorWidgetShown: false,
  lastSubmittedPhoneNumber: '',
  phoneNumber: '',
  isFormSubmitting: false,
  phoneInputError: '',
};

const mnpFormSlice = createSlice({
  name: 'MNP_LANDING/MNP_FORM',
  initialState,
  reducers: {
    showErrorWidget: (state, action: PayloadAction<ErrorWidgetType>) => {
      state.isErrorWidgetShown = true;
      state.isFormSubmitting = false;
      state.lastSubmittedPhoneNumber = state.phoneNumber;
      state.errorWidgetType = action.payload;
    },
    hideErrorWidget: (state) => {
      state.isErrorWidgetShown = false;
      state.phoneNumber = '';
    },
    changePhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    clearPhoneInputError: (state) => {
      state.phoneInputError = '';
    },
    clearPhoneInput: (state) => {
      state.phoneNumber = '';
      state.phoneInputError = '';
    },
    startFormSubmitting: (state) => {
      state.isFormSubmitting = true;
    },
    showPhoneInputError: (state, action: PayloadAction<string>) => {
      state.phoneInputError = action.payload;
      state.isFormSubmitting = false;
    },
  },
});

export const {
  showErrorWidget,
  hideErrorWidget,
  changePhoneNumber,
  clearPhoneInputError,
  clearPhoneInput,
  showPhoneInputError,
  startFormSubmitting,
} = mnpFormSlice.actions;

export const mnpFormReducer = mnpFormSlice.reducer;
