import React, { memo } from 'react';
import { RootStateOrAny, connect } from 'react-redux';
import classNames from 'classnames/bind';
import { Chips } from '@carnica/ui-kit';

import { toggleActiveCategory } from 'pages/FancyNumber2022/store/actions/category';
import { getActiveCategories } from 'pages/FancyNumber2022/store/selectors/category';
import { CATEGORY_ALIAS } from 'pages/FancyNumber2022/constants';

import { Select } from './Select';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type CategoriesSelectLayoutPropsType = {
  activeCategories: Record<string, string>;
  onClick: (val: string) => void;
};

// TODO: переписать компонент при рефакторинге
const CategoriesSelectLayout = memo<CategoriesSelectLayoutPropsType>(
  ({ activeCategories, onClick }) => (
    <Select>
      {({ alias, name }) => {
        const lowerName = `${name[0]?.toLowerCase()}${name.slice(1)}`;

        return (
          <Chips
            className={cx('categoryButton', {
              'categoryButton--active': activeCategories[alias],
            })}
            focus={!!activeCategories[alias]}
            key={alias}
            label={lowerName}
            onClick={() => {
              /* Если фильтр "Все" активен (activeCategories.all) и текущий alias = "all", игнорируем клик */
              return activeCategories.all && alias === CATEGORY_ALIAS.all ? null : onClick(alias);
            }}
          />
        );
      }}
    </Select>
  ),
);

const mapStateToProps = (state: RootStateOrAny) => ({
  activeCategories: getActiveCategories(state),
});

const mapDispatchToProps = {
  onClick: toggleActiveCategory,
};

export const CategoriesSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoriesSelectLayout);
