import React from 'react';
import { Avatar } from '@carnica/ui-kit';
import { IconChatMessageFilled } from '@carnica/graphics';
import { withDotSeparatedProps } from '@beef/landing-constructor/library-utils';
import PropTypes from 'prop-types';

import withContent from 'pages/ContactPage/utils/withContent';
import { Metric, contactPageMetric } from 'pages/ContactPage/metric';

import { Cards } from '../Cards';
import { Accordion } from '../Accordion';
import { TMessengersBlock } from './types';

const _Messengers = ({ title, cards }: TMessengersBlock) => {
  return (
    <Metric
      asChild
      data={title}
      events={{ contactPageMetric: ['handleMessengersSpoilerClick'] }}
      mode="click"
    >
      <Accordion
        icon={<Avatar bgColor="invert" icon={IconChatMessageFilled} size="m" />}
        title={title}
      >
        {cards && (
          <Cards
            cards={cards}
            onClick={(cardTitle) => contactPageMetric.handleCardClick(cardTitle, title)}
          />
        )}
      </Accordion>
    </Metric>
  );
};

export const Messengers = withDotSeparatedProps(withContent(_Messengers), {
  title: PropTypes.string,
  cards: PropTypes.string,
});
