import React, { FC } from 'react';
import { Tag, Text } from '@carnica/ui-kit';
import classnames from 'classnames/bind';

import { ConditionsStars } from '../ConditionsStars';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export type TConditionsNumber = {
  bgColor?: string;
  tagText?: string;
  title?: string;
};

/** Компонент с выбранным номером и его типом */
export const ConditionsNumber: FC<TConditionsNumber> = ({ title, bgColor = '#fff', tagText }) => (
  <div className={cx('wrapper')} style={{ color: bgColor }}>
    {title && (
      <div className={cx('wrapper__text')}>
        <Text align="left" color="primary" lineHeight={24} size="m">
          {title}
        </Text>
      </div>
    )}
    {tagText && (
      <Tag className={cx('wrapper__tag')} size="m" view="dark">
        {tagText}
      </Tag>
    )}
    <ConditionsStars />
  </div>
);
