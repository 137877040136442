// TODO: Разнести на отдельные файлы и добавить TS
export const smoothScrollToTop = ({ timeout = 300, top = 0 }) => {
  return setTimeout(() => {
    window.scrollTo({
      top,
      behavior: 'smooth',
    });
  }, timeout);
};

export const wrapAction = (() => {
  const cache = new WeakMap();

  return (dispatch, action) => {
    if (cache.has(action)) {
      return cache.get(action);
    }

    let wrapped;
    if (typeof action === 'function') {
      wrapped = (...args) => dispatch(action(...args));
    } else {
      wrapped = () => action;
    }

    cache.set(action, wrapped);
    return wrapped;
  };
})();

export const getModifiedCLoudsData = (cloudsData, isMobile) => {
  const modifiedData = JSON.parse(JSON.stringify(cloudsData?.data));

  if (!isMobile) {
    modifiedData.push({ id: modifiedData.length + 1 });
  }

  return modifiedData;
};
