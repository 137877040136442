import React, { FC } from 'react';
import classnames from 'classnames/bind';
// @ts-expect-error ошибка импорта типа IconPropColor
import { IconLoading, IconPropColor } from '@carnica/graphics';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

type TCustomPreloader = {
  color?: IconPropColor;
  size?: number;
};

// TODO: Ждем целевое решение в carnica!
/** Временный кастомный компонент Preloader */
export const CustomPreloader: FC<TCustomPreloader> = ({ size = 24, color }) => (
  <section className={cx('wrapper')}>
    <IconLoading className={cx('wrapper__icon')} {...{ size, color }} />
  </section>
);
