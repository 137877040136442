import React, { FC } from 'react';
import classnames from 'classnames/bind';

import { ConditionsNumber, TConditionsNumber } from '../ConditionsNumber';
import { ConditionsInfo, TConditionsInfo } from '../ConditionsInfo';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export type TConditionsSettings = {
  infoProps: TConditionsInfo;
  numberProps: TConditionsNumber;
};

/** Компонент-обертка с информацией по типу номера и бонусам (чёрный блок) */
export const ConditionsSettings: FC<TConditionsSettings> = ({ numberProps, infoProps }) => (
  <div className={cx('wrapper')}>
    <ConditionsNumber {...numberProps} />
    <ConditionsInfo {...infoProps} />
  </div>
);
