/** Функция осуществляет подмену переменных в тексте, на реальные данные.
 * connectedNumber - текущий номер пользователя при авторизации.
 * newNumber - выбранный пользователем номер для обновления */
export const createAttentionTexts = (
  pageTitle: string,
  pageSubTitle: string,
  connectedNumber: string = '',
  newNumber: string = '',
) => ({
  title: pageTitle?.replace('{{connectedNumber}}', connectedNumber),
  subTitle: pageSubTitle?.replace('{{newNumber}}', newNumber),
});
