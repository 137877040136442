import { compose, withReduxStore } from '@beef/redux';
import { withDataConverter } from '@beef/react';
import { createBeefCoPage, createLibrary, withBeefCoProject } from '@beefco/sdk';
import React from 'react';

import { CrudeMapper, extractBeefCoDSL, withCrudeMappingRoot } from 'utils/lowCodeHelpers';
import { dataConverter } from 'pages/LizaAlertLandingPage/dataConverter';
import { initializeStore } from 'pages/LizaAlertLandingPage/store';

import * as extraComponents from './components';

const library = createLibrary(
  {
    CrudeMapper,
    ...extraComponents,
  },
  module,
);

const _LizaAlertLandingPage = createBeefCoPage({
  library,
  pageSlug: 'LizaAlertLanding',
});

export const LizaAlertLandingPage = compose<React.FC<any>>(
  withBeefCoProject(undefined, extractBeefCoDSL),
  withDataConverter(dataConverter),
  withReduxStore(initializeStore),
  withCrudeMappingRoot({ selectState: (state) => state.blocks }),
)(_LizaAlertLandingPage);
