import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { RootStateOrAny, connect } from 'react-redux';

import { getFaqChangeContent, getFaqContent } from 'pages/FancyNumber2022/store/selectors/content';
import { getIsChangeNumber } from 'pages/FancyNumber2022/store/selectors/change';
import { CustomFaqPropsTyps } from 'pages/FancyNumber2024/components/CustomFaq/types';

import { Faq } from './components/Faq';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

// TODO: переписать компонент при рефакторинге
export const _CustomFaq = memo<CustomFaqPropsTyps>(({ content }) => {
  const { title, data } = content || {};

  if (!(title || data)) {
    return null;
  }

  /** Контейнер для faq с заголовком */
  return (
    <section className={cx('section')}>
      <Text className={cx('title')}>{title}</Text>
      {data && <Faq data={data} />}
    </section>
  );
});

const mapStateToProps = (state: RootStateOrAny) => {
  const isChangeNumber = getIsChangeNumber(state);

  return isChangeNumber ?
      {
        content: getFaqChangeContent(state),
      }
    : {
        content: getFaqContent(state),
      };
};

export const CustomFaq = connect(mapStateToProps)(_CustomFaq);
