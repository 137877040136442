import React, { CSSProperties, PropsWithChildren, memo } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type CardProps = PropsWithChildren<{
  border?: boolean;
  className?: string;
  id?: string;
  style?: CSSProperties;
}>;

/**
 * Компонент-обёртка для отображения карточки
 */
export const Card = memo<CardProps>(({ children, className, style, border, id, ...restProps }) => (
  <div className={cx('card', { border }, className)} id={id} style={style} {...restProps}>
    {children}
  </div>
));
