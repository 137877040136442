import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { enhancedConnect } from 'utils/lowCodeHelpers';
import { TColumnContainer } from 'pages/LizaAlertLandingPage/components/ColumnContainer/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ColumnContainerRaw = ({
  children,
  withRowMobileLayout = true,
  gap,
}: TColumnContainer) => {
  return (
    <div
      className={cx('column', `column--gap-${gap}`, {
        'column--with-row-mobile-layout': withRowMobileLayout,
      })}
    >
      {children}
    </div>
  );
};

export const ColumnContainer = enhancedConnect({
  children: PropTypes.node,
  withRowMobileLayout: PropTypes.bool,
  gap: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '']),
})(ColumnContainerRaw);

ColumnContainer.defaultProps = {
  withRowMobileLayout: true,
};
