import { FormEvent, useCallback } from 'react';
import { getCookie } from '@beef/utils';
import { formatOrderData, handleSuccessRequest } from '@beef/layout-kit/utils';

import { getOnlyDigitsPhone, isFilledPhone } from 'utils/format-string';
import { isNumberBought } from 'utils/cartHelpers';
import { pushEcommerceBuyMnp, pushEcommerceSuccessBuyMnp } from 'utils/analytics/simOrderAnalytics';
import {
  showErrorWidget,
  showPhoneInputError,
  startFormSubmitting,
} from 'pages/MNPLanding/store/slices/mnpFormSlice';
import { useAppDispatch, useAppSelector } from 'pages/MNPLanding/store';
import {
  selectIsFormSubmitting,
  selectPhoneNumber,
} from 'pages/MNPLanding/store/slices/mnpFormSlice/selectors';
import {
  selectInputBlockContent,
  selectIsMnpTariffEnabled,
  selectIsValidateMnpOrderV2,
  selectMainContent,
} from 'pages/MNPLanding/store/slices/commonSlice/selectors';
import {
  selectIsMNPService,
  selectMnpTariff,
  selectTariffId,
} from 'pages/MNPLanding/store/slices/additionalDataSlice/selectors';
import { DEFAULT_CONSTRUCTOR_ID } from 'pages/MNPLanding/constants';
import {
  ErrorWidgetType,
  ServerPhoneValidationErrorTypes,
} from 'pages/MNPLanding/store/slices/mnpFormSlice/types';

import { validateMNPOrderV2 } from '../../../api/validateMNPOrderV2';
import { validateMNPOrder } from '../../../api/validateMNPOrder';
import { createMNPOrder } from '../../../api/createMNPOrder';
import { getRegionId } from '../../../utils/getRegionId';
import { fetchBasket } from '../../../api/fetchBasket';
import { getPurchasedItems } from '../../../utils/getPurchasedItems';
import { CreateBasketRequestData, createBasket } from '../../../api/createBasket';

/**
 * Хук для отправки формы переноса номера
 */
export const useMnpFormSubmit = () => {
  const { errors } = useAppSelector(selectMainContent);
  const initialTariffId = useAppSelector(selectTariffId);
  const isMNPService = useAppSelector(selectIsMNPService);
  const isValidateMnpOrderV2 = useAppSelector(selectIsValidateMnpOrderV2);
  const { placeholder: ourPhoneNumber } = useAppSelector(selectInputBlockContent);
  const isMnpTariffEnabled = useAppSelector(selectIsMnpTariffEnabled);
  const mnpTariff = useAppSelector(selectMnpTariff);

  const isFormSubmitting = useAppSelector(selectIsFormSubmitting);
  const phoneNumber = useAppSelector(selectPhoneNumber);

  const dispatch = useAppDispatch();

  /** Отображение ошибки возникшей в ходе отправки формы */
  const showSubmitError = useCallback(
    (errorWidgetType: ErrorWidgetType) => {
      dispatch(showErrorWidget(errorWidgetType));
    },
    [dispatch],
  );

  /** Валидация номера через метод 'validatemnporder', возвращает флаг isValid */
  const validatePhoneV1 = useCallback(
    async (phone: string): Promise<boolean> => {
      /** Отправляем запрос на валидацию и получаем результат */
      const validationResult = await validateMNPOrder(phone);

      /** Если запрос упал, показываем стандартную ошибку */
      if (typeof validationResult === 'string' || !validationResult.IsSucceeded) {
        showSubmitError('standardError');
        return false;
      }

      /** Если в ответе IsExistingCtn = true, показываем ошибку 'номер уже в билайне' */
      if (validationResult.View.IsExistingCtn) {
        showSubmitError('isOperatorCodeBeeline');
        return false;
      }

      /** Если валидация прошла успешно, возвращаем isValid = true' */
      return true;
    },
    [showSubmitError],
  );

  /** Валидация номера через метод 'v2/validatemnporder', возвращает флаг isValid */
  const validatePhoneV2 = useCallback(
    async (phone: string): Promise<boolean> => {
      try {
        /** Отправляем запрос на валидацию и получаем результат */
        const { isAvaliable: isAvailable, ...validationErrors } = await dispatch(
          validateMNPOrderV2(phone),
        ).unwrap();

        /** Если isAvailable = false, значит в ответе вернулась ошибка */
        if (!isAvailable) {
          /** Ищем первое поле со значением true и получаем тип ошибки */
          const errorType = (
            Object.keys(validationErrors) as Array<ServerPhoneValidationErrorTypes>
          ).find((type) => validationErrors[type]);

          /**
           * Если не нашли тип ошибки, показываем стандартную ошибку,
           * так как при isAvailable = false, ошибка должна быть в ответе
           */
          showSubmitError(errorType ?? 'standardError');
          return false;
        }
      } catch {
        /** Если запрос упал, показываем стандартную ошибку */
        showSubmitError('standardError');
        return false;
      }

      /** Если валидация прошла успешно, возвращаем isValid = true' */
      return true;
    },
    [dispatch, showSubmitError],
  );

  const submitMnpForm = useCallback(
    async (event?: FormEvent<HTMLFormElement>) => {
      try {
        event?.preventDefault();

        if (isFormSubmitting) {
          return;
        }

        dispatch(startFormSubmitting());

        /** Чистим номер от всех пробелов и префикса */
        const clearPhoneNumber: string = getOnlyDigitsPhone(phoneNumber);

        /** Проверяем введенный номер на корректность */
        if (!isFilledPhone(clearPhoneNumber)) {
          /** Показываем ошибку под инпутом для ввода номера */
          dispatch(showPhoneInputError(errors.notFilledCtn));
          return;
        }

        /**
         * Если введенный номер совпадает с номером по умолчанию, показываем ошибку 'номер уже наш'.
         * Номер по умолчанию отображается в качестве примера в инпуте для ввода номера.
         */
        if (clearPhoneNumber === getOnlyDigitsPhone(ourPhoneNumber)) {
          showSubmitError('ourNumber');
          return;
        }

        /**
         * Проводим валидацию номера на сервере.
         * Метод валидации выбираем исходя из значения тоггла.
         */
        const isPhoneValid = await (isValidateMnpOrderV2 ? validatePhoneV2 : validatePhoneV1)(
          clearPhoneNumber,
        );

        /**
         * Если номер невалидный, прерываем отправку формы.
         * Отображение ошибок происходит в методах валидации номера.
         */
        if (!isPhoneValid) {
          return;
        }

        /** Получаем id корзины */
        const basketId = getCookie('basket_id');

        /**
         * Если id есть, то необходимо узнать, нет ли
         * в ней уже введенного пользователем номера.
         *
         * Поскольку в новой шапке нет функционала по работе с корзиной,
         * то есть этих данных нет в глобальном сторе, делаем запрос за корзиной
         */
        if (basketId) {
          const fetchBasketResponse = await fetchBasket(basketId);

          /** Если запрос упал, показываем стандартную ошибку */
          if (typeof fetchBasketResponse === 'string') {
            showSubmitError('standardError');
            return;
          }

          /** Форматируем данные полученные из корзины */
          const basketItems = getPurchasedItems(fetchBasketResponse.items);

          /**
           * Если в корзине уже есть номер,
           * который ввел пользователь,
           * перенаправляем пользователя в корзину
           */
          if (isNumberBought(basketItems.numbers, clearPhoneNumber)) {
            handleSuccessRequest({
              redirectToCart: true,
            });
            return;
          }
        }

        /* Пока решено оставить сбор Ecommerce метрик как есть,
         * может потребовать доработки после уточнения информации */
        /** Перенесено из старого MNP */
        pushEcommerceBuyMnp(initialTariffId);

        /** Данные для создания корзины, будут содержать информацию о тарифе */
        let createBasketRequestData: CreateBasketRequestData;

        /** Если данные о тарифе пришли при загрузке страницы MNP */
        if (isMnpTariffEnabled) {
          /** Формируем данные для корзины */
          createBasketRequestData = formatOrderData({
            constructorTariffData: {
              tariffConstructorId: mnpTariff.presetId,
              optionSocs: mnpTariff.options.join(','),
            },
            mnp: clearPhoneNumber,
            simOrderPlace: 'mnp',
            tariffId: mnpTariff.dpcIdTariff,
            additionalCtnOptions: {},
          });
        } else {
          /** Получаем регион пользователя */
          const regionId = getRegionId();

          /** Если не нашли регион, показываем стандартную ошибку */
          if (!regionId) {
            showSubmitError('standardError');
            return;
          }

          /** Отправляем запрос на создание заказа по переносу номера и получаем ответ */
          const createOrderResponse = await createMNPOrder(clearPhoneNumber, regionId);

          /** Если запрос упал, показываем стандартную ошибку */
          if (typeof createOrderResponse === 'string' || !createOrderResponse.IsSucceeded) {
            showSubmitError('standardError');
            return;
          }

          /** Перенесено из старого МНП */
          // Дефолтный объект с данными для корзины, на случай пустого пропа constructorTariffData
          const defaultConstructorTariffData = {
            // Значение захардкожено, так как соответствует дефолтному тарифу. Согласовано с аналитиком
            tariffConstructorId: DEFAULT_CONSTRUCTOR_ID,
            optionSocs: '',
          };

          // PEB-4891: в ответе createMnpOrder теперь присутствуют соки тарифа (и опций) для корректной работы корзины
          if (Array.isArray(createOrderResponse?.View?.Options)) {
            defaultConstructorTariffData.optionSocs = createOrderResponse?.View?.Options.join(',');
          }

          /** Формируем данные для корзины */
          createBasketRequestData = formatOrderData({
            constructorTariffData: defaultConstructorTariffData,
            mnp: clearPhoneNumber,
            simOrderPlace: 'mnp',
            tariffId: `${
              !initialTariffId || isMNPService ? createOrderResponse.View.TariffId : initialTariffId
            }`,
            additionalCtnOptions: {},
          });
        }

        /** Отправляем запрос на создание корзины и получаем ответ */
        const createBasketResponse = await createBasket(createBasketRequestData);

        /** Если запрос упал, показываем стандартную ошибку */
        if (typeof createBasketResponse === 'string') {
          showSubmitError('standardError');
          return;
        }

        /** Перенесено из старого MNP */
        pushEcommerceSuccessBuyMnp(initialTariffId);

        /** Перенаправляем пользователя на страницу с корзиной */
        handleSuccessRequest({
          cartId: createBasketResponse.id,
          items: createBasketResponse.items,
          redirectToCart: true,
        });
      } catch (unexpectedError) {
        /** В случае непредвиденной ошибки, показываем стандартную ошибку */
        showSubmitError('standardError');
      }
    },
    [
      dispatch,
      errors,
      initialTariffId,
      isMNPService,
      phoneNumber,
      isFormSubmitting,
      isValidateMnpOrderV2,
      showSubmitError,
      validatePhoneV1,
      validatePhoneV2,
      isMnpTariffEnabled,
      ourPhoneNumber,
      mnpTariff,
    ],
  );

  return {
    submitMnpForm,
    isFormSubmitting,
  };
};
