import { createBeefCoPage, createLibrary, withBeefCoProject } from '@beefco/sdk';
import { compose, withReduxStore } from '@beef/redux';
import {
  AppBannerView,
  InfoBannerView,
  LandingRow,
  TeaserBannerView,
} from '@beef/landing-constructor/library';

import { initializeStore } from 'pages/ContactPage/store';
import { extractBeefCoDSL } from 'utils/lowCodeHelpers';
import { withMetricContainer } from 'pages/ContactPage/metric';

import * as components from './components';

const library = createLibrary(
  {
    LandingRow,
    TeaserBannerView,
    InfoBannerView,
    AppBannerView,
    ...components,
  },
  module,
);

const _BeefCoContactPage = createBeefCoPage({
  library,
  pageSlug: 'BeefCoContactPage',
});
// https://beefco-test.apps.yd-kt05.vimpelcom.ru/?url=http://localhost:8080/customers/contact-page/
export const BeefCoContactPage = compose(
  withBeefCoProject(undefined, extractBeefCoDSL),
  withReduxStore(initializeStore),
  withMetricContainer,
)(_BeefCoContactPage);
