import React, { FC, ReactNode } from 'react';
import { Text } from '@beef/ui-kit';
import classnames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

type TCustomTag = {
  children: ReactNode;
};

export const CustomTag: FC<TCustomTag> = ({ children }) => {
  return (
    <Text className={cx('row_tag')} color="green" size="size5-r-s" testName="Row_tag">
      {children}
    </Text>
  );
};
