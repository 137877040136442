import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { RootStateOrAny, connect } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';

import Icon from 'pages-components/Icon';
import { useIsAuthenticated } from 'pages/FancyNumber2022/hooks/useIsAuthenticated';
import { getWidgetNoAuthContent } from 'pages/FancyNumber2022/store/selectors/content';

import { CustomSmartButton } from '../CustomSmartButton';
import { INoAuthContainer } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.2 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.2 },
  },
};

// TODO: переписать компонент при рефакторинге
/** Компонент для НЕ авторизованного юзера, при выборе таба "Сменить номер" */
export const _NoAuthContainer: FC<INoAuthContainer> = ({ content }) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <AnimatePresence>
      <motion.div
        animate="visible"
        className={cx('wrapper')}
        exit="exit"
        initial="hidden"
        layout
        variants={variants}
      >
        <div className={cx('widget')}>
          <Text className={cx('widget__header-title')} color="white" size="size2-m">
            {content?.title}
          </Text>
          <ul className={cx('widget__content-list')}>
            {content?.data?.map((item) => (
              <li className={cx('widget__element')} key={item.id}>
                <div className={cx('widget__elem-icon')}>
                  <Icon className={cx('widget__icon')} lazy src={item?.icon} />
                </div>
                <div className={cx('widget__elem-info')}>
                  <Text
                    className={cx('widget__elem-info-text')}
                    color="white"
                    size="size5-r-s"
                    tagType="span"
                  >
                    {item?.info}
                  </Text>
                  <Text
                    className={cx('widget__elem-info-description')}
                    color="inherit"
                    size="size5-r-s"
                    tagType="span"
                  >
                    {item?.description}
                  </Text>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className={cx('widget__btn-wrapper')}>
          <CustomSmartButton isAuth={isAuthenticated} />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

const mapStateToProps = (state: RootStateOrAny) => ({
  content: getWidgetNoAuthContent(state),
});

export const NoAuthContainer = connect(mapStateToProps)(_NoAuthContainer);
