import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { AppStoresProps } from '../../types';
import { StoreLink } from '../StoreLink';
import { QRCode } from '../QRCode';

const cx = classNames.bind(styles);

export const StoresBlock: React.FC<AppStoresProps> = ({
  qrLabel,
  qrImageUrl,
  links,
  className,
}) => {
  const { appStore, huaweiStore, ruStore, googleStore } = links;

  return (
    <div className={cx('store-wrapper', className)}>
      <QRCode qrImageUrl={qrImageUrl} qrLabel={qrLabel} />
      <div className={cx('store-links')}>
        <StoreLink href={appStore.href} imageUrl={appStore.imageUrl} label="Apple Store" />
        <StoreLink href={googleStore.href} imageUrl={googleStore.imageUrl} label="Google Play" />
        <StoreLink href={huaweiStore.href} imageUrl={huaweiStore.imageUrl} label="Huawei Store" />
        <StoreLink href={ruStore.href} imageUrl={ruStore.imageUrl} label="RuStore" />
      </div>
    </div>
  );
};
