import { useSelector } from 'react-redux';

import { fancyNumberPages } from 'pages/FancyNumber2024/router/pathes';
import { prepareUrl } from 'pages/FancyNumber2024/router/utils/prepareUrl';
import { selectBaseUrl } from 'pages/FancyNumber2022/store/selectors/content';

/* Хук для получения ссылки на зарегистрированные страницы.
 * Обязательно используем его для навигации из-за особенностей SSR */
export const useFancyNumberLink = (page: keyof typeof fancyNumberPages) => {
  /** Базовый URL страницы FancyNumber2024, "обработанный" утилитой getEnvBaseUrl */
  const baseUrl = prepareUrl(useSelector(selectBaseUrl));

  if (page === 'main') {
    return baseUrl;
  }
  return [baseUrl || '/', fancyNumberPages[page]].join('');
};
