import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Text } from '@carnica/ui-kit';

import { enhancedConnect } from 'utils/lowCodeHelpers';
import { THeading } from 'pages/LizaAlertLandingPage/components/Heading/types';
import { ButtonArrow } from 'pages/MainPage/ui';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const HeadingRaw = ({
  title,
  description,
  size,
  extraLink,
  withPadding,
  withBlockPadding,
  indent,
}: THeading) => (
  <div
    className={cn('heading', `heading--indent-${indent}`, {
      'heading--with-padding': withPadding,
      'heading--with-block-padding': withBlockPadding,
      'heading--with-extra-link': !!extraLink,
    })}
  >
    <Text className={cn('title', `title--${size}`)} variant={size}>
      {title}
    </Text>
    {description && (
      <Text className={cn('description')} color="secondary" variant="body-small">
        {description}
      </Text>
    )}
    {extraLink && (
      <ButtonArrow
        arrowFill="primary"
        arrowPosition="top-right"
        href={extraLink.href}
        isBlankUrlTarget={extraLink.isBlankUrlTarget}
      >
        <Text color="secondary" variant="body-small">
          {extraLink.label}
        </Text>
      </ButtonArrow>
    )}
  </div>
);

export const Heading = enhancedConnect({
  title: PropTypes.string,
  extraLink: PropTypes.string,
  withPadding: PropTypes.bool,
  withBlockPadding: PropTypes.bool,
  size: PropTypes.string,
  indent: PropTypes.oneOf(['xxs', 'xs', 's', 'm', '']),
})(HeadingRaw);

Heading.defaultProps = {
  indent: 'm',
};
