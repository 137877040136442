import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Preloader } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const LoadingWrap = memo(({ isLoading, className, minHeight, preloadLayout, children }) => (
  <div className={cx('wrap', { 'wrap--loading': isLoading }, className)} style={{ minHeight }}>
    <div className={cx('wrap_content')}>{children}</div>
    {isLoading &&
      (preloadLayout ?? (
        <div className={cx('wrap_spinner')}>
          <Preloader size="m" />
        </div>
      ))}
  </div>
));

LoadingWrap.propTypes = {
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  minHeight: PropTypes.string,
  preloadLayout: PropTypes.node,
  children: PropTypes.node,
};
