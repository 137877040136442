import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import { CustomTag } from 'pages/FancyNumber2024/components/Tag';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

// TODO: переписать компонент при рефакторинге
export const Row = ({ isBought, isInCart, onClick, numberNode, priceNode, tagText }) => {
  const isAvailable = !isBought && !isInCart;
  return (
    <button
      className={cx('row', {
        'row--with-tag': !!tagText,
        'row--not-available': !isAvailable,
      })}
      onClick={isAvailable ? onClick : undefined}
      type="button"
    >
      <span>{numberNode}</span>
      {isAvailable && priceNode}
      {isAvailable && tagText && <CustomTag>{tagText}</CustomTag>}
      {/* {isInCart && <InCartMessage />} */}
      {/* {isBought && <BoughtMessage />} */}
    </button>
  );
};

Row.propTypes = {
  isBought: PropTypes.bool,
  isInCart: PropTypes.bool,
  onClick: PropTypes.func,
  numberNode: PropTypes.node,
  priceNode: PropTypes.node,
  // tagNode: PropTypes.node,
};
