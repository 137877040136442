import React, { ComponentType, useCallback } from 'react';

import { CrudeMappingModule } from '../store';

export const withCrudeMappingRoot =
  ({ selectState }: { selectState: (state: any) => any }) =>
  <T extends Record<string, any>>(WrappedComponent: ComponentType<T>) => {
    return (props: T) => {
      const selectEmptyObject = useCallback(() => ({}), []);
      return (
        <CrudeMappingModule context={selectEmptyObject} state={selectState}>
          <WrappedComponent {...props} />
        </CrudeMappingModule>
      );
    };
  };
