/** Дефолтное значение возможной длины кода проверки из SMS */
export const DEFAULT_CODE_LENGTH = 4;

/** Дефолтное значение финиша таймера обратного отсчета */
export const DEFAULT_END_TIMER = 0;

/** Дефолтное значение старта таймера обратного отсчета */
export const DEFAULT_START_TIMER = 59000;

/** Дефолтное значение интервала таймера обратного отсчета */
export const DEFAULT_INTERVAL_TIMER = 1000;

/** Возможные ошибки в методе запроса/отправки SMS для FancyNumber2024  */
export enum SMS_REQUEST_ERRORS {
  /** Общая ошибка */
  generalFault = 'generalFault',
  /** Ошибка баланса (недостаточно средств) */
  balanceNotEnough = 'balanceNotEnough',
  /** Ошибка смены номера */
  changedRecently = 'changedRecently',
  /** Ошибка валидации SMS-кода */
  incorrectSmsConfirmationCode = 'incorrectSmsConfirmationCode',
  /** Ошибка срока активации абонента */
  ageRestrict = 'ageRestrict',
}

/**  URL метода запроса/отправки SMS для FancyNumber2024.
 * После реализации собственного store вынести в папку api */
export const CHANGE_NUMBER_URL = '/changenumber/change/';

/** Значение time для setInterval компонента CloudsCarousel */
export const CLOUDS_CAROUSEL_TIMER = 2500;
