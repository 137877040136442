import React, { FC } from 'react';

import { Status } from 'pages/FancyNumber2024/components/Status';

import { useAttentionContent } from './hooks/useAttentionContent';

/** Компонент-обертка над страницей с предупреждением/результатом обновления номера */
export const AttentionContainer: FC = () => {
  /* Готовим данные для компонента Status */
  const statusContent = useAttentionContent();

  return <Status {...statusContent} />;
};
