import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { composeStoreInitializer } from '@beef/redux';

import { volunteersForm } from 'pages/LizaAlertLandingPage/store/volunteerFormSlice';

const rootReducer = combineReducers({
  blocks: (state = {}) => state,
  form: (state = {}) => state,
  volunteersForm,
});

export const initializeStore = composeStoreInitializer(
  rootReducer,
) as unknown as typeof configureStore<typeof rootReducer>;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ReturnType<typeof initializeStore>['dispatch'];
