import React from 'react';
import { Modal } from '@carnica/ui-kit';
import { connect } from 'react-redux';

import { selectVolunteersConfirmationModal } from 'pages/LizaAlertLandingPage/store/selectors';
import { closeConfirmationModal } from 'pages/LizaAlertLandingPage/store/volunteerFormSlice';
import { CodeValidation } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/ConfirmationModal/components/CodeValidation';
import { Status } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/ConfirmationModal/components/Status';
import { TConfirmationModal } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/ConfirmationModal/types';
import { useCountdown } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/ConfirmationModal/components/CountdownButton/hooks/useCountdown';

const _ConfirmationModal = ({ isOpen, onClose }: TConfirmationModal) => {
  /** Иницилизация счетчика обратного отсчета вне модалки, чтобы избежать его сброса */
  const { countdown, startCountdown } = useCountdown();

  return (
    <Modal
      closeOptions={{ button: true, overlay: true }}
      isOpen={isOpen}
      onClose={onClose}
      size="s"
    >
      <CodeValidation countdown={countdown} startCountdown={startCountdown} />
      <Status />
    </Modal>
  );
};

export const ConfirmationModal = connect(
  (state) => ({
    isOpen: selectVolunteersConfirmationModal(state).isOpen,
  }),
  (dispatch) => ({
    onClose: () => dispatch(closeConfirmationModal()),
  }),
)(_ConfirmationModal);
