import React from 'react';
import { ECaptchaKeys } from '@beef/captcha';

import { BlockWrapperRaw as BlockWrapper } from 'pages/LizaAlertLandingPage/components/BlockWrapper';
import { ColumnContainerRaw as ColumnContainer } from 'pages/LizaAlertLandingPage/components/ColumnContainer';
import { RowContainerRaw as RowContainer } from 'pages/LizaAlertLandingPage/components/RowContainer';
import {
  AddLocationButton,
  ConsentCheckbox,
  ConsentText,
  DistrictCheckbox,
  DistrictList,
  HelpTypeCheckbox,
  HelpTypesHeading,
  LastNameInput,
  NameInput,
  PhoneNumberInput,
  RegionDistrictCheckbox,
  RegionGroupList,
  SubmitButton,
  VolunteersFinalForm,
  VolunteersFormHeading,
  YandexCaptcha,
  YourContactsHeading,
} from 'pages/LizaAlertLandingPage/components/VolunteersForm/connected';
import { HelpTypesList } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/HelpTypesList';
import { LocationSelectMapper } from 'pages/LizaAlertLandingPage/store/selectors';
import { RegionGroup } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/RegionGroup';
import { ConfirmationModal } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/ConfirmationModal';
import { LocationSelect } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/LocationSelect';

export const VolunteersFormMobile = () => (
  <>
    <VolunteersFinalForm>
      <RowContainer gap="xxs" indent="s">
        <VolunteersFormHeading indent="xxs" withBlockPadding />
        <BlockWrapper withPadding>
          <HelpTypesHeading />
          <RowContainer>
            <ColumnContainer>
              <HelpTypesList>
                <HelpTypeCheckbox />
              </HelpTypesList>
            </ColumnContainer>
          </RowContainer>
        </BlockWrapper>
        <BlockWrapper withPadding>
          <YourContactsHeading />
          <RowContainer gap="l">
            <NameInput />
            <LastNameInput />
            <PhoneNumberInput />
            <LocationSelectMapper>
              <LocationSelect removeButtonView="secondary-invert" />
              <RegionGroupList>
                <RegionGroup>
                  <RegionDistrictCheckbox />
                </RegionGroup>
              </RegionGroupList>
              <DistrictList>
                <DistrictCheckbox />
              </DistrictList>
            </LocationSelectMapper>
            <AddLocationButton view="secondary-invert" />
            <RowContainer gap="m">
              <ConsentCheckbox />
              <ConsentText />
            </RowContainer>
          </RowContainer>
        </BlockWrapper>
        <SubmitButton />
      </RowContainer>
    </VolunteersFinalForm>
    <YandexCaptcha clientKey={ECaptchaKeys.LizaAlert} />
    <ConfirmationModal />
  </>
);
