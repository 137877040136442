import React from 'react';
import classNames from 'classnames/bind';
import { withDotSeparatedProps } from '@beef/landing-constructor/library-utils';
import PropTypes from 'prop-types';

import { Cards } from 'pages/ContactPage/components/Cards';
import { Accordion } from 'pages/ContactPage/components/Accordion';
import withContent from 'pages/ContactPage/utils/withContent';
import { Metric, contactPageMetric } from 'pages/ContactPage/metric';

import styles from './styles.pcss';
import { TPhoneNumbers } from './types';

const cn = classNames.bind(styles);

const _PhoneNumbers = ({ accordionItems }: TPhoneNumbers) => {
  return (
    <div className={cn('wrapper')}>
      {accordionItems?.map(({ title, cards, isOpen }) => (
        <Metric
          asChild
          data={title}
          events={{ contactPageMetric: ['handlePhoneNumbersSpoilerClick'] }}
          key={title}
          mode="click"
        >
          <Accordion isOpen={isOpen} title={title} withDivider>
            <Cards
              cards={cards}
              disabledCursorPointer
              onClick={(cardTitle) => contactPageMetric.handleCardClick(cardTitle, title, true)}
            />
          </Accordion>
        </Metric>
      ))}
    </div>
  );
};

export const PhoneNumbers = withDotSeparatedProps(withContent(_PhoneNumbers), {
  accordionItems: PropTypes.string,
});
