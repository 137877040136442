export const TABS_ALIAS = {
  change: 'change',
  new: 'new',
};

export const BUTTON_STATUS = {
  default: 'default',
  success: 'success',
  loading: 'loading',
  auth: 'auth',
  reset: 'reset',
};

/** Базовый url открытия страницы FancyNumber2024 */
export const FANCY_NUMBER_BASE_URL =
  '/customers/products/mobile/services/details/nomer-na-vybor/krasivie-nomera/';

/** Текст бесплатного оформления обновления номера телефона */
export const FREE_PRICE_TEXT = 'бесплатно';

/** Текст минимальной платы за обновления номера телефона.
 * Константа создана для подстраховки.
 * Сценарий с ее применением НЕВОЗМОЖЕН при КОРРЕКТНОЙ настройки номеров */
export const MIN_PRICE_TEXT = 'заменить номер за 100 ₽';

/** Минимальное значение для отображения RageSlider (Slider) под инпутом ввода желаемых пользователем цифр */
export const MIN_POS_SIZE = 5;
