import { useMemo } from 'react';

import { useAppSelector } from 'pages/MNPLanding/store';
import { selectErrorWidgetType } from 'pages/MNPLanding/store/slices/mnpFormSlice/selectors';

import { ErrorWidgetMetrics } from '../types';

/** Хук для определения метрик на виджете с ошибками */
export const useErrorWidgetMetrics = () => {
  const errorType = useAppSelector(selectErrorWidgetType);

  /** Определяем метрики в зависимости от типа ошибок */
  const metrics: ErrorWidgetMetrics = useMemo(
    () => ({
      standardError: {
        viewWidget: ['handleViewErrorWidgetStandardError'],
        clickPrimaryButton: ['handleClickErrorWidgetStandardErrorButton'],
      },
      isLastChangeDate: {
        viewWidget: ['handleViewErrorWidgetIsLastChangeDate'],
        clickSecondaryButton: ['handleClickErrorWidgetIsLastChangeDateButton'],
      },
      ourNumber: {
        viewWidget: ['handleViewErrorWidgetOurNumber'],
        clickPrimaryButton: ['handleClickErrorWidgetOurNumberButton'],
      },
      isOperatorCodeBeeline: {
        viewWidget: ['handleViewErrorWidgetIsOperatorCodeBeeline'],
        clickPrimaryButton: ['handleClickErrorWidgetIsOperatorCodeBeelineButton'],
        clickSecondaryButton: ['handleClickErrorWidgetIsOperatorCodeBeelineButton'],
      },
      isRequest: {
        viewWidget: ['handleViewErrorWidgetIsRequest'],
        clickPrimaryButton: ['handleClickErrorWidgetIsRequestButton'],
      },
    }),
    [],
  );

  return { metricEvents: metrics[errorType] };
};
