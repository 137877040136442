import { useEffect, useState } from 'react';

import { fetchServicesContextInfo } from '../utils';
import { ServiceContextInfo } from '../types';

export const useFetchServiceContextInfo = (isAuthenticated: boolean) => {
  const [servicesInfoContexts, setServicesInfoContexts] = useState<ServiceContextInfo[]>([]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const bootstrap = async () => {
      const { contexts } = await fetchServicesContextInfo();

      if (contexts === null) {
        return;
      }

      setServicesInfoContexts(contexts);
    };

    bootstrap().catch(() => {
      setServicesInfoContexts([]);
    });
  }, [isAuthenticated]);

  return servicesInfoContexts;
};
