import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { RootStateOrAny, connect } from 'react-redux';
import { cnTheme } from '@carnica/themes';

import { CustomSmartButton } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/CustomSmartButton';
import { getDefCodesAndDefault } from 'pages/FancyNumber2022/store/selectors/numbers';
import {
  clearWidgetFilters,
  setActiveFilter,
  setFilterDefCode,
  setFilterQuery,
  setResetWidgetFilters,
} from 'pages/FancyNumber2022/store/actions/filter';
import { setInputDataFields as setInputDataFieldsAC } from 'pages/FancyNumber2022/store/actions/numbers';
import { getWidgetContent } from 'pages/FancyNumber2022/store/selectors/content';
import { getIsResetWidgetFilters } from 'pages/FancyNumber2022/store/selectors/filter';
import { Header } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/components/Header';
import { CountryCode } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/components/CountryCode';
import { RegionSelector } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/components/RegionSelector';
import { InputFields } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/components/InputFields';
import { Description } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/components/Descriptions';
import { Slider } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/components/Slider';
import { LockButton } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/components/LockButton';
import { IMainContentProps } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/types';
import { useMainContent } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/hook';

import styles from './styles.pcss';

const cx = classNames.bind(styles);
const lTheme = cnTheme('light');

// TODO: переписать компонент при рефакторинге
const _MainContent = memo<IMainContentProps>(
  ({
    onSetActiveFilter,
    defCodes,
    onSetFilterDefCode,
    onSetInputDataFields,
    onResetFilter,
    content,
    isResetWidgetFilters,
    onSetResetWidgetFilters,
  }) => {
    const {
      handleSlideChange,
      handleSliderChange,
      isMobile,
      maxSliderHPos,
      hasAtLeastOneFilledInputCell,
      centeredInputRef,
      inputRefs,
      hasInitialized,
      setHasInitialized,
      setLocked,
      selectedRegion,
      secondInputValue,
      inputDataFields,
      setSelectedRegion,
      setPosition,
      swiperRef,
      setSecondInputValue,
      setOriginalInputDataFields,
      locked,
      position,
      initialInputDataFields,
      originalInputDataFields,
      isShowRangeInput,
    } = useMainContent({
      onResetFilter,
      onSetResetWidgetFilters,
      onSetFilterDefCode,
      defCodes,
      isResetWidgetFilters,
      onSetInputDataFields,
      onSetActiveFilter,
    });

    return (
      <div className={cx('number-widget', lTheme)}>
        {!isMobile && <Header title={content?.title} />}

        <div className={cx('number-widget__body')}>
          <CountryCode />

          <RegionSelector
            defCodes={defCodes}
            hasAtLeastOneFilledInputCell={hasAtLeastOneFilledInputCell}
            hasInitialized={hasInitialized}
            onSlideChange={handleSlideChange}
            selectedRegion={selectedRegion}
            setHasInitialized={setHasInitialized}
            setPosition={setPosition}
            setSelectedRegion={setSelectedRegion}
            swiperRef={swiperRef}
          />

          <div
            className={cx('number-widget__input-wrapper', {
              'number-widget__input-wrapper--unlocked': !locked,
            })}
          >
            <div
              className={cx('number-widget__input-container', {
                'number-widget__input-container--unlocked': !locked,
              })}
            >
              <InputFields
                centeredInputRef={centeredInputRef}
                hasAtLeastOneFilledInputCell={hasAtLeastOneFilledInputCell}
                initialInputDataFields={initialInputDataFields}
                inputDataFields={inputDataFields}
                inputRefs={inputRefs}
                // Пока отключаем вариацию с вводом в любое место, т.к. логика не поддерживается на сторонних сервисах, а инпут работает очень криво!
                locked={false}
                originalInputDataFields={originalInputDataFields}
                position={position}
                secondInputValue={secondInputValue}
                setOriginalInputDataFields={setOriginalInputDataFields}
                setSecondInputValue={setSecondInputValue}
              />

              <LockButton locked={locked} onSetLocked={setLocked} />
            </div>

            <Slider
              content={content}
              handleSliderChange={handleSliderChange}
              hasAtLeastOneFilledInputCell={hasAtLeastOneFilledInputCell}
              isMobile={isMobile}
              isShowRangeInput={isShowRangeInput}
              // Временно отключаем для показа единого текста на обоих вариантах поиска номера
              locked={false}
              maxSliderHPos={maxSliderHPos}
              position={position}
            />
          </div>
        </div>

        <Description content={content} isMobile={isMobile} locked={locked} />

        <CustomSmartButton />
      </div>
    );
  },
);

const mapStateToProps = (state: RootStateOrAny) => {
  return {
    defCodes: getDefCodesAndDefault(state),
    content: getWidgetContent(state),
    isResetWidgetFilters: getIsResetWidgetFilters(state),
  };
};

const mapDispatchToProps = {
  onSetActiveFilter: setActiveFilter,
  onSetFilterDefCode: setFilterDefCode,
  onSetInputDataFields: setInputDataFieldsAC,
  onSetFilterQuery: setFilterQuery,
  onResetFilter: clearWidgetFilters,
  onSetResetWidgetFilters: setResetWidgetFilters,
};

export const MainContent = connect(mapStateToProps, mapDispatchToProps)(_MainContent);
