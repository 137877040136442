import { setChatWidgetOpen } from '@beef/widget-chat';

import { clickOnElement } from './clickOnElement';

/** Открывает чат с поддержкой */
export const openSupportChat = () => {
  setChatWidgetOpen(true);
};

/* Пока нет метода для открытия меню авторизации в хэдере,
 * поэтому используем поиск по элементу и программный клик */
/** Открывает меню авторизации */
export const openAuthMenu = () => {
  clickOnElement('header [data-metric-data="войти"]');
};
