import { useEffect, useMemo, useState } from 'react';

export const BASE_COUNTDOWN_TIME = 60;

// ToDo: вынести в тулзы или переиспользовать с лендинга голосования по вышкам
export const useCountdown = () => {
  const [timeLeft, setTimeLeft] = useState(0);

  // Функция для преобразования секунд в формат mm:ss
  const formatTime = () => {
    let minutes = Math.floor(timeLeft / 60);
    let seconds = timeLeft % 60;

    if (minutes < 10) minutes = `0${minutes}`;
    if (seconds < 10) seconds = `0${seconds}`;

    return `${minutes}:${seconds}`;
  };

  // Эффект для запуска таймера
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1); // Уменьшаем оставшееся время на одну секунду

      if (timeLeft <= 0) {
        clearInterval(intervalId); // Остановка таймера при достижении нуля
        setTimeLeft(0);
      }
    }, 1000);

    return () => clearInterval(intervalId); // Чистка интервала при размонтаже компонента
  }, [timeLeft]);

  return useMemo(
    () => ({
      countdown: timeLeft > 0 ? formatTime() : null,
      startCountdown: (time: number) => setTimeLeft(time),
    }),
    [timeLeft],
  );
};
