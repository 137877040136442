import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { enhancedConnect } from 'utils/lowCodeHelpers';

import { TLogo } from './types';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

const _Logo = ({ src, href, alt }: TLogo) => (
  <a className={cn('link')} href={href}>
    <img alt={alt} className={cn('logo')} src={src} />
  </a>
);

export const Logo = enhancedConnect({
  alt: PropTypes.string,
  src: PropTypes.string,
  href: PropTypes.string,
})(_Logo);
