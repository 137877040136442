import { EHeaderTheme, setHeaderTheme } from '@carnica/smart-kit';
import { useWillMount } from '@beef/react';

import { headerStore2024 } from 'store';

/** Хук инициализации темы для HeaderV2 */
export const useICustomHeaderTheme = () => {
  useWillMount(() => {
    try {
      headerStore2024().dispatch(setHeaderTheme(EHeaderTheme.primary));
    } catch (e) {
      console.error(e);
    }
  });
};
