import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';

import { withHelpTypesMapper } from 'pages/LizaAlertLandingPage/store/selectors';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _HelpTypesList = ({ children }: { children: ReactNode }) => {
  return <div className={cx('wrapper')}>{children}</div>;
};

export const HelpTypesList = withHelpTypesMapper(_HelpTypesList);
