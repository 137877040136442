import axios from 'axios';
import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit';

import { getCurrentNumber } from 'pages/FancyNumber2022/store/selectors/change';
import {
  ErrorGeneralFault,
  resetError,
  setError,
  setSmsLoadingStatus,
  setSuccessStatus,
} from 'pages/FancyNumber2022/store/actions/change';
import { camelize } from 'pages/FancyNumber2024/utils/camelize';
import { removeSpaces } from 'pages/FancyNumber2024/utils/removeSpaces';
import {
  CHANGE_NUMBER_URL,
  SMS_REQUEST_ERRORS,
} from 'pages/FancyNumber2024/components/ConfirmationContainer/constants';

// TODO: переписать при рефакторинге. Реализовать полноценный отдельный store!
/** Вспомогательный action для получения/отправки SMS-кода при обновлении номера пользователя */
export const manageSms = createAsyncThunk<
  Promise<void>,
  string | undefined,
  {
    dispatch: Dispatch;
    // TODO: Типизировать RootState  после реализации отдельного store
    state: any;
  }
>('sms/request-resend', async (smsCode, { dispatch, getState }) => {
  /** Текущий выбранный (новый) номер пользователя */
  const currentNumber = getCurrentNumber(getState());

  /* Если текущий выбранный (новый) номер пользователя отсутствует, выходим из manageSms */
  if (!currentNumber) {
    return;
  }

  // TODO: в новой реализации store избавится от этой логики!
  /* Перед началом каждого нового запроса сбрасываем successStatus и error в store */
  // @ts-expect-error ошибка типизации actions из-за js
  dispatch(setSuccessStatus(false));
  // @ts-expect-error ошибка типизации actions из-за js
  dispatch(resetError());

  /* Если получили код на вход, выставляем smsLoadingStatus: true в store */
  if (smsCode) {
    // @ts-expect-error ошибка типизации actions из-за js
    dispatch(setSmsLoadingStatus(true));
  }

  try {
    /** Формируем объект запроса к CHANGE_NUMBER_URL */
    const requestOptions = {
      changetonumber: currentNumber.value,
      featurecode: currentNumber.featureCode,
      cost: removeSpaces(currentNumber.price),
      findnumberrule: 9,
      numbercategory: currentNumber.category,
      confirmationcode: smsCode,
    };

    const {
      data: { code, isSucceeded },
    } = await axios.put(CHANGE_NUMBER_URL, requestOptions);

    /* Если получили isSucceeded: true - записываем значение в store и
     * выходим из manageSms. Обновление прошло успешно */
    if (isSucceeded) {
      // @ts-expect-error ошибка типизации actions из-за js
      dispatch(setSuccessStatus(true));
      return;
    }

    // TODO при рефакторинге нужна стандартизация кодов ответов от бека
    /* Должен быть тип keyof typeof SMS_REQUEST_ERRORS, но страдает общая типизация manageSms и setError (ts) */
    const requestCode = camelize(code) as any;

    /* Если получили код ошибки (кроме "generalFault" - общая ошибка),
     * записываем code в store и выходим из manageSms */
    if (code && requestCode !== SMS_REQUEST_ERRORS.generalFault) {
      dispatch(setError(requestCode));
      /* Требуется возвращать код ошибки для корректной обработки .then() с переходом на результирующую страницу */
      // eslint-disable-next-line consistent-return
      return requestCode;
    }

    /* Если предыдущие условия не отработали, выставляем "generalFault" (общую ошибку) в store и
     * показываем экран общей ошибки */
    // @ts-expect-error ошибка типизации actions из-за js
    dispatch(setError(ErrorGeneralFault));
    throw new Error();
  } catch (e) {
    // @ts-expect-error ошибка типизации actions из-за js
    dispatch(setSmsLoadingStatus(false));
    throw new Error('ErrorGeneralFault');
  }
});
