import React, { FC } from 'react';
import classNames from 'classnames/bind';

import RobotSvg from 'pages/FancyNumber2024/components/NotAuthBlock/components/Picture/assets/robot_main.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент-обертка над изображением в блоке NotAuthBlock */
export const Picture: FC = () => (
  <div className={cx('picture')}>
    <RobotSvg />
  </div>
);
