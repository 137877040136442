import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { IconCallingFilled } from '@carnica/graphics';
import PropTypes from 'prop-types';
import { useIsDesktopDimension } from '@carnica/utils/hooks';

import { enhancedConnect } from 'utils/lowCodeHelpers';
import { TPhoneCard } from 'pages/LizaAlertLandingPage/components/PhoneCard/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const _PhoneCard = ({ title, description, href }: TPhoneCard) => {
  const isDesktop = useIsDesktopDimension();
  const Tag = isDesktop ? 'div' : 'a';

  return (
    <Tag className={cn('phone-card')} href={!isDesktop ? href : undefined}>
      <IconCallingFilled className={cn('icon')} size={24} />
      <div className={cn('texts')}>
        <Text className={cn('title')} variant="body-large">
          {title}
        </Text>
        <Text className={cn('description')} color="tertiary" variant="body-medium">
          {description}
        </Text>
      </div>
    </Tag>
  );
};

export const PhoneCard = enhancedConnect({
  title: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
})(_PhoneCard);
