import React from 'react';
import PropTypes from 'prop-types';

import { assertArrayValue } from 'utils/lowCodeHelpers/crude-mapping/components/CrudeMapper/utils';
import { cloneNthChildren } from 'utils/react-utils';

import { enhancedConnect } from '../../../enhancedConnect';
import { CrudeProvider } from '../CrudeContext';

type IProps = {
  alias: string;
  children: React.ReactNode;
  skipChildren?: number;
  value: any[] | string;
};

const _CrudeMapper = ({ alias, value, children, skipChildren = 0 }: IProps): any => {
  value = assertArrayValue(value);
  return cloneNthChildren(children, skipChildren, (nthChildren) =>
    value.map((item, index) => (
      <CrudeProvider alias={alias} extra={{ number: index + 1, index }} key={+index} value={item}>
        {nthChildren}
      </CrudeProvider>
    )),
  );
};

/**
 *
 * @param alias - короткое название для данных, которые будут доступны по $$.{alias}.*
 * @param value - массив данных, на основании которых будет производиться маппинг
 * @param children
 * @param skipChildren - число уровней вложения, которые необходимо пропустить, по умолчанию - 0.
 *   Обычно это нужно, чтобы не устанавливать маппер между компонентом и его дочерними элементами
 */
export const CrudeMapper = enhancedConnect({
  children: PropTypes.node as any,
  alias: PropTypes.string.isRequired,
  value: PropTypes.string as any,
  skipChildren: PropTypes.number,
})(_CrudeMapper);
