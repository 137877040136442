import { useSelector } from 'react-redux';

import { getAnimationsSettings } from 'pages/FancyNumber2022/store/selectors/content';
import { useLottieInView } from 'pages/CarnicaDSLandings/YaBusterLanding/hooks/useLottieInView';

/** Хук подготовки данных для компонента LottieAnimation */
export const useLottieAnimation = () => {
  const { containerRef, lottieRef } = useLottieInView({ once: false, margin: '10px' });

  /** Настройки анимации из контента.
   * Включают в себя loop, autoplay и animationLink */
  const { loop, autoplay, animationLink } = useSelector(getAnimationsSettings);

  /** Объект с настройками для компонента LottiePlayer */
  const lottyPlayerSettings = {
    loop,
    autoplay,
    lottieRef,
    animationLink,
  };

  /** Результирующий объект */
  return { containerRef, lottyPlayerSettings };
};
