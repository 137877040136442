import { parse, stringify } from 'query-string';

import {
  CATEGORY_ALIAS,
  CATEGORY_ALIAS_ARRAY,
  FANCY_NUMBER_URL,
  FILTER_ALIAS_ARRAY,
} from '../../constants';

const clearPathname = (pathname, valuesToRemove) => {
  let pathSegments = pathname.split('/').filter(Boolean);

  const removeValues = valuesToRemove.filter((value) => pathSegments.includes(value));

  pathSegments = pathSegments.filter((segment) => !removeValues.includes(segment));

  return {
    path: `/${pathSegments.join('/')}`,
    removeValues,
  };
};

const handleFilterChangeUrl = (pathname, query, filter) => {
  const { path, removeValues } = clearPathname(pathname, [
    ...FILTER_ALIAS_ARRAY,
    ...CATEGORY_ALIAS_ARRAY,
  ]);
  const categoryQuery = removeValues
    .filter((value) => CATEGORY_ALIAS_ARRAY.includes(value))
    .join('');
  let url = path;

  if (filter) {
    query.filter = filter;
  }

  if (categoryQuery) {
    url = `${url}${url.endsWith('/') ? '' : '/'}${categoryQuery}`;
  }

  return url;
};

const handleCategoryChangeUrl = (pathname, query, categories) => {
  const [mainCategory, ...restCategories] = categories;
  const { path, removeValues } = clearPathname(pathname, [
    ...FILTER_ALIAS_ARRAY,
    ...CATEGORY_ALIAS_ARRAY,
  ]);
  const filterQuery = removeValues.filter((value) => FILTER_ALIAS_ARRAY.includes(value)).join('');
  pathname = path;

  if (mainCategory && !restCategories.length) {
    // redesign 2024
    // для редизайна добавлена была категория all, но не должна подставляться в url при её выборе пользователем

    if (mainCategory === CATEGORY_ALIAS.all) {
      pathname = `${path}${path.endsWith('/') ? '' : '/'}`;
    } else {
      pathname = `${path}${path.endsWith('/') ? '' : '/'}${mainCategory}`;
    }

    if (filterQuery) {
      query.filter = filterQuery;
    }

    if (!restCategories.length) {
      delete query.categories;
    }
  } else if (restCategories.length) {
    pathname = `${path}${path.endsWith('/') ? '' : '/'}${mainCategory}`;
    query.categories = restCategories.join('_');

    if (filterQuery) {
      query.filter = filterQuery;
    }
    if (!query.categories) {
      pathname = handleFilterChangeUrl(pathname, query, query.filter);
    }
  } else {
    delete query.categories;
  }

  return pathname;
};

export const setFancyNumberPageUrl = () => window.history.replaceState({}, null, FANCY_NUMBER_URL);

export const updatePageUrl = ({ filter, numbers, categories }) => {
  let { pathname } = window.location;
  const query = parse(window.location.search);

  if (filter) {
    pathname = handleFilterChangeUrl(pathname, query, filter);
  }
  if (categories) {
    pathname = handleCategoryChangeUrl(pathname, query, categories);
  }
  if (typeof numbers !== 'undefined') {
    if (numbers) {
      query.numbers = numbers;
    } else {
      delete query.numbers;
    }
  }

  if (!query.categories) delete query.categories;
  if (!query.filter) delete query.filter;

  // eslint-disable-next-line max-len
  const url = `${pathname}${pathname.endsWith('/') ? '' : '/'}${
    Object.keys(query).length ? `?${stringify(query)}` : ''
  }`;
  window.history.replaceState({}, null, url);
};
