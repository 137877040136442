import { createAction } from '@reduxjs/toolkit';

import { getActiveCategoriesArray } from 'pages/FancyNumber2022/store/selectors/category';
import { FANCY_NUMBER_ALIAS, FILTER_ALIAS_ARRAY } from 'pages/FancyNumber2022/constants';
import { getAlias } from 'pages/FancyNumber2022/store/selectors/content';

export const setAlias = createAction('set alias');

// redesign2024
export const setTabId = createAction('set tab id');

export const setAliasAction = (alias) => (dispatch, getState) => {
  if (
    alias &&
    getActiveCategoriesArray(getState()).length <= 1 &&
    !FILTER_ALIAS_ARRAY.includes(getAlias(getState()))
  ) {
    dispatch(setAlias(alias));
    return;
  }
  dispatch(setAlias(FANCY_NUMBER_ALIAS));
};
