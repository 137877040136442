import React, { FC } from 'react';

import { PageContainer } from 'pages/FancyNumber2024/components/PageContainer';
import { ResultContainer } from 'pages/FancyNumber2024/components/ResultContainer';

/** Контейнер результирующей страницы FancyNumber2024.
 * Используем дополнительный PageContainer из-за особенности верстки главного компонента _FancyNumberLayout2024 */
export const FancyNumberResult: FC = () => (
  <PageContainer>
    <ResultContainer />
  </PageContainer>
);
