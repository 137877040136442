import React from 'react';
import { Button, CodeInput, Text, Title } from '@carnica/ui-kit';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import {
  selectVolunteersConfirmationModal,
  selectVolunteersFormContent,
} from 'pages/LizaAlertLandingPage/store/selectors';
import {
  EConfirmationModalStep,
  EFetchStatus,
  setConfirmationCode,
  validateCode,
} from 'pages/LizaAlertLandingPage/store/volunteerFormSlice';
import { AppDispatch, RootState } from 'pages/LizaAlertLandingPage/store';
import { CountdownButton } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/ConfirmationModal/components/CountdownButton';
import { TCodeValidation } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/ConfirmationModal/components/CodeValidation/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const BASE_CODE_LENGTH = 4;

// ToDo: декомпозировать компонент на отдельные части с connect
const _CodeValidation = ({
  code,
  codeLength = BASE_CODE_LENGTH,
  title,
  description,
  submitButtonStatus,
  submitButtonText,
  onCodeChange,
  onButtonClick,
  visible,
  codeError,
  countdown,
  startCountdown,
}: TCodeValidation) => {
  return (
    <div className={cn('code-validation', { 'code-validation--visible': visible })}>
      <Title align="left" subTitle={description} title={title} />
      {codeError && (
        <Text className={cn('error')} color="error" tagName="label" variant="caption-accent-medium">
          {codeError}
        </Text>
      )}
      <CodeInput codeLength={codeLength} error={codeError} onChange={onCodeChange} value={code} />
      <CountdownButton countdown={countdown} startCountdown={startCountdown} />
      <Button
        className={cn('submit-button')}
        loading={submitButtonStatus === EFetchStatus.loading}
        onClick={onButtonClick}
        size="l"
        width="full"
      >
        {submitButtonText}
      </Button>
    </div>
  );
};

export const CodeValidation = connect(
  (state: RootState) => ({
    title: selectVolunteersFormContent(state).confirmationModal.code.title,
    description: selectVolunteersFormContent(state).confirmationModal.code.description,
    codeLength: selectVolunteersFormContent(state).confirmationModal.code.codeLength,
    submitButtonText: selectVolunteersFormContent(state).confirmationModal.code.submitButtonText,
    submitButtonStatus: selectVolunteersConfirmationModal(state).validateCodeFetchStatus,
    code: selectVolunteersConfirmationModal(state).code,
    codeError: selectVolunteersConfirmationModal(state).codeError,
    visible: selectVolunteersConfirmationModal(state).step === EConfirmationModalStep.code,
  }),
  (dispatch: AppDispatch) => ({
    onCodeChange: (value: number) => dispatch(setConfirmationCode(value)),
    onButtonClick: () => dispatch(validateCode()),
  }),
)(_CodeValidation);
