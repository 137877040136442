import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classnames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export type TConditionsStepsItem = {
  id: number;
  size?: string;
  subTitle?: string;
  title?: string;
};

/** Элемент компонента ConditionsSteps.
 * Содержит id (порядковый номер), заголовок и описание шага по обновлению номера */
export const ConditionsStepsItem: FC<TConditionsStepsItem> = ({
  id,
  size = 's',
  title,
  subTitle,
}) => (
  <li className={cx('wrapper')}>
    <Text className={cx('wrapper__step')} color="tertiary-invert" lineHeight={26} size="xs">
      {id}
    </Text>
    <div className={cx('wrapper__content')}>
      {title && (
        <Text align="left" color="primary" lineHeight={26} size={size} tagName="p">
          {title}
        </Text>
      )}
      {subTitle && (
        <Text align="left" color="secondary" lineHeight={26} size={size} tagName="p">
          {subTitle}
        </Text>
      )}
    </div>
  </li>
);
