import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { enhancedConnect } from 'utils/lowCodeHelpers';
import { TRowContainer } from 'pages/LizaAlertLandingPage/components/RowContainer/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const RowContainerRaw = ({ children, gap = 'xl', indent }: TRowContainer) => {
  return <div className={cx('row', `row--gap-${gap}`, `row--indent-${indent}`)}>{children}</div>;
};

export const RowContainer = enhancedConnect({
  children: PropTypes.node,
  gap: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '']),
  indent: PropTypes.oneOf(['xs', '']),
})(RowContainerRaw);

RowContainer.defaultProps = {
  gap: 'xl',
};
