import React from 'react';
import { Button, Select } from '@carnica/ui-kit';
import { IconTrashFilled } from '@carnica/graphics';
import { connect } from 'react-redux';
import { withFinalField } from '@beef/form';
import classNames from 'classnames/bind';

import type { RootState } from 'pages/LizaAlertLandingPage/store';
import { EFormInputNames } from 'pages/LizaAlertLandingPage/components/VolunteersForm/types';
import {
  selectCurrentLocationSelect,
  selectUnusedLocations,
  selectVolunteersFormContent,
} from 'pages/LizaAlertLandingPage/store/selectors';
import {
  clearFormValue,
  removeLocationSelect,
} from 'pages/LizaAlertLandingPage/store/volunteerFormSlice';
import { TLocationSelect } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/LocationSelect/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const _LocationSelect = ({
  removeButtonView,
  onRemove,
  onClear,
  ...selectProps
}: TLocationSelect) => {
  return (
    <div
      className={cn('location-select', {
        'location-select--with-remove-button': selectProps.id !== 0,
        'location-select--with-selected-item': !!selectProps.value && selectProps.id !== 0,
      })}
    >
      <Select {...selectProps} onClear={() => onClear?.(selectProps.name)} />
      {onRemove && selectProps.id !== 0 && (
        <Button
          iconLeft={IconTrashFilled}
          onClick={() => onRemove(selectProps.name, selectProps.id)}
          onlyIcon
          view={removeButtonView}
        />
      )}
    </div>
  );
};

export const LocationSelect = connect(
  (state: RootState) => ({
    name: `${EFormInputNames.location}_${selectCurrentLocationSelect(state).id}`,
    id: selectCurrentLocationSelect(state).id,
    items: selectUnusedLocations(state),
    label: selectVolunteersFormContent(state).cityFieldLabel,
    placeholder: selectVolunteersFormContent(state).cityFieldPlaceholder,
    withMobileOffset: false,
    withClearButton: true,
  }),
  (dispatch) => ({
    onRemove: (name: string, id: number) => {
      dispatch(
        removeLocationSelect({
          name,
          id,
        }),
      );
      dispatch(clearFormValue(name));
    },
    onClear: (name: string) => dispatch(clearFormValue(name)),
  }),
)(withFinalField(_LocationSelect));
