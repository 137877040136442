import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { defineUrlIcon } from '@carnica/graphics';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { getIsLoadingSms } from 'pages/FancyNumber2022/store/selectors/change';

import { ICON_CHEVRON_LEFT } from '../constants';

/** Хук подготовки функции-обработчика для кнопки "Назад" */
export const useBackButton = () => {
  const navigate = useNavigate();

  /** Флаг определяет, является ли текущий просматриваемый экран mobile-формата */
  const isMobile = useIsMobileDimension();

  /** Флаг отражает статус отправки запроса (метод изменения номера телефона) */
  const isLoadingSms = useSelector(getIsLoadingSms);

  /** Если пользователь просматривает НЕ mobile-версию страницы и
   * отправка запроса на подтверждение обновления номера не происходит,
   * покажем кнопку (в мобиле кнопка скрывается) */
  const isVisible = !isMobile && !isLoadingSms;

  /** Функция возврата на предыдущую страницу */
  const handleClick = () => navigate(-1);

  /** Подготовленная иконка стрелки "Назад" */
  const icon = defineUrlIcon({ url: ICON_CHEVRON_LEFT, ratio: null });

  return { icon, isVisible, handleClick };
};
