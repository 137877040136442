import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'querystring';

import globalStore from 'store';
import { isEmptyValue } from 'utils/isEmptyValue';

import { FILTER_FAVORITE } from '../../constants';
import {
  fetchAllNumbers,
  fetchChangeNumbers,
  fetchFavoriteNumbers,
  fetchSettings,
  fetchSimilarNumbers,
} from '../api/index';
import {
  getActiveFilter,
  getDefCodeValue,
  getIsQueryValid,
  getQueryValue,
} from '../selectors/filter';
import {
  getNextRandomNumber,
  selectCaptchaData,
  selectIsCaptchaEnabled,
} from '../selectors/numbers';
import { pushNextRandomClick } from '../../analytics/filter';
import { getRandomContent, selectIsRedesign2024Enabled } from '../selectors/content';
import { isChangeNumberLocation } from '../../utils';

export const setDefCodes = createAction('set def codes');
export const setNumbersList = createAction('set numbers list');
export const setRandomNumber = createAction('set random number');
export const setNumbersInCart = createAction('set numbers in cart');
export const setLoading = createAction('set numbers loading');
export const setError = createAction('set numbers error');
export const setCaptchaIsEnabled = createAction('set captcha is enabled');
export const setCaptchaData = createAction('set captcha data');
export const setReCallAfterCaptchaRequest = createAction('set recall after captcha request');

// redesign2024
export const setInputDataFields = createAction('set input data fields');
export const setLaunchCaptchaRequest = createAction('set launch captcha request');
export const setWidgetButtonState = createAction('set widget button state');

export const loadFilteredNumbersThunk = createAsyncThunk(
  'fetch filtered numbers',
  // eslint-disable-next-line no-unused-vars
  async ({ filter, query, defCode }, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const isRedesign2024 = selectIsRedesign2024Enabled(state);

    const captchaDataFromStore = selectCaptchaData(state);
    const captchaIsEnabled = selectIsCaptchaEnabled(state);

    const options = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify({
        captcha: captchaDataFromStore?.captchaToken,
        _captcha_key: captchaDataFromStore?.key,
      }),
      method: 'POST',
    };

    const captchaOptions = captchaIsEnabled ? options : {};

    // redesign2024
    if (isRedesign2024) {
      return dispatch(
        fetchFilteredNumbersR24({
          filter,
          captchaOptions,
          defCode,
          isRedesign2024,
          query,
          rejectWithValue,
        }),
      );
    }

    try {
      if (filter === FILTER_FAVORITE) {
        return await fetchFavoriteNumbers(query, captchaOptions);
      }

      return await fetchSimilarNumbers(
        { filter, number: query, defCode, isRedesign2024Enabled: isRedesign2024 },
        captchaOptions,
      );
    } catch (error) {
      console.error('Error in loadFilteredNumbersThunk:', error.response);
      return rejectWithValue(error);
    }
  },
);

export const loadFilteredChangeNumbersThunk = createAsyncThunk(
  'fetch change numbers',
  async ({ query, filter, defCode }, { getState }) => {
    const isRedesign2024 = selectIsRedesign2024Enabled(getState());

    return fetchChangeNumbers({
      query,
      filter,
      defCode,
      isRedesign2024Enabled: isRedesign2024,
    });
  },
);

export const loadDefCodes = () => async (dispatch) => {
  try {
    const defCodes = await fetchSettings();
    dispatch(setDefCodes(defCodes));
  } catch (err) {
    // do nothing
  }
};

const selectNextRandomNumber = (dispatch, getState) => {
  const nextNumber = getNextRandomNumber(getState());
  dispatch(setRandomNumber(nextNumber));
};

export const setNextRandomNumber = () => (dispatch, getState) => {
  dispatch(selectNextRandomNumber);
  pushNextRandomClick(getRandomContent(getState()).category);
};

export const loadInitialNumbers = () => async (dispatch, getState) => {
  if (getIsQueryValid(getState())) {
    await dispatch(loadFilteredNumbers);
  } else {
    await dispatch(loadAllNumbers());
  }
  dispatch(selectNextRandomNumber);
};

export const loadAllNumbers = () => async (dispatch, getState) => {
  const isChangeNumber = isChangeNumberLocation();
  const state = getState();

  const query = getQueryValue(state);

  if (isChangeNumber) {
    dispatch(loadFilteredChangeNumbersThunk({ query }));
  } else {
    try {
      dispatch(setLoading(true));
      const numbers = await fetchAllNumbers();
      dispatch(setNumbersList(numbers));
      dispatch(setCaptchaIsEnabled(false));
    } catch (err) {
      dispatch(setError(true));
      dispatch(setNumbersList({}));
    } finally {
      dispatch(setLoading(false));
    }
  }
};

export const loadFilteredNumbers = async (dispatch, getState) => {
  const isChangeNumber = isChangeNumberLocation();

  const query = getQueryValue(getState());
  if (query.length < 2) return;
  const filter = getActiveFilter(getState());
  const defCode = getDefCodeValue(getState());

  if (isChangeNumber) {
    dispatch(
      loadFilteredChangeNumbersThunk({
        query,
        filter,
        defCode,
      }),
    );
  } else {
    await dispatch(loadFilteredNumbersThunk({ filter, query, defCode }));
  }
};

export const loadNumbersInCart = () => (dispatch) => {
  try {
    const numbers = globalStore.getState().external.cartData.currentCart.numbers || [];
    dispatch(setNumbersInCart(numbers.map((number) => +number)));
  } catch (err) {
    // no action needed
  }
};

export const loadFancyNumberData = () => (dispatch) => {
  dispatch(loadDefCodes());
  dispatch(loadInitialNumbers());
  dispatch(loadNumbersInCart());
};

// redesign2024

export const fetchFilteredNumbersR24 =
  ({ filter, query, captchaOptions, defCode, isRedesign2024, rejectWithValue }) =>
  async (dispatch) => {
    let result;

    // eslint-disable-next-line consistent-return
    function checkResultOfNumbers(res) {
      const isEmptyNumbers = isEmptyValue(res);
      // если ни один из номеров не был найден, то вызвать заново все
      if (isEmptyNumbers) {
        dispatch(setError(true));
        dispatch(loadAllNumbers());
      } else {
        return res;
      }
    }

    try {
      if (filter === FILTER_FAVORITE) {
        result = await fetchFavoriteNumbers(query, captchaOptions);
        return checkResultOfNumbers(result);
      }

      result = await fetchSimilarNumbers(
        { filter, number: query, defCode, isRedesign2024Enabled: isRedesign2024 },
        captchaOptions,
      );

      return checkResultOfNumbers(result);
    } catch (error) {
      console.error('Error in loadFilteredNumbersThunk:', error.response);
      return rejectWithValue(error);
    }
  };
