import { YMMetricHelper, createMetric } from '@beef/metric';

import { getCommonPropsStore2024 } from 'utils/analytics/ymBase';
import { getContentData } from 'pages/ContactPage/store';

export class ContactPageMetric extends YMMetricHelper {
  protected readonly YM_PARAMS_KEY = 'LP_contact_page';

  protected setParamsPageInfo = (params: Record<string, any>) => {
    if (typeof window === 'undefined')
      return {
        [this.YM_PARAMS_KEY]: {
          ...params,
        },
      };

    const commonPropsYM = getCommonPropsStore2024();

    return {
      [this.YM_PARAMS_KEY]: {
        ...commonPropsYM,
        ...params,
      },
    };
  };

  // п.6
  handleOpenChatClick(buttonText: string) {
    this.sendParamsEvent(
      this.setParamsPageInfo({
        action: 'click',
        button_text: `${buttonText}`,
        object_type: 'block',
        block_title: 'написать в чат',
        item_type: 'button',
      }),
    );
  }

  // п.7
  handleAppBLockStoresClick(storeName: string) {
    this.sendParamsEvent(
      this.setParamsPageInfo({
        action: 'click',
        item_type: 'icon',
        icon_name: `${storeName}`,
        object_type: 'block',
        block_title: 'мобильное приложение',
      }),
    );
  }

  handlePhoneNumbersSpoilerClick(spoilerTitle: string) {
    const blockTitle = this.getData()?.phoneNumbersBlock?.title;
    this.sendParamsEvent(
      this.setParamsPageInfo({
        action: 'click',
        item_type: 'spoiler',
        spoiler_title: spoilerTitle,
        object_type: 'block',
        block_title: blockTitle,
      }),
    );
  }

  handleMessengersSpoilerClick(spoilerTitle: string) {
    this.sendParamsEvent(
      this.setParamsPageInfo({
        action: 'click',
        item_type: 'spoiler',
        spoiler_title: spoilerTitle,
      }),
    );
  }

  handleCardClick(elementTitle: string, spoilerTitle: string, isBlock: boolean) {
    const blockTitle = isBlock ? this.getData()?.phoneNumbersBlock?.title : undefined;
    this.sendParamsEvent(
      this.setParamsPageInfo({
        action: 'click',
        item_type: 'element',
        element_title: elementTitle,
        spoiler_title: spoilerTitle,
        object_type: 'block',
        block_title: blockTitle,
      }),
    );
  }

  handleBannerClick(elementTitle: string) {
    this.sendParamsEvent(
      this.setParamsPageInfo({
        action: 'click',
        item_type: 'element',
        element_title: elementTitle,
      }),
    );
  }
}

export const contactPageMetric = new ContactPageMetric({
  counterId: 26001372,
  isEcommerce: false,
  debug: true,
  selector: getContentData,
});

export const { Metric, decorateHandler, withMetricContainer } = createMetric(
  {
    contactPageMetric,
  },
  'debug',
);
