import axios from 'axios';
import { formatOrderData } from '@beef/layout-kit/utils';

import { IBasketResponse } from './fetchBasket';

const CREATE_BASKET_URL = '/basketapi/api/v1/';

export type CreateBasketRequestData = ReturnType<typeof formatOrderData>;

/**
 * Запрос на создание корзины
 */
export const createBasket = async (payload: CreateBasketRequestData) => {
  try {
    const { data } = await axios.post<IBasketResponse>(
      `${CREATE_BASKET_URL}${payload.apiEndpoint}`,
      payload.body,
    );

    return data;
  } catch (error) {
    return String(error);
  }
};
