import React from 'react';
import classNames from 'classnames/bind';
import { setCartQuantity } from '@carnica/smart-kit';
import type { ConnectedProps } from 'react-redux';
import {
  CART_URL,
  formatAdditionalOptions,
  formatOrderData,
  handleSuccessRequest,
} from '@beef/layout-kit/utils';
import { Button, Link, Radio, Text } from '@beef/ui-kit';

import FancyNumberWidget from 'pages/ProductCard/components/FancyNumberWidget/FancyNumberWidget';
import FancyNumberWidgetHOC from 'pages/ProductCard/hoc/FancyNumberWidgetHOC';
import Ctx from 'pages-components/Ctx';
import { isNumberBought } from 'utils/cartHelpers';
import { maskPhone } from 'utils/format-string';
import { addToCartSimCardYM } from 'utils/analytics/tariffsYandexMetrica';
import { pushEcommerceBuySim } from 'utils/analytics/simOrderAnalytics';
import { ymSimMNPAddToCart } from 'utils/analytics/ymCommonEvents';
import { ETariffsVkGoals, vkPushSimTariffEvent } from 'utils/analytics/simTariffVkEvents';
import { headerStore2024 } from 'store';

import { connector } from './connector';
import type { ICartData, IOwnProps } from './types';
import { OptionStatus, SubmitStatus } from './types';
import EsimDescription from '../EsimDescription';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const getDefaultOption = ({
  esimAvailable,
  availableInShop,
}: Record<string, boolean>): OptionStatus | null => {
  if (availableInShop) {
    return OptionStatus.Sim;
  }
  if (esimAvailable) {
    return OptionStatus.Esim;
  }
  return null;
};

type Props = ConnectedProps<typeof connector> & IOwnProps;

/**
 * Форма добавления сим в корзину (через api корзины)
 */
const SimCartFormContent: React.FC<Props> = ({
  alias,
  note,
  availableInShop,
  chosenNumber,
  onStoreButtonClick,
  onNumberChange,
  onRequestError,
  id,
  soc,
  trafficPackage,
  cartApi,
  rootCtnChosenIndex,
  onRootCtnChosenIndexChange,
  additionalOptions,
  handleSetCartData,
  openFancyNumberModal,
  fancyNumberModalChoice,
  esimData,
  boughtNumbers,
  simOrderPlace,
  analyticsData,
  constructorTariffData,
  constructorParams,
  personalPlanData,
  sendAnalytics,
}) => {
  const [currentOption, setCurrentOption] = React.useState<OptionStatus | null>(
    getDefaultOption({ esimAvailable: !!esimData, availableInShop }),
  );
  const [submitStatus, setSubmitStatus] = React.useState<SubmitStatus>(SubmitStatus.Initial);
  const [choiceAvailable, setChoiceAvailable] = React.useState(!!esimData && availableInShop);

  React.useEffect(() => {
    setChoiceAvailable(!!esimData && availableInShop);
  }, [esimData, availableInShop]);

  const handleChangeNumber = React.useCallback(() => {
    setSubmitStatus(SubmitStatus.Initial);
    onNumberChange?.();
  }, [onNumberChange]);

  const isBought = React.useMemo(
    () => isNumberBought(boughtNumbers, chosenNumber),
    [boughtNumbers, chosenNumber],
  );

  const simPurchaseChosen = currentOption === OptionStatus.Sim;
  const formDisabled = submitStatus === SubmitStatus.Pending;
  /* доп опции для тарифа */
  const additionalSimOptions = formatAdditionalOptions(additionalOptions);

  const handleBuySim = async () => {
    /** Добавление Сим карты в корзину Клик на Купить */
    addToCartSimCardYM(document.location.href);
    ymSimMNPAddToCart();
    vkPushSimTariffEvent(alias, ETariffsVkGoals.SimTariffBasket);
    setSubmitStatus(SubmitStatus.Pending);
    // @ts-expect-error formatOrderData use JS
    const requestData = formatOrderData({
      tariffId: id,
      ctn: chosenNumber,
      additionalSimOptions,
      simOrderPlace,
      constructorTariffData,
      personalPlanData,
    });

    pushEcommerceBuySim(
      {
        id,
        soc,
        trafficPackage,
        pageTitle: analyticsData.name,
        subscriptionFee: analyticsData.price,
      },
      constructorParams,
    );

    try {
      const { data }: { data: ICartData } = await cartApi.post(
        requestData.apiEndpoint,
        requestData.body,
      );

      if (data.id) {
        const cartQuantity = data?.stats?.quantity || 0;

        handleSuccessRequest({
          cartId: data.id,
          items: data.items,
        });

        // Pass quantity of goods to basket for user's view
        headerStore2024().dispatch(setCartQuantity(cartQuantity));
        // LEGACY !!! Call deprecated action to pass all basket data to oldest store.external
        handleSetCartData(data);

        if (sendAnalytics) {
          sendAnalytics({ isConnect: true });
        }
        setSubmitStatus(SubmitStatus.Success);
      } else {
        onRequestError(Ctx.defaultError);
      }
    } catch (e) {
      onRequestError(Ctx.defaultError);
    }
  };

  return (
    <div className={cx('wrap')}>
      <div
        className={cx({
          'radio-list': !!choiceAvailable,
        })}
      >
        {choiceAvailable && (
          <div className={cx('radio-list-item')}>
            <Radio
              checked={currentOption === OptionStatus.Sim}
              disabled={formDisabled}
              label="Купить обычную SIM"
              name="sim-type"
              onChange={(e) => {
                setCurrentOption(e.target.value as unknown as OptionStatus);
              }}
              radioVariant="default"
              value={OptionStatus.Sim}
            />
          </div>
        )}
        {simPurchaseChosen && (
          <div className={cx('number-widget-wrap')}>
            {fancyNumberModalChoice ?
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {chosenNumber && (
                  <>
                    <div
                      className={cx('number-view', {
                        'number-view--success': isBought,
                      })}
                    >
                      {maskPhone(chosenNumber)}
                    </div>
                    <div
                      className={cx('number-choice-btn', {
                        'number-choice-btn--to-left': choiceAvailable,
                      })}
                    >
                      <Button onClick={openFancyNumberModal} size="m" variant="plain">
                        Выбрать другой номер
                      </Button>
                    </div>
                  </>
                )}
              </>
            : <FancyNumberWidgetHOC
                boughtNumbers={boughtNumbers}
                chosenNumber={chosenNumber}
                disabled={formDisabled}
                number={chosenNumber}
                onNumberChange={handleChangeNumber}
                onRootChosenIndexChange={onRootCtnChosenIndexChange}
                rootChosenIndex={rootCtnChosenIndex}
              >
                {/* @ts-expect-error FancyNumberWidget use JSX.PropTypes */}
                <FancyNumberWidget />
              </FancyNumberWidgetHOC>
            }
          </div>
        )}
        {choiceAvailable && (
          <div className={cx('radio-list-item')}>
            <Radio
              checked={currentOption === OptionStatus.Esim}
              disabled={formDisabled}
              label="eSIM онлайн"
              name="sim-type"
              onChange={(e) => {
                setCurrentOption(e.target.value as unknown as OptionStatus);
              }}
              radioVariant="default"
              value={OptionStatus.Esim}
            />
          </div>
        )}
      </div>
      {currentOption === OptionStatus.Esim && esimData && (
        <div className={cx('esim-description')}>
          <EsimDescription
            appButtons={esimData.appButtons}
            descriptionList={esimData.descriptionList}
            onStoreButtonClick={onStoreButtonClick}
            qrCodeUrl={esimData.qrCodeUrl}
          />
        </div>
      )}
      {note && (
        <div className={cx('note')}>
          <Text size="size6-r">{note}</Text>
        </div>
      )}
      {simPurchaseChosen && (
        <div className={cx('submit')}>
          {isBought ?
            <Link
              buttonSize="l"
              buttonVariant="secondary"
              href={CART_URL}
              isFullWidth
              variant="button"
            >
              Перейти в корзину
            </Link>
          : <Button disabled={formDisabled} fullWidth onClick={handleBuySim} size="l">
              Купить
            </Button>
          }
        </div>
      )}
    </div>
  );
};

export default connector(SimCartFormContent);
