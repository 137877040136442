import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { enhancedConnect } from 'utils/lowCodeHelpers';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _CardsWrapper = ({ children }: { children: ReactNode }) => {
  return <div className={cx('cards-wrapper')}>{children}</div>;
};

export const CardsWrapper = enhancedConnect({
  children: PropTypes.node,
})(_CardsWrapper);
