import { formatLocationsData } from 'pages/LizaAlertLandingPage/components/VolunteersForm/utils';
import { ILizaAlertLandingPage } from 'pages/LizaAlertLandingPage/types';

export const dataConverter = ({
  data: { content, help_types, locations },
}: ILizaAlertLandingPage) => {
  return {
    blocks: content,
    form: {
      helpTypes: help_types,
      locations: formatLocationsData(locations),
    },
  };
};
