import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Button, Text } from '@carnica/ui-kit';
import { useIsDesktopDimension } from '@carnica/utils/hooks';

import { enhancedConnect } from 'utils/lowCodeHelpers';
import { TBanner } from 'pages/LizaAlertLandingPage/components/Banner/types';
import { windowScrollToBlock } from 'utils/scrollToBlock';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const _Banner = ({
  backgroundImage,
  backgroundImageMobile,
  backgroundPosition = 'center',
  backgroundSize = 'cover',
  title,
  description,
  buttonText,
  buttonHref,
  titleColor = 'primary-invert',
  descriptionColor = 'tertiary',
}: TBanner) => {
  const isDesktop = useIsDesktopDimension();

  const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    if (!buttonHref.startsWith('#')) return;
    event.preventDefault();
    windowScrollToBlock(buttonHref.replace('#', ''));
  };

  return (
    <div
      className={cn('banner')}
      style={{
        backgroundImage: `url(${
          isDesktop || !backgroundImageMobile ? backgroundImage : backgroundImageMobile
        })`,
        backgroundPosition,
        backgroundSize,
      }}
    >
      <Text className={cn('title')} color={titleColor} variant="display-small">
        {title}
      </Text>
      <Text className={cn('description')} color={descriptionColor} variant="body-small">
        {description}
      </Text>
      <Button className={cn('button')} href={buttonHref} onClick={handleClick} size="l" tagName="a">
        {buttonText}
      </Button>
    </div>
  );
};

export const Banner = enhancedConnect({
  backgroundImage: PropTypes.string,
  backgroundImageMobile: PropTypes.string,
  backgroundPosition: PropTypes.string,
  backgroundSize: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  buttonHref: PropTypes.string,
  titleColor: PropTypes.string,
  descriptionColor: PropTypes.string,
})(_Banner);

Banner.defaultProps = {
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  titleColor: 'primary-invert',
  descriptionColor: 'tertiary',
};
