import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

// TODO: переписать компонент при рефакторинге
export const CountryCode = memo(() => (
  <div className={cx('country-code')}>
    <Text className={cx('country-code-text')} color="white" size="size3-m">
      +7
    </Text>
  </div>
));
