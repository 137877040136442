import React from 'react';
import classNames from 'classnames/bind';
import { cnTheme } from '@carnica/themes';
import { useGetHeaderHeight } from '@carnica/smart-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  mode?: 'dark' | 'light';
}

export const AppLayout: React.FC<LayoutProps> = ({ children, mode = 'light', className }) => {
  const headerHeight = useGetHeaderHeight();

  return (
    <div className={cx('layout', cnTheme(mode), className)} style={{ paddingTop: headerHeight }}>
      <div className={cx('container')}>{children}</div>
    </div>
  );
};
