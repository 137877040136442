import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@carnica/ui-kit';
import classnames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export type TConditionsButton = {
  btnText?: string;
  className?: string;
  /* Флаг позволяет включать/выключать отображение кнопки */
  isVisible?: boolean;
  link: string;
};

/** Компонент кнопки для ConditionsContainer */
export const ConditionsButton: FC<TConditionsButton> = ({
  btnText,
  className,
  isVisible = false,
  link,
}) =>
  isVisible ?
    <div className={cx('wrapper', className)}>
      <Link to={link}>
        <Button size="l" type="button" view="primary" width="full">
          {btnText}
        </Button>
      </Link>
    </div>
  : null;
