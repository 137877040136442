import { parseUrl } from 'query-string';

import { CATEGORY_ALIAS, FANCY_NUMBER_URL, FILTER_ALIAS_ARRAY } from './constants';

export const findSegmentOfCategory = (pathname, categories) => {
  const pathSegments = pathname.split('/').filter(Boolean);

  return Object.keys(categories).filter((value) => pathSegments.includes(value))[0];
};

export const parseFancyNumberUrl = (url) => {
  const {
    url: pathname,
    query: { filter, categories, numbers, type },
  } = parseUrl(url);
  const mainCategory = findSegmentOfCategory(pathname, CATEGORY_ALIAS);
  const queryFilter = FILTER_ALIAS_ARRAY.includes(filter) ? filter : null;
  const pathFilter = FILTER_ALIAS_ARRAY.find((filterValue) => pathname.includes(filterValue));
  return {
    // Merge into one array. Weired syntax, thanks ES6
    categories: [...(mainCategory ? [mainCategory] : []), ...(categories?.split('_') || [])],
    filter: queryFilter ?? pathFilter,
    numbers,
    type,
  };
};

export const isChangeNumberLocation = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);

  return searchParams.get('type') === 'change';
};

export const redirectToNewSimPage = () => {
  const { origin, pathname } = window.location;
  const { referrer } = document;
  const isFancyNumberReferrer = referrer?.includes(FANCY_NUMBER_URL);
  const { pathname: refPathname, search: refSearch } =
    isFancyNumberReferrer ? new URL(referrer) : {};
  window.location.href = `${origin}${
    isFancyNumberReferrer ? `${refPathname}${refSearch}` : pathname
  }`;
};

/** Функция проверки отключения всех фильтров в объекте active */
export const allFiltersTurnedOff = (filters) => {
  /* Если filters отсутствуют, возвращаем false */
  if (!filters) {
    return false;
  }

  /* Получаем ключи объекта filters */
  const keys = Object.keys(filters);

  /* Проверяем, все ли значения равны false */
  return keys.every((key) => filters[key] === false);
};
