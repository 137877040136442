import { useCallback, useMemo } from 'react';
import { TextPropVariant } from '@carnica/ui-kit/components/Text/types';
import { useIsMobileDimension } from '@carnica/utils/hooks';

import { yaMNPMetric } from 'pages/MNPLanding/analytics';
import { useAppSelector } from 'pages/MNPLanding/store';
import { selectFAQContent } from 'pages/MNPLanding/store/slices/commonSlice/selectors';
import { useIsLargeDesktopScreen } from 'pages/MNPLanding/hooks/media/useIsLargeDesktopScreen';

/**
 * Хук для данных блока faq
 */
export const useFAQSection = () => {
  const faqContent = useAppSelector(selectFAQContent);

  const isMobile = useIsMobileDimension();
  const isLargeDesktop = useIsLargeDesktopScreen();

  /**
   * Хендлер для отправки аналитики по клику на элемент блока FAQ
   * Используем нативный вызов метода класса аналитики, поскольку
   * нет возможности вызвать метод с передачей нескольких параметров
   */
  const handleSendFAQAnalytics = useCallback((flag: boolean, titleBlock: string) => {
    if (!flag) {
      yaMNPMetric.handleClickShowFAQItem(titleBlock);
    } else {
      yaMNPMetric.handleClickHideFAQItem(titleBlock);
    }
  }, []);

  const titleVariant: TextPropVariant = useMemo(() => {
    let variant = 'display-medium';

    if (isMobile) {
      variant = 'display-small';
    }

    if (isLargeDesktop) {
      variant = 'display-large';
    }

    return variant;
  }, [isMobile, isLargeDesktop]);

  return {
    ...faqContent,
    handleSendFAQAnalytics,
    titleVariant,
    isMobile,
  };
};
