import React, { FC } from 'react';

import { BannerLayout } from 'pages/FancyNumber2024/components/BannerLayout';
import { MainLayout } from 'pages/FancyNumber2024/components/MainLayout';
import { CustomFaq } from 'pages/FancyNumber2024/components/CustomFaq';

/** Контейнер главной страницы FancyNumber2024 */
export const FancyNumberMain: FC = () => (
  <>
    {/* Layout главного баннера c заголовком и анимацией */}
    <BannerLayout />
    {/* Layout основного блока с выбором номеров */}
    <MainLayout />
    {/* Layout faq */}
    <CustomFaq />
  </>
);
