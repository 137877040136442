import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import { AvatarGroup, Text } from '@carnica/ui-kit';
import { IconInfoFilled } from '@carnica/graphics';

import { Card } from 'pages/MNPLanding/components/Card';
import { Metric } from 'pages/MNPLanding/analytics';

import styles from './styles.pcss';
import { useTariffCardData } from './hooks/useTariffCardData';

const cx = classNames.bind(styles);

/**
 * Карточка с информацией о тарифе MNP
 */
export const TariffCard: FC = memo(() => {
  const { optionsTitle, optionsImages, info, backgroundImageUrl, presetText, presetPriceText } =
    useTariffCardData();

  return (
    <Metric events={{ yaMNPMetric: ['handleViewTariffCard'] }} mode="viewport">
      <Card border className={cx('card-wrapper')}>
        <Card className={cx('card', 'card--tariff')}>
          <Text color="primary-invert" variant="headline-medium">
            {presetText}
          </Text>
          <div className={cx('options')}>
            <Text color="secondary-invert" variant="body-small">
              {optionsTitle}
            </Text>
            <AvatarGroup className={cx('images')} items={optionsImages} size="s" />
          </div>
          <Text color="primary-invert" variant="body-small">
            {presetPriceText}
          </Text>
          {backgroundImageUrl ?
            ['small', 'large'].map((imageSize) => (
              <img
                alt=""
                className={cx('bg-image', `bg-image--${imageSize}`)}
                key={imageSize}
                src={backgroundImageUrl}
              />
            ))
          : null}
        </Card>
        <Card className={cx('card', 'card--info')}>
          <div className={cx('content')}>
            <IconInfoFilled className={cx('icon')} color="secondary" size="m" />
            <Text allowLineFeed={false} className={cx('info')} color="primary" variant="body-small">
              {info}
            </Text>
          </div>
        </Card>
      </Card>
    </Metric>
  );
});
