import React, { useLayoutEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { fancyNumberPages } from 'pages/FancyNumber2024/router/pathes';
import { FANCY_NUMBER_BASE_URL } from 'pages/FancyNumber2024/constants';
import { prepareUrl } from 'pages/FancyNumber2024/router/utils/prepareUrl';
import { FancyNumberMain } from 'pages/FancyNumber2024/pages/FancyNumberMain';
import { FancyNumberResult } from 'pages/FancyNumber2024/pages/FancyNumberResult';
import { FancyNumberAttention } from 'pages/FancyNumber2024/pages/FancyNumberAttention';
import { FancyNumberConditions } from 'pages/FancyNumber2024/pages/FancyNumberConditions';
import { FancyNumberConfirmation } from 'pages/FancyNumber2024/pages/FancyNumberConfirmation';

import { WithReactRouterProps, withReactRouter } from '../../../enhancers/withReactRouter';

/* Главный роутер FancyNumber2024. Содержит HOC для корректной работы SSR */
const FancyNumber2024Router = ({
  baseUrl: serverBaseUrl = FANCY_NUMBER_BASE_URL,
}: WithReactRouterProps) => {
  /** Используем baseUrl из hydrate props для предотвращения задержки получения данных из store
   Мемоизируем актуальный baseUrl в зависимости от окружения.
   Здесь НЕЛЬЗЯ использовать утилиту getEnvBaseUrl, так как для локалки важно получать "/" как baseUrl */
  const baseUrl = process.env.NODE_ENV === 'development' ? '/' : prepareUrl(serverBaseUrl);

  /** Авто-прокрутка страницы вверх */
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Routes>
      {/* Главная страница */}
      <Route element={<FancyNumberMain />} errorElement={<p>error</p>} path={baseUrl} />
      {/* Страница условий (порядок обновления номера) */}
      <Route
        element={<FancyNumberConditions />}
        path={`${baseUrl}${fancyNumberPages.conditions}`}
      />
      {/* Страница предупреждения (о невозможности пользования старого номера) */}
      <Route element={<FancyNumberAttention />} path={`${baseUrl}${fancyNumberPages.attention}`} />
      {/* Страница подтверждения (обновления номера по коду из SMS) */}
      <Route
        element={<FancyNumberConfirmation />}
        path={`${baseUrl}${fancyNumberPages.confirmation}`}
      />
      {/* Результирующая страница */}
      <Route element={<FancyNumberResult />} path={`${baseUrl}${fancyNumberPages.result}`} />
      {/* Default */}
      <Route element={<Navigate replace to={baseUrl} />} path="*" />
    </Routes>
  );
};

export default withReactRouter(FancyNumber2024Router);
