import React from 'react';
import { LandingConstructor } from '@beef/landing-constructor';

import { CardsLayout } from './components/old/CardsLayout';
import { ContactPagePropsType } from './types';

export const ContactPage = ({ data }: ContactPagePropsType) => (
  <LandingConstructor
    {...data}
    customComponents={{
      // eslint-disable-next-line react/no-unstable-nested-components
      CardsLayout: (props: any) => <CardsLayout {...props} />,
    }}
  />
);
