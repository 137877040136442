import { useSelector } from 'react-redux';

import {
  getCurrentNumber,
  getError,
  getIsSuccess,
} from 'pages/FancyNumber2022/store/selectors/change';
import { breakdownNumber } from 'pages/FancyNumber2024/utils/breakdownNumber';
import { getStatusContent } from 'pages/FancyNumber2022/store/selectors/content';
import { useFancyNumberLink } from 'pages/FancyNumber2024/router/hooks/useFancyNumberLink';
import { createSuccessContent } from 'pages/FancyNumber2024/components/ResultContainer/utils/createSuccessContent';
import { createErrorsContent } from 'pages/FancyNumber2024/components/ResultContainer/utils/createErrorsContent';

/** Хук подготовки данных для компонента ResultContainer */
export const useResultContent = () => {
  /** Ссылка для перехода на главную страницу */
  const mainUrl = useFancyNumberLink('main');

  /** Флаг успеха обновления номера телефона */
  const isSuccess = useSelector(getIsSuccess);

  /** Текущая ошибка, возникшая при обновлении номера телефона */
  const currentError = useSelector(getError);

  /** Контентные данные результирующей страницы */
  const { errors, success } = useSelector(getStatusContent);

  /** Текущий выбранный пользователем номер */
  const { value } = useSelector(getCurrentNumber) ?? {};

  /** Форматированный текущий выбранный пользователем номер */
  const newNumber = breakdownNumber(value ? `7${value}` : '');

  /* Результирующий объект с подготовленными данными для результирующей страницы (result) */
  return isSuccess ?
      createSuccessContent({ success, newNumber, mainUrl })
    : createErrorsContent({ errors, currentError, mainUrl });
};
