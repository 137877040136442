import { RootState } from '../../index';

/**
 * Данные для виджета с формой переноса номера (MNPForm)
 */
export const selectMnpFormState = (state: RootState) => state.mnpForm;

/**
 * Тип ошибки полученной после отправки формы для переноса номера.
 * Используется при отображении виджета с ошибкой.
 */
export const selectErrorWidgetType = (state: RootState) =>
  selectMnpFormState(state).errorWidgetType;

/**
 * Флаг для отображения виджета с ошибкой
 */
export const selectIsErrorWidgetShown = (state: RootState) =>
  selectMnpFormState(state).isErrorWidgetShown;

/**
 * Последний номер телефона отправленный с формой переноса номера
 */
export const selectLastSubmittedPhoneNumber = (state: RootState) =>
  selectMnpFormState(state).lastSubmittedPhoneNumber;

/**
 * Номер телефона введенный на форме переноса номера
 */
export const selectPhoneNumber = (state: RootState) => selectMnpFormState(state).phoneNumber;

/**
 * Флаг определяющий, что форма переноса номера находится в процессе отправки
 */
export const selectIsFormSubmitting = (state: RootState) =>
  selectMnpFormState(state).isFormSubmitting;

/**
 * Ошибка отображаемая под инпутом для ввода номера на форме переноса
 */
export const selectPhoneInputError = (state: RootState) =>
  selectMnpFormState(state).phoneInputError;
