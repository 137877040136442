/** адрес для валидации введенного номера */
export const apiValidateMnpOrder = '/customers/products/mobile/services/validatemnporder/';

/** адрес для валидации введенного номера V2 */
export const apiValidateMnpOrderV2 = '/customers/products/mobile/services/api/v2/validatemnporder/';

/** адрес для создания заказа на перенос номера */
export const apiCreateMnpOrder = '/customers/products/mobile/services/createmnporder/';

/** адрес для получения корзины */
export const apiBasket = '/basketapi/api/v1/basket/';
