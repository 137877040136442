import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { Icon, TIcon } from '../Icon';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TContent = {
  subTitle?: string;
  title?: string;
} & TIcon;

/** Контентная часть компонента Status */
export const Content: FC<TContent> = ({ icon, title, subTitle }) => (
  <div className={cx('wrapper')}>
    <Icon {...{ icon }} />
    {title && (
      <Text
        align="center"
        className={cx('wrapper__title')}
        color="primary"
        lineHeight={24}
        size="m"
      >
        {title}
      </Text>
    )}
    {subTitle && (
      <Text align="center" color="secondary" lineHeight={26} size="s">
        {subTitle}
      </Text>
    )}
  </div>
);
