import React, { FC, PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

type FadeAnimationProps = PropsWithChildren<{
  className?: string;
}>;

/*
 * Компонент-обёртка для анимации смены блока
 */
export const FadeAnimation: FC<FadeAnimationProps> = ({ children, className }) => (
  <motion.div
    animate={{ opacity: 1 }}
    className={className}
    exit={{ opacity: 0 }}
    initial={{ opacity: 0 }}
  >
    {children}
  </motion.div>
);
