/** Доступные роуты в FancyNumber2024 */
export const fancyNumberPages = {
  main: 'main',
  conditions: 'conditions',
  confirmation: 'confirmation',
  attention: 'attention',
  result: 'result',
  redirect: 'test-redirect',
} as const;

/** Функция определения пути для Route */
export const fancyNumberSubPathes = Object.keys(fancyNumberPages)
  .filter((key) => key !== fancyNumberPages.main)
  .map((key) => fancyNumberPages[key as keyof typeof fancyNumberPages]);
