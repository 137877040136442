import React, { FC } from 'react';
// @ts-expect-error ошибка импорта типа из carnica
import { Text, TextPropAlign } from '@carnica/ui-kit';
import classnames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export type TConfirmationContent = {
  pageTitle?: string;
  pageTitleSettings?: {
    lineHeight: number;
    size: string;
  };
  subTitle?: string;
  subTitleSettings?: {
    align: TextPropAlign;
  };
};

/** Компонент заголовка и описания для ConfirmationContainer */
export const ConfirmationContent: FC<TConfirmationContent> = ({
  pageTitle,
  pageTitleSettings,
  subTitleSettings,
  subTitle,
}) => (
  <div className={cx('wrapper')}>
    {pageTitle && (
      <Text align="left" color="primary" tagName="h1" {...pageTitleSettings}>
        {pageTitle}
      </Text>
    )}
    {subTitle && (
      <Text color="secondary" lineHeight={26} size="s" tagName="p" {...subTitleSettings}>
        {subTitle}
      </Text>
    )}
  </div>
);
