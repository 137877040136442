import React from 'react';
import { Button, Title } from '@carnica/ui-kit';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { IconCheckMarkCheck, IconCloseRoundStroked } from '@carnica/graphics';
import { compose } from '@beef/redux';
import { withRenderFlag } from '@beef/react';
import { IconComponent } from '@carnica/graphics/types';

import {
  selectVolunteersConfirmationModal,
  selectVolunteersFormContent,
} from 'pages/LizaAlertLandingPage/store/selectors';
import {
  EConfirmationModalStep,
  refreshStatus,
} from 'pages/LizaAlertLandingPage/store/volunteerFormSlice';
import { AppDispatch, RootState } from 'pages/LizaAlertLandingPage/store';
import { TStatus } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/ConfirmationModal/components/Status/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const StatusIcons: Record<string, IconComponent> = {
  success: IconCheckMarkCheck,
  error: IconCloseRoundStroked,
};

const _Status = ({ status, statusTexts, onButtonClick }: TStatus) => {
  const StatusIcon = StatusIcons[status];

  return (
    <div className={cn('wrapper')}>
      <div className={cn('icon', status)}>
        <StatusIcon color="white" size={60} />
      </div>
      <Title
        align="center"
        size="s"
        subTitle={statusTexts?.[status].subtitle}
        title={statusTexts?.[status].title}
      />
      <Button className={cn('button')} onClick={onButtonClick} size="l" width="full">
        {statusTexts?.[status].buttonText}
      </Button>
    </div>
  );
};

export const Status = compose<React.FC<TStatus>>(
  connect(
    (state: RootState) => ({
      status: selectVolunteersConfirmationModal(state).step,
      statusTexts: selectVolunteersFormContent(state).confirmationModal.status,
      shouldRender: selectVolunteersConfirmationModal(state).step !== EConfirmationModalStep.code,
    }),
    (dispatch: AppDispatch) => ({
      onButtonClick: () => dispatch(refreshStatus()),
    }),
  ),
  withRenderFlag,
)(_Status);
