import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { IInputFieldsProps } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/types';
import { setFilterQuery } from 'pages/FancyNumber2022/store/actions/filter';
import { useInputFields } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/components/InputFields/hook';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const MAX_LENGTH_NUMBER = 7;

// TODO: переписать компонент при рефакторинге
const _InputFields = memo<IInputFieldsProps>(
  ({
    centeredInputRef,
    inputDataFields,
    locked,
    hasAtLeastOneFilledInputCell,
    inputRefs,
    initialInputDataFields,
    position,
    originalInputDataFields,
    setOriginalInputDataFields,
    onSetFilterQuery,
    setSecondInputValue,
    secondInputValue,
  }) => {
    const {
      handleChangeCenteredInput,
      handleOnFocusInput,
      handleKeyDown,
      handleOnChange,
      shouldInputBeDisabled,
    } = useInputFields({
      position,
      originalInputDataFields,
      setOriginalInputDataFields,
      onSetFilterQuery,
      setSecondInputValue,
      inputRefs,
      locked,
      initialInputDataFields,
      inputDataFields,
      hasAtLeastOneFilledInputCell,
    });

    return (
      <div className={cx('input-field')}>
        {!locked ?
          <div className={cx('input-wrap')}>
            <input
              className={cx('second-input')}
              disabled={locked}
              maxLength={MAX_LENGTH_NUMBER}
              onChange={handleChangeCenteredInput}
              onFocus={() => centeredInputRef?.current?.focus()}
              /* eslint-disable-next-line no-return-assign */
              ref={(el: HTMLInputElement) => (centeredInputRef.current = el)}
              type="text"
              value={secondInputValue}
            />
            <span className={cx('input-dot')} />
          </div>
        : inputDataFields.map((field, index) => {
            const originalIndex = index - position;
            return (
              <div
                className={cx('input-wrap', {
                  'input-wrap--filled': hasAtLeastOneFilledInputCell,
                })}
                key={field.id}
              >
                <input
                  className={cx('input', {
                    'input--filled': hasAtLeastOneFilledInputCell,
                  })}
                  disabled={!(locked || shouldInputBeDisabled(index, originalIndex))}
                  max={field.max}
                  onChange={(e) => handleOnChange(e, index)}
                  onFocus={() => handleOnFocusInput(index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  placeholder={field.placeholder}
                  ref={(el: HTMLInputElement | null) => {
                    if (inputRefs && inputRefs.current) {
                      inputRefs.current[index] = el;
                    }
                  }}
                  type="number"
                  value={field.value}
                />

                <span className={cx('input-dot')} />
                <span
                  className={cx('below-input-dot', {
                    'below-input-dot--filled': hasAtLeastOneFilledInputCell,
                  })}
                />
              </div>
            );
          })
        }
      </div>
    );
  },
);

const mapDispatchToProps = {
  onSetFilterQuery: setFilterQuery,
};

export const InputFields = connect(null, mapDispatchToProps)(_InputFields);
