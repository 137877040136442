import React from 'react';
import { ECaptchaKeys } from '@beef/captcha';

import { BlockWrapperRaw as BlockWrapper } from 'pages/LizaAlertLandingPage/components/BlockWrapper';
import { ColumnContainerRaw as ColumnContainer } from 'pages/LizaAlertLandingPage/components/ColumnContainer';
import { RowContainerRaw as RowContainer } from 'pages/LizaAlertLandingPage/components/RowContainer';
import { FieldsWrapper } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/FieldsWrapper';
import {
  AddLocationButton,
  ConsentCheckbox,
  ConsentText,
  DistrictCheckbox,
  DistrictList,
  HelpTypeCheckbox,
  HelpTypesHeading,
  LastNameInput,
  NameInput,
  PhoneNumberInput,
  RegionDistrictCheckbox,
  RegionGroupList,
  SubmitButton,
  VolunteersFinalForm,
  VolunteersFormHeading,
  YandexCaptcha,
  YourContactsHeading,
} from 'pages/LizaAlertLandingPage/components/VolunteersForm/connected';
import { HelpTypesList } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/HelpTypesList';
import { LocationSelectMapper } from 'pages/LizaAlertLandingPage/store/selectors';
import { RegionGroup } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/RegionGroup';
import { ConfirmationModal } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/ConfirmationModal';
import { LocationSelect } from 'pages/LizaAlertLandingPage/components/VolunteersForm/components/LocationSelect';

export const VolunteersFormDesktop = () => (
  <BlockWrapper withIndent withPadding>
    <VolunteersFormHeading indent="m" />
    <VolunteersFinalForm>
      <RowContainer gap="xl">
        <ColumnContainer>
          <HelpTypesHeading />
          <FieldsWrapper gap="m">
            <HelpTypesList>
              <HelpTypeCheckbox />
            </HelpTypesList>
          </FieldsWrapper>
        </ColumnContainer>
        <ColumnContainer>
          <YourContactsHeading />
          <RowContainer gap="s">
            <FieldsWrapper gap="s">
              <NameInput />
              <LastNameInput />
              <PhoneNumberInput />
              <LocationSelectMapper>
                <LocationSelect removeButtonView="secondary" />
                <RegionGroupList>
                  <RegionGroup>
                    <RegionDistrictCheckbox />
                  </RegionGroup>
                </RegionGroupList>
                <DistrictList>
                  <DistrictCheckbox />
                </DistrictList>
              </LocationSelectMapper>
              <AddLocationButton view="secondary" />
              <RowContainer gap="m">
                <ConsentCheckbox />
                <ConsentText />
              </RowContainer>
            </FieldsWrapper>
            <SubmitButton />
          </RowContainer>
        </ColumnContainer>
      </RowContainer>
    </VolunteersFinalForm>
    <YandexCaptcha clientKey={ECaptchaKeys.LizaAlert} />
    <ConfirmationModal />
  </BlockWrapper>
);
