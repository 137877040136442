import { CATEGORY_ALIAS } from 'pages/FancyNumber2022/constants';
import { MIN_PRICE_TEXT } from 'pages/FancyNumber2024/constants';

import { getNumberContent } from './getNumberContent';

/** Функция получения текста в кнопку чека на базе category */
export const getBtnText = (typeNumbers: Record<string, any>, category: string, price: number) => {
  /** Текущий контент для кнопки на базе category */
  const contentBtnText = getNumberContent(typeNumbers, category, 'btnText');

  /* Если текущая категория "бесплатные" (spetsialnyie), просто возвращаем контентный текст */
  if (category === CATEGORY_ALIAS.spetsialnyie) {
    return contentBtnText;
  }

  return price ? contentBtnText?.replace('{{price}}', price) : MIN_PRICE_TEXT;
};
