import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import store from 'store';
import { breakdownNumber } from 'pages/FancyNumber2024/utils/breakdownNumber';
import { getCurrentNumber } from 'pages/FancyNumber2022/store/selectors/change';
import { getStatusContent } from 'pages/FancyNumber2022/store/selectors/content';
import { useFancyNumberLink } from 'pages/FancyNumber2024/router/hooks/useFancyNumberLink';

import { EIconsUrl } from '../constants';
import { createAttentionTexts } from '../utils/createAttentionTexts';

/** Хук подготовки данных для страницы предупреждения */
export const useAttentionContent = () => {
  const navigate = useNavigate();

  /** Ссылка для перехода на страницу подтверждения обновления номера */
  const confirmationUrl = useFancyNumberLink('confirmation');

  /** Контентные данные страницы "предупреждения" (attention) */
  const {
    attention: { title, subTitle, buttons },
  } = useSelector(getStatusContent);

  /** Текущий выбранный пользователем номер */
  const { value } = useSelector(getCurrentNumber) ?? {};

  /** Форматированный текущий выбранный пользователем номер */
  const newNumber = breakdownNumber(value ? `7${value}` : '');

  /** Номер телефона авторизованного пользователя из global store */
  const { accountText: connectedNumber } = store.getState().external?.user ?? {};

  /* Готовим результирующий объект с подготовленными данными для страницы с типом 'attention', либо 'result' */
  return {
    /* Текущая иконка (информационное предупреждение) */
    icon: EIconsUrl.warning,
    /* Функция-обработчик главной кнопки */
    onAccept: () => navigate(confirmationUrl),
    /* Подготовленный заголовок и описание для страницы (создается через локальную функцию createAttentionTexts) */
    ...createAttentionTexts(title, subTitle, connectedNumber, newNumber),
    /* Текста для кнопок */
    ...buttons,
  };
};
