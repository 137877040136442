import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { CodeInput } from '@carnica/ui-kit';

import { CustomPreloader } from 'pages/FancyNumber2024/components/CustomPreloader';

import { useSMSContainer } from './hooks/useSMSContainer';
import { DEFAULT_CODE_LENGTH } from '../../constants';
import { BackButton } from '../BackButton';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export type TSMSContainer = {
  codeLength?: number;
  timerSettings?: {
    timerBtnText?: string;
    timerText?: string;
  };
};

/** Компонент-обертка над блоком подтверждения обновления номера через SMS */
export const SMSContainer: FC<TSMSContainer> = ({
  timerSettings,
  codeLength = DEFAULT_CODE_LENGTH,
}) => {
  /* Готовим данные для компонента SMSContainer */
  const { isLoadingSms, codeInputProps } = useSMSContainer(timerSettings);

  return (
    <section className={cx('wrapper')}>
      <BackButton />
      <div className={cx('wrapper__code')}>
        {isLoadingSms ?
          <CustomPreloader />
        : <CodeInput {...{ codeLength, ...codeInputProps }} />}
      </div>
    </section>
  );
};
