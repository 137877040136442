import { createContext } from 'react';

export const TrustPaymentContext = createContext({
  content: {
    banner: {},
    infoCard: [],
    isAvailableToConnect: false,
    trustedPaymentInfo: {},
  },
  isAuthenticated: false,
  isConnected: false,
});
