import { axiosBeelineRequest } from '@beef/utils';

import { apiValidateMnpOrder } from './constants';

export interface IResponseValidateMNPOrder {
  Errors: Record<string, unknown>;
  IsSucceeded: boolean;
  View: {
    IsExistingCtn: boolean;
  };
  camelCase: boolean;
}

/**
 * Валидация введенного номера методом 'validatemnporder'
 */
export const validateMNPOrder = async (phoneNumber: string) => {
  try {
    return await axiosBeelineRequest<IResponseValidateMNPOrder>({
      method: 'post',
      url: apiValidateMnpOrder,
      data: {
        phone: phoneNumber,
      },
    });
  } catch (error) {
    return String(error);
  }
};
