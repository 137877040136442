import React, { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const BlockWrapper: React.FC<{ withPadding?: boolean } & PropsWithChildren> = ({
  withPadding,
  children,
}) => {
  return (
    <div className={cn('block-wrapper', { 'block-wrapper--with-padding': withPadding })}>
      {children}
    </div>
  );
};
