import React from 'react';
import classNames from 'classnames/bind';
import { Avatar, Text } from '@carnica/ui-kit';
import { IconArrowsRight, IconMarkFilled } from '@carnica/graphics';
import { withDotSeparatedProps } from '@beef/landing-constructor/library-utils';
import PropTypes from 'prop-types';

import withContent from 'pages/ContactPage/utils/withContent';
import { Metric } from 'pages/ContactPage/metric';
import { MenuUrlTarget } from 'pages/ContactPage/utils/utils';

import { TBanner } from './types';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

const _Banner = ({
  href,
  title,
  description,
  backgroundImage,
  backgroundSize = 'contain',
  backgroundPosition = 'center right',
  isBlankUrlTarget,
}: TBanner) => {
  return (
    <Metric
      asChild
      data={title}
      events={{ contactPageMetric: ['handleBannerClick'] }}
      key={title}
      mode="click"
    >
      <a
        className={cn('wrapper')}
        href={href}
        style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize, backgroundPosition }}
        target={MenuUrlTarget.get(isBlankUrlTarget as boolean)}
      >
        <Avatar bgColor="invert" className={cn('mark-icon')} icon={IconMarkFilled} size="m" />
        <Text className={cn('title')} variant="body-small">
          {title}
        </Text>
        <div className={cn('description-wrapper')}>
          <Text className={cn('description')} color="secondary" variant="caption-medium">
            {description}
          </Text>
          <Avatar bgColor="invert" className={cn('arrow-icon')} icon={IconArrowsRight} size="m" />
        </div>
      </a>
    </Metric>
  );
};

export const Banner = withDotSeparatedProps(withContent(_Banner), {
  href: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundSize: PropTypes.string,
  backgroundPosition: PropTypes.string,
  isBlankUrlTarget: PropTypes.string,
});
