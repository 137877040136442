import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import { Accordion, Text } from '@carnica/ui-kit';
import { IconChevronUp } from '@carnica/graphics';

import { Metric } from 'pages/MNPLanding/analytics';

import styles from './styles.pcss';
import { useFAQSection } from './hooks/useFAQSection';

const cx = classNames.bind(styles);

/**
 * Блок FAQ
 */
export const FAQSection: FC = memo(() => {
  const { tabs, title, handleSendFAQAnalytics, titleVariant, isMobile } = useFAQSection();

  return (
    <Metric asChild events={{ yaMNPMetric: ['handleViewFAQBlock'] }} mode="viewport">
      <section className={cx('section')}>
        <Text align="left" className={cx('title')} variant={titleVariant}>
          {title}
        </Text>

        <div className={cx('accordion-wrapper')}>
          {tabs.map((tab) => (
            <Accordion
              className={cx('accordion')}
              closeIcon={IconChevronUp}
              key={tab.id}
              label={tab.title}
              onLabelClick={(flag) => handleSendFAQAnalytics(flag, tab.title)}
              size={isMobile ? 's' : 'l'}
            >
              {tab.description}
            </Accordion>
          ))}
        </div>
      </section>
    </Metric>
  );
});
