import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { enhancedConnect } from 'utils/lowCodeHelpers';
import { TBlockWrapper } from 'pages/LizaAlertLandingPage/components/BlockWrapper/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const BlockWrapperRaw = ({ children, withPadding, withIndent }: TBlockWrapper) => {
  return (
    <div
      className={cx('wrapper', {
        'wrapper--with-padding': withPadding,
        'wrapper--with-indent': withIndent,
      })}
    >
      {children}
    </div>
  );
};

export const BlockWrapper = enhancedConnect({
  children: PropTypes.node,
  withPadding: PropTypes.bool,
  withIndent: PropTypes.bool,
})(BlockWrapperRaw);
