import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { motion } from 'framer-motion';

import { useAppSelector } from 'pages/MNPLanding/store';
import { selectIsMnpTariffEnabled } from 'pages/MNPLanding/store/slices/commonSlice/selectors';

import styles from './styles.pcss';
import { HowItWorksBlock } from './components/HowItWorksBlock';
import { BenefitsBlock } from './components/BenefitsBlock';
import { AdditionalInfoBlock } from './components/AdditionalInfoBlock';
import { BriefInfo } from '../MNPForm/components/BriefInfo';

const cx = classNames.bind(styles);

/**
 * Блок с информацией о том, как работает услуга MNP
 */
export const InfoAboutServiceSection: FC = () => {
  const isMnpTariffEnabled = useAppSelector(selectIsMnpTariffEnabled);

  return (
    <motion.section
      className={cx('section-wrapper')}
      id="infoAboutServiceSection"
      layout="position"
      transition={{ duration: 0.6, type: 'spring' }}
    >
      <div className={cx('content-wrapper')}>
        {/* Краткая информация о переносе номера */}
        {isMnpTariffEnabled && <BriefInfo />}

        {/* Блок "как работает перенос */}
        <HowItWorksBlock />

        {/* Блок "в билайне можно все */}
        <BenefitsBlock />

        {/* Блок с карточками дополнительной информации */}
        <AdditionalInfoBlock />
      </div>
    </motion.section>
  );
};
