import { withDotSeparatedProps } from '@beef/landing-constructor/library-utils';
import PropTypes from 'prop-types';

import { ChatBlock as _ChatBlock } from './ChatBlock';
import { CardAction as _CardAction } from './components/CardAction';
import { CardHeading as _CardHeading } from './components/CardHeading';

export const ChatBlock = withDotSeparatedProps(_ChatBlock, {
  children: PropTypes.node,
  className: PropTypes.string,
  floatingImageUrl: PropTypes.string,
});

export const CardAction = withDotSeparatedProps(_CardAction, {
  text: PropTypes.string,
});

export const CardHeading = withDotSeparatedProps(_CardHeading, {
  text: PropTypes.string,
  statusLabel: PropTypes.string,
  statusDotColor: PropTypes.string,
  statusTextColor: PropTypes.string,
  textColor: PropTypes.string,
});
