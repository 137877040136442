import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { HeaderPropsType } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

// TODO: переписать компонент при рефакторинге
export const Header = memo<HeaderPropsType>(({ title }) => (
  <div className={cx('header')}>
    <Text className={cx('header-title')} color="white" size="size2-m" tagType="h2">
      {title}
    </Text>
  </div>
));
