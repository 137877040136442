import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { InputBlock } from '../InputBlock';
import { FadeAnimation } from '../FadeAnimation';
import { BriefInfo } from '../BriefInfo';

const cx = classNames.bind(styles);

/**
 * Виджет переноса номера по умолчанию (без отображения тарифа)
 */
export const DefaultMnpWidget: FC = memo(() => (
  <FadeAnimation>
    {/* Блок с формой переноса номера */}
    <InputBlock className={cx('input-block')} id="mnp-widget-input-block" />
    {/* Краткая информация о переносе номера */}
    <BriefInfo />
  </FadeAnimation>
));
