import { useCallback, useMemo } from 'react';
import { TextPropVariant } from '@carnica/ui-kit/components/Text/types';
import { useIsMobileDimension } from '@carnica/utils/hooks';

import { useAppSelector } from 'pages/MNPLanding/store';
import {
  selectIsMnpTariffEnabled,
  selectMainContent,
} from 'pages/MNPLanding/store/slices/commonSlice/selectors';
import { useIsLargeDesktopScreen } from 'pages/MNPLanding/hooks/media/useIsLargeDesktopScreen';

/**
 * Хук для контента стартового экрана лендинга
 */
export const useStartSectionContent = () => {
  const mainContent = useAppSelector(selectMainContent);
  const isMnpTariffEnabled = useAppSelector(selectIsMnpTariffEnabled);

  const isMobile = useIsMobileDimension();
  const isLargeDesktop = useIsLargeDesktopScreen();

  const titleVariant: TextPropVariant = useMemo(() => {
    let variant = 'display-medium';

    if (isMobile) {
      variant = 'display-small';
    }

    if (isLargeDesktop) {
      variant = 'display-large';
    }

    return variant;
  }, [isMobile, isLargeDesktop]);

  const handleScrollToInfoAboutServiceSection = useCallback(() => {
    const element = document?.getElementById('howItWorks');

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  return {
    ...mainContent,
    handleScrollToInfoAboutServiceSection,
    isMobile,
    isLargeDesktop,
    titleVariant,
    isMnpTariffEnabled,
  };
};
