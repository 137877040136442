import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { useIsMobileDimension } from '@carnica/utils/hooks';

/** TODO Проверить можно ли отказаться от компонента Anchor */
import { Anchor } from 'pages/MNPLanding/components/Anchor';
import { Metric } from 'pages/MNPLanding/analytics';
import { useIsLargeDesktopScreen } from 'pages/MNPLanding/hooks/media/useIsLargeDesktopScreen';

import styles from './styles.pcss';
import { useHowItWorksBlock } from './hooks/useHowItWorksBlock';
import { Step } from './components/Step';

const cx = classNames.bind(styles);

/**
 * Блок "как работает перенос"
 */
export const HowItWorksBlock: FC = () => {
  const { anchorId, items, title } = useHowItWorksBlock();
  const isMobile = useIsMobileDimension();
  const isLargeDesktop = useIsLargeDesktopScreen();

  return (
    <Metric data={title} events={{ yaMNPMetric: ['handleViewHowItWorksBlock'] }} mode="viewport">
      <Anchor anchorId={anchorId} />

      <div className={cx('wrapper')}>
        <Text
          align={isMobile || isLargeDesktop ? 'left' : 'center'}
          variant={isMobile ? 'display-small' : 'display-medium'}
        >
          {title}
        </Text>

        {items.map((step) => (
          <Step key={step.id} {...step} />
        ))}
      </div>
    </Metric>
  );
};
