import { useState } from 'react';
import { useTimer } from '@carnica/utils/hooks';

import {
  DEFAULT_END_TIMER,
  DEFAULT_INTERVAL_TIMER,
  DEFAULT_START_TIMER,
} from 'pages/FancyNumber2024/components/ConfirmationContainer/constants';

/** Хук подготовки параметров таймера отправки SMS-кода */
export const useTimerOptions = () => {
  /** State для работы с китовой утилитой useTimer */
  const [time, setTime] = useState(DEFAULT_START_TIMER);

  /** Китовый таймер с настройками */
  const { start, reset, isRunning } = useTimer({
    endTime: DEFAULT_END_TIMER,
    startTime: DEFAULT_START_TIMER,
    interval: DEFAULT_INTERVAL_TIMER,
    onInterval: setTime,
    timerType: 0,
  });

  return { start, reset, isRunning, time, setTime };
};
