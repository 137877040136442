import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ProductTile } from '@beef/legacy-ui-kit';
import { connect } from 'react-redux';
import { isPlainObject } from '@beef/utils';

import { withBeelineStore } from 'store-enhancers/withBeelineStore';
import ProductsFamilyTile from 'pages-components/ProductsFamilyTile/ProductsFamilyTile';
import { ymPushParams } from 'utils/ym';

import ServiceTile from './ServiceTile';
import {
  convertServiceContextInfoToBySocMap,
  getServiceInfoContext,
  getTrustPaymentContextInfo,
  isTrustPayment,
} from './utils';
import { useFetchServiceContextInfo } from './hooks/useFetchServiceContextInfo';

const serviceInfoContextCases = [[isTrustPayment, getTrustPaymentContextInfo]];

const _ServicesGrid = ({ services, className, id, onClick, isAuthenticated }) => {
  const servicesInfoContexts = useFetchServiceContextInfo(isAuthenticated);
  const servicesInfoContextsMap = convertServiceContextInfoToBySocMap(servicesInfoContexts);
  const getServiceInfoContextBySoc = getServiceInfoContext({
    cases: serviceInfoContextCases,
    servicesInfoContextsMap,
  });

  return (
    <div className={className} id={id}>
      {services.map((service, index) => (
        <Fragment key={index}>
          {service.type === 'family' && (
            <>
              {service?.showBenefits && (
                <ProductsFamilyTile
                  data={service}
                  itemType="service"
                  key={`familyTile-${index}`}
                  onClick={(link, serviceId) => onClick && onClick(link, serviceId)}
                />
              )}
              {!service?.showBenefits && service.services.length > 1 && (
                <ProductTile
                  description={service.description}
                  family={service.services}
                  forArchiveTariff={service.forArchiveTariff}
                  itemType="service"
                  key={`productTile-${index}`}
                  onClick={(link, serviceId) => onClick && onClick(link, serviceId)}
                  title={service.familyTitle}
                />
              )}
              {!service?.showBenefits && !(service.services.length > 1) && (
                <ServiceTile
                  key={`serviceTile-${index}`}
                  onClick={(link, serviceId) => onClick && onClick(link, serviceId)}
                  service={{
                    ...service.services[0],
                    hideSubscribeButton: service.hideSubscribeButton,
                  }}
                  serviceInfoContext={getServiceInfoContextBySoc(service.soc)}
                />
              )}
            </>
          )}
          {service.type === 'service' && (
            <ServiceTile
              key={`serviceTile-F${index}`}
              onClick={(link, serviceId) => {
                if (service.alias === 'vowifi') {
                  ymPushParams({ vowifi: 'more_info' });
                }
                if (onClick) {
                  onClick(link, serviceId);
                }
              }}
              service={service}
              serviceInfoContext={getServiceInfoContextBySoc(service.soc)}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

_ServicesGrid.propTypes = {
  className: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  services: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string,
  onClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isAuthenticated: isPlainObject(state.external.user),
});

export default withBeelineStore(connect(mapStateToProps)(_ServicesGrid));
