import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Accordion, Text } from '@carnica/ui-kit';

import { enhancedConnect } from 'utils/lowCodeHelpers';
import { TFaqItem } from 'pages/LizaAlertLandingPage/components/FaqItem/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const _FaqItem = ({ title, text }: TFaqItem) => {
  return (
    <Accordion
      key={title}
      label={
        <Text className={cn('title')} color="primary" variant="display-extrasmall">
          {title}
        </Text>
      }
    >
      <Text className={cn('text')} color="secondary" tagName="span" variant="body-paragraph-small">
        {text}
      </Text>
    </Accordion>
  );
};

export const FaqItem = enhancedConnect({
  title: PropTypes.string,
  text: PropTypes.string,
})(_FaqItem);
