import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@carnica/ui-kit';

import { PageContainer } from 'pages/FancyNumber2024/components/PageContainer';

import { useErrorBoundaryFallback } from './hooks/useErrorBoundaryFallback';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент общей ошибки */
export const ErrorBoundaryFallback: FC = () => {
  /* Готовим данные для компонента ErrorBoundaryFallback */
  const { title, description, image, btnText, onReload } = useErrorBoundaryFallback();

  return (
    <PageContainer>
      <div className={cx('wrapper')}>
        {image && <img alt="" className={cx('wrapper__image')} src={image} />}
        <div className={cx('wrapper__content')}>
          {title && (
            <Text color="primary" size="l">
              {title}
            </Text>
          )}
          {description && (
            <Text color="secondary" size="s">
              {description}
            </Text>
          )}
        </div>
        {btnText && (
          <div className={cx('wrapper__button')}>
            <Button onClick={onReload} width="full">
              {btnText}
            </Button>
          </div>
        )}
      </div>
    </PageContainer>
  );
};
