/** Высота хэдера при скролле страницы */
const HEADER_HEIGHT = 76;

interface IOptions {
  /** Условие для проверки на полную видимость элемента */
  checkFullVisibility?: boolean;
}

/** Находит элемент по id и скроллит страницу до него */
export const scrollElementIntoView = (
  elementId: string,
  { checkFullVisibility = true }: IOptions = {},
) => {
  const element = document.getElementById(elementId);

  if (!element) {
    return;
  }

  const scrollToElement = () => {
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  /** Если нужно проверить, что элемент виден полностью */
  if (checkFullVisibility) {
    /** Получаем информацию о расположении элемента относительно viewport */
    const elementRect = element.getBoundingClientRect();

    /** Скроллим если элемент не виден полностью */
    if (elementRect.top < HEADER_HEIGHT || elementRect.bottom > window.innerHeight) {
      scrollToElement();
    }

    return;
  }

  /** Скроллим во всех остальных случаях */
  scrollToElement();
};
