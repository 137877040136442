import React from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const QRCode: React.FC<{
  className?: string;
  qrImageUrl: string;
  qrLabel: string;
}> = ({ qrLabel, qrImageUrl, className }) => (
  <div className={cx('qr-wrapper', className)}>
    <img alt="" className={cx('qr-image')} src={qrImageUrl} />
    <Text align="center" className={cx('qr-label')} color="tertiary" variant="body-small">
      {qrLabel}
    </Text>
  </div>
);
