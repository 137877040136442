import React, { memo, useMemo } from 'react';
import { RootStateOrAny, connect } from 'react-redux';
import classnames from 'classnames/bind';

import { NumberSelectionWidget } from 'pages/FancyNumber2024/components/NumberSelectionWidget';
import { CloudsCarousel } from 'pages/FancyNumber2024/components/CloudsCarousel';
import { NumbersBlock } from 'pages/FancyNumber2024/components/NumbersBlock';
import { NotAuthBlock } from 'pages/FancyNumber2024/components/NotAuthBlock';
import { selectTabId } from 'pages/FancyNumber2022/store/selectors/content';
import { TABS_ALIAS } from 'pages/FancyNumber2024/constants';
import { CustomSmartButton } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/CustomSmartButton';
import { useMainLayoutHook } from 'pages/FancyNumber2024/components/MainLayout/hook';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

type MainLayoutPropsType = {
  tabId: string;
};

// TODO: переписать компонент при рефакторинге
const _MainLayout = memo<MainLayoutPropsType>(({ tabId }) => {
  const {
    handleContainerRef,
    handleWidgetRef,
    isWidgetVisible,
    isButtonFixed,
    isMobile,
    isTablet,
    isDesktop,
    isAuthenticated,
    isShowBtn,
    block,
  } = useMainLayoutHook({ tabId });

  // необходимо чтобы не сбрасывалось состояние раскрытых номеров, когда кнопка появляется при мобильной версии после виджета
  const MemoizedNotAuthBlock = React.memo(NotAuthBlock);
  const MemoizedNumbersBlock = React.memo(NumbersBlock);

  const ChosenComponent = useMemo(() => {
    return block === TABS_ALIAS.change ? <MemoizedNotAuthBlock /> : <MemoizedNumbersBlock />;
  }, [block]);

  const isTabletOrMobileView = isMobile || isTablet;

  return (
    <div className={cx('wrapper')}>
      {isDesktop && (
        <section className={cx('content-block')}>
          <div className={cx('cloud-block')}>
            <CloudsCarousel />
          </div>

          <div
            className={cx('content-layout', {
              'content-layout--noAuth': !isAuthenticated && tabId === TABS_ALIAS.change,
            })}
          >
            {ChosenComponent}

            <aside
              className={cx('aside-widget', {
                'aside-widget--noAuth-change-tab': !isAuthenticated && tabId === TABS_ALIAS.change,
              })}
            >
              <NumberSelectionWidget />
            </aside>
          </div>
        </section>
      )}

      {isTabletOrMobileView && (
        <section className={cx('content-block')}>
          <div className={cx('cloud-block')}>
            <CloudsCarousel />
          </div>

          <aside
            className={cx('aside-widget', {
              'aside-widget--noAuth': !isAuthenticated,
            })}
            ref={handleWidgetRef}
          >
            <NumberSelectionWidget />
          </aside>

          <div
            className={cx('content-layout', {
              'content-layout--noAuth': !isAuthenticated && tabId === TABS_ALIAS.change,
            })}
            ref={handleContainerRef}
          >
            {ChosenComponent}
          </div>

          {!isWidgetVisible && isShowBtn && !isTablet && (
            <div
              className={cx('mobile-btn-block', {
                'mobile-btn-block--visible': !isWidgetVisible,
              })}
              style={{
                position: isButtonFixed ? 'fixed' : 'absolute',
                bottom: isButtonFixed ? '30px' : '-28px',
                zIndex: 5,
              }}
            >
              <CustomSmartButton />
            </div>
          )}
        </section>
      )}
    </div>
  );
});

const mapStateToProps = (state: RootStateOrAny) => ({
  tabId: selectTabId(state),
});

export const MainLayout = connect(mapStateToProps)(_MainLayout);
