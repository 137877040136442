import React, { FC } from 'react';
import classnames from 'classnames/bind';

import { LottiePlayer } from 'pages/CarnicaDSLandings/ui';

import { useLottieAnimation } from './hooks/useLottieAnimation';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

/** Компонент-обертка с lotty анимацией */
export const LottieAnimation: FC = () => {
  /** Готовим данные для LottiePlayer */
  const { containerRef, lottyPlayerSettings } = useLottieAnimation();

  return (
    <div className={cx('lottie-container')} ref={containerRef}>
      <LottiePlayer renderer="svg" {...lottyPlayerSettings} />
    </div>
  );
};
