import { EIconsUrl } from 'pages/FancyNumber2024/components/AttentionContainer/constants';
import { resultHandleClick } from 'pages/FancyNumber2024/components/ResultContainer/utils/resultHandleClick';
import { createSuccessTitle } from 'pages/FancyNumber2024/components/ResultContainer/utils/createSuccessTitle';

/** Функция формирования контента для страницы успеха */
export const createSuccessContent = ({
  success,
  mainUrl = '',
  newNumber,
}: {
  mainUrl: string;
  newNumber?: string;
  // TODO: типизировать после реализации отдельного store
  success: Record<any, any>;
}) => {
  /** Текущий контент успеха */
  const { title, subTitle, buttons, acceptLink, declineLink } = success ?? {};

  return {
    /* Текущая иконка (успех) */
    icon: EIconsUrl.success,
    /* Подготовленный заголовок для страницы (создается через локальную функцию createSuccessTitle) */
    title: createSuccessTitle(title, newNumber),
    /* Описание для страницы */
    subTitle,
    /* Функция-обработчик главной кнопки */
    onAccept: () => resultHandleClick({ link: acceptLink, mainUrl }),
    /* Функция-обработчик дополнительной кнопки */
    onDecline: () => resultHandleClick({ link: declineLink, mainUrl }),
    /* Текста для кнопок */
    ...buttons,
  };
};
