import React, { FC } from 'react';
import { compose } from '@beef/redux';
import { motion } from 'framer-motion';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { cnTheme } from '@carnica/themes';
import { ErrorBoundary, withMountListener } from '@beef/react';

import FancyNumber2024Router from 'pages/FancyNumber2024/router';
import { selectBaseUrl } from 'pages/FancyNumber2022/store/selectors/content';
import { useICustomHeaderTheme } from 'pages/FancyNumber2024/hooks/useICustomHeaderTheme';
import { ErrorBoundaryFallback } from 'pages/FancyNumber2024/components/ErrorBoundaryFallback';
import { useHeaderHeightCompensation } from 'pages/FancyNumber2024/hooks/useHeaderHeightCompensation';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

/** Основная задача: BCNC-6706.
 * Главный компонент FancyNumberLayout2024 красивых номеров */
const _FancyNumberLayout2024: FC = () => {
  /** Базовый URL страницы FancyNumber2024 */
  const baseUrl = useSelector(selectBaseUrl);

  /** TODO: Заменить на useInitialHeaderTheme после обновления до 24.20 */
  useICustomHeaderTheme();

  const { animate, transition } = useHeaderHeightCompensation();

  return (
    <motion.section animate={animate} className={cx('wrapper', cnTheme())} transition={transition}>
      {/* @ts-expect-error неверная типизация ErrorBoundary */}
      <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
        <FancyNumber2024Router {...{ baseUrl }} />
      </ErrorBoundary>
    </motion.section>
  );
};

export const FancyNumber2024 = compose(withMountListener)(_FancyNumberLayout2024);
