import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button, Input } from '@carnica/ui-kit';

import { Metric } from 'pages/MNPLanding/analytics';

import styles from './styles.pcss';
import { useMnpFormData } from './hooks/useMnpFormData';
import { usePhoneInput } from './hooks/usePhoneInput';
import { useMnpFormSubmit } from './hooks/useMnpFormSubmit';

const cx = classNames.bind(styles);

/**
 * Форма переноса номера
 */
export const InputForm: FC = () => {
  const { submitMnpForm, isFormSubmitting } = useMnpFormSubmit();
  const { submitButtonTitle, isErrorWidgetShown, isMnpTariffEnabled } = useMnpFormData();
  const {
    data: { phoneNumber, phoneInputError, phonePlaceholder },
    handlers: phoneInputHandlers,
  } = usePhoneInput();

  return (
    <form onSubmit={submitMnpForm}>
      <Metric events={{ yaMNPMetric: ['handleClickInput'] }} mode="click">
        <Input
          disabled={isFormSubmitting || isErrorWidgetShown}
          error={phoneInputError}
          placeholder={phonePlaceholder}
          status={phoneInputError ? 'error' : 'default'}
          value={phoneNumber}
          wrapperProps={{ className: cx('phone-input-wrapper') }}
          {...phoneInputHandlers}
        />
      </Metric>

      <Metric
        data={submitButtonTitle}
        events={{
          yaMNPMetric: [
            isMnpTariffEnabled ?
              'handleClickTariffWidgetSubmitButton'
            : 'handleClickStartTransferPhoneNumber',
          ],
        }}
        mode="click"
      >
        <Button
          className={cx('submit-button')}
          disabled={Boolean(phoneInputError)}
          loading={isFormSubmitting || isErrorWidgetShown}
          size="l"
          type="submit"
        >
          {submitButtonTitle}
        </Button>
      </Metric>
    </form>
  );
};
