import { composePhoneValidator, composeRequiredValidator } from '@beef/utils/validators';

import { EFormInputNames } from 'pages/LizaAlertLandingPage/components/VolunteersForm/types';
import { TLocations } from 'pages/LizaAlertLandingPage/types';

export enum EFieldErrors {
  phoneNumber = 'укажите номер телефона',
  name = 'введите имя',
  lastName = 'введите фамилию',
  code = 'неверный код',
}

/** форматирование данных локаций под компонент Select */
export const formatLocationsData = (locations: TLocations) =>
  locations.map(({ id, name, ...otherProps }) => ({
    ...otherProps,
    key: id,
    label: name,
  }));

export const getValidators = () => ({
  [EFormInputNames.phoneNumber]: composePhoneValidator(EFieldErrors.phoneNumber, undefined),
  [EFormInputNames.name]: composeRequiredValidator(EFieldErrors.name, undefined),
  [EFormInputNames.lastName]: composeRequiredValidator(EFieldErrors.lastName, undefined),
});
