import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

type PriceType = {
  color: string;
  discount: number;
  price: number;
  size: string;
};

// TODO: переписать компонент при рефакторинге
const getTotalPrice = (price: number, discount: number) => +(price - price * discount).toFixed(2);

const convertTitle = (price: number, discount: number) => {
  if (discount) {
    return `${getTotalPrice(price, discount)} ₽`;
  }

  /** Т.к. price через контент могут добавить строкой, на всякий случай преобразуем поле в число */
  if (Number(price) === 0) {
    return 'бесплатно';
  }

  return `${price} ₽`;
};

export const Price: FC<PriceType> = ({ price, discount, size = 'size5-r-s', color }) => (
  <div className={cx('wrapper')}>
    <Text className={cx('text')} color="" size={size}>
      <span className={cx('inner-text')}>
        {discount ?
          <span className={cx('text_strike-through', color)}>{`${convertTitle(
            price,
            discount,
          )}`}</span>
        : null}
        {`${convertTitle(price, discount)}`}
      </span>
    </Text>
  </div>
);
