import { useAppSelector } from 'pages/MNPLanding/store';
import {
  selectInputBlockContent,
  selectIsMnpTariffEnabled,
} from 'pages/MNPLanding/store/slices/commonSlice/selectors';
import { selectIsErrorWidgetShown } from 'pages/MNPLanding/store/slices/mnpFormSlice/selectors';

/**
 * Хук с данными для формы переноса номера
 */
export const useMnpFormData = () => {
  const { buttonTitle, buttonTitleV2 } = useAppSelector(selectInputBlockContent);
  const isMnpTariffEnabled = useAppSelector(selectIsMnpTariffEnabled);
  const isErrorWidgetShown = useAppSelector(selectIsErrorWidgetShown);

  return {
    submitButtonTitle: isMnpTariffEnabled ? buttonTitleV2 : buttonTitle,
    isErrorWidgetShown,
    isMnpTariffEnabled,
  };
};
