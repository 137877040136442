import React from 'react';
import { useBreakpoints } from '@carnica/utils/hooks';

import { VolunteersFormDesktop } from 'pages/LizaAlertLandingPage/components/VolunteersForm/view/VolunteersFormDesktop';
import { VolunteersFormMobile } from 'pages/LizaAlertLandingPage/components/VolunteersForm/view/VolunteersFormMobile';

export const VolunteersForm = () => {
  const [breakpoint] = useBreakpoints({
    mobile: 0,
    tablet: 801,
  });

  return breakpoint === 'tablet' ? <VolunteersFormDesktop /> : <VolunteersFormMobile />;
};
