import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Text } from '@carnica/ui-kit';
import classnames from 'classnames/bind';
import { RootStateOrAny, connect } from 'react-redux';
import { useIsMobileDimension } from '@beef/ui-kit/hooks';

import { getModifiedCLoudsData } from 'pages/FancyNumber2024/utils';
import { getCloudsCarouselContent } from 'pages/FancyNumber2022/store/selectors/content';
import { CloudType, ICloudsCarousel } from 'pages/FancyNumber2024/components/CloudsCarousel/types';
import { CLOUDS_CAROUSEL_TIMER } from 'pages/FancyNumber2024/components/ConfirmationContainer/constants';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

// TODO: переписать компонент при рефакторинге
const _CloudsCarousel = memo<ICloudsCarousel>(({ cloudsData }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const timerId = useRef(null);
  const isMobile = useIsMobileDimension();

  const updData = useMemo(
    () => getModifiedCLoudsData(cloudsData, isMobile),
    [cloudsData, isMobile],
  );

  const changeActiveIndex = useCallback(() => {
    setActiveIndex((prevState) => {
      // в updData для desktop - добавляется пустое облако в конце,чтобы выглядело как по макету
      const lastElemCount = !isMobile ? updData.length - 2 : updData.length - 1;

      if (prevState >= lastElemCount) {
        clearInterval(timerId.current);
        return prevState;
      }

      return prevState + 1;
    });
  }, [updData]);

  useEffect(() => {
    timerId.current = setInterval(changeActiveIndex, CLOUDS_CAROUSEL_TIMER);

    return () => {
      clearInterval(timerId.current);
    };
  }, [changeActiveIndex]);

  return (
    <div className={cx('wrapper')}>
      <motion.ul
        animate={{
          x: isMobile ? activeIndex * -331 : 0,
          y: !isMobile ? activeIndex * -80 : 0,
        }}
        className={cx('cloud-list')}
        initial={{ y: 0, x: 0 }}
        transition={{ type: 'tween', duration: 0.15, ease: 'easeIn' }}
      >
        {updData?.map((cloud: CloudType, index: number) => (
          <motion.li
            animate={{ opacity: activeIndex === index ? 1 : 0.5 }}
            className={cx('cloud-wrapper', {
              'cloud-wrapper--active': activeIndex === index,
              'cloud-wrapper--small': activeIndex !== index,
              'cloud-wrapper--right': index % 2 === 0,
              'cloud-wrapper--left': index % 2 !== 0,
            })}
            initial={{ opacity: 1 }}
            key={index}
            transition={{ duration: 1.5 }}
          >
            <motion.div
              animate={{ opacity: activeIndex === index ? 1 : 0 }}
              className={cx('info-wrapper', {
                'info-wrapper--hidden': activeIndex !== index,
                'info-wrapper--right': index % 2 === 0,
                'info-wrapper--left': index % 2 !== 0,
              })}
              initial={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Text
                className={cx('info-text')}
                color="primary"
                lineHeight={isMobile ? 20 : 26}
                size={isMobile ? 16 : 20}
                weight="regular"
              >
                {cloud.text}
              </Text>
              <div
                className={cx('info-icon', {
                  'info-icon--left': index % 2 === 0,
                })}
              >
                <img alt="" className={cx('img')} src={cloud.icon} />
              </div>
            </motion.div>
          </motion.li>
        ))}
      </motion.ul>
    </div>
  );
});

const mapStateToProps = (state: RootStateOrAny) => ({
  cloudsData: getCloudsCarouselContent(state),
});

export const CloudsCarousel = connect(mapStateToProps)(_CloudsCarousel);
