import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useIsMobileDimension } from '@beef/ui-kit';
import { Button, Text } from '@carnica/ui-kit';
import { IconArrowsLeft, IconArrowsRight } from '@carnica/graphics';
import SwiperClass from 'swiper/types/swiper-class';
import PropTypes from 'prop-types';

import { TCarouselCards } from 'pages/LizaAlertLandingPage/components/CarouselCards/types';
import { enhancedConnect } from 'utils/lowCodeHelpers';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const _CarouselCards = ({ slides }: TCarouselCards) => {
  const isMobile = useIsMobileDimension();
  const [swiper, setSwiper] = useState<SwiperClass>(null);
  const [progress, setProgress] = useState<number>(0);

  const setInstance = (swiperInstance: SwiperClass) => {
    setSwiper(swiperInstance);
    setProgress(swiperInstance.progress);
  };

  const nextSlide = () => swiper?.slideNext();
  const prevSlide = () => swiper?.slidePrev();

  useEffect(() => {
    if (swiper) {
      swiper?.updateSlides();
      swiper?.slideTo(0);
    }
  }, [slides]);

  return (
    <div className={cn('carousel-container')}>
      <div className={cn('carousel-arrow', 'arrow-prev')}>
        {progress > 0 && (
          <Button
            className={cn('arrow-button', 'arrow-prev__button')}
            iconLeft={IconArrowsLeft}
            onClick={prevSlide}
            onlyIcon
            view="secondary"
          />
        )}
      </div>
      <Swiper
        className={cn('carousel-swiper')}
        onSlideChange={setInstance}
        onSwiper={setInstance}
        slidesPerView="auto"
        spaceBetween={isMobile ? 16 : 24}
      >
        {slides?.map((item) => (
          <SwiperSlide className={cn('carousel-slide')} key={item.id}>
            <a
              className={cn('advice-card')}
              href={item.href}
              style={{
                background: `linear-gradient(180deg, rgba(40, 48, 63, 0.00) 60.89%, rgba(40, 48, 63, 0.90) 100%), url('${item.backgroundImage}') 50% / cover no-repeat`,
              }}
              target={item.target}
            >
              <Text color={item.textColor || 'primary-invert'} variant="headline-small">
                {item.title}
              </Text>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={cn('carousel-arrow', 'arrow-next')}>
        {progress !== 1 && (
          <Button
            className={cn('arrow-button', 'arrow-next__button')}
            iconLeft={IconArrowsRight}
            onClick={nextSlide}
            onlyIcon
            view="secondary"
          />
        )}
      </div>
    </div>
  );
};

export const CarouselCards = enhancedConnect({
  slides: PropTypes.string,
})(_CarouselCards);
