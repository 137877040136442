import React, { FC } from 'react';

import { PageContainer } from 'pages/FancyNumber2024/components/PageContainer';
import { AttentionContainer } from 'pages/FancyNumber2024/components/AttentionContainer';

/** Контейнер страницы предупреждения FancyNumber2024.
 * Используем дополнительный PageContainer из-за особенности верстки главного компонента _FancyNumberLayout2024 */
export const FancyNumberAttention: FC = () => (
  <PageContainer>
    <AttentionContainer />
  </PageContainer>
);
