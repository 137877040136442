import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const FieldsWrapper = ({ children, gap }: { children: ReactNode; gap: string }) => {
  return <div className={cn('fields-wrapper', `fields-wrapper--gap-${gap}`)}>{children}</div>;
};
