import { createReducer } from '@reduxjs/toolkit';

import { DEFAULT_DEF_CODE, FILTER_FAVORITE } from '../../constants';
import {
  addNotification,
  removeNotification,
  setFilter,
  setFilterDefCode,
  setFilterQuery,
  setResetWidgetFilters,
} from '../actions/filter';

export const filter = createReducer(
  {
    active: FILTER_FAVORITE,
    query: '',
    defCode: DEFAULT_DEF_CODE,
    notifications: [],
    isResetWidgetFilters: false,
  },
  {
    [setFilter.type]: (state, action) => {
      state.active = action.payload;
    },
    [setFilterQuery.type]: (state, action) => {
      state.query = action.payload;
    },
    [setFilterDefCode.type]: (state, action) => {
      state.defCode = action.payload;
    },
    [addNotification.type]: (state, action) => {
      state.notifications.push(action.payload);
    },
    [removeNotification.type]: (state, action) => {
      const index = state.notifications.findIndex((item) => item.id === action.payload);

      state.notifications.splice(index, 1);
    },
    [setResetWidgetFilters.type]: (state, action) => {
      state.isResetWidgetFilters = action.payload;
    },
  },
);
