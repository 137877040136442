import React, { FC } from 'react';
import { Button, Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { setChatWidgetOpen } from '@beef/widget-chat';

import { AdaptivityContainer } from 'pages/MainPage/utils/ResponsiveContainers';
import { Metric } from 'pages/ContactPage/metric';
import withContent from 'pages/ContactPage/utils/withContent';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _CardAction: FC<{
  text: string;
}> = ({ text }) => {
  return (
    <Metric asChild events={{ сontactPageMetric: ['handleOpenChatClick'] }} mode="click">
      <div className={cx('action-container')}>
        <AdaptivityContainer minWidth={1025}>
          <div className={cx('action')} onClick={() => setChatWidgetOpen(true)} />
          <Text className={cx('action-text')} size="m" weight="medium">
            {text}
          </Text>
        </AdaptivityContainer>
        <AdaptivityContainer maxWidth={1024} minWidth={800}>
          <Button onClick={() => setChatWidgetOpen(true)} size="l">
            {text}
          </Button>
        </AdaptivityContainer>
        <AdaptivityContainer maxWidth={799}>
          <Button onClick={() => setChatWidgetOpen(true)} size="m">
            {text}
          </Button>
        </AdaptivityContainer>
      </div>
    </Metric>
  );
};

export const CardAction = withContent(_CardAction);
