import { useSelector } from 'react-redux';

import { onReloadWithCrash } from 'pages/FancyNumber2024/utils/onReloadWithCrash';
import { getBaseUrl, getErrorBoundaryContent } from 'pages/FancyNumber2022/store/selectors/content';

/** Хук подготовки данных для компонента ErrorBoundaryFallback */
export const useErrorBoundaryFallback = () => {
  /** Url главной страницы */
  const baseUrl = useSelector(getBaseUrl);

  /** Контент для компонента ErrorBoundaryFallback */
  const content = useSelector(getErrorBoundaryContent);

  /** Функция возврата на главную страницу */
  const onReload = () => onReloadWithCrash(baseUrl);

  return { onReload, ...content };
};
