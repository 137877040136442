import React from 'react';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import { AppBlockProps } from './types';
import { ContentBlock } from './components/ContentBlock';
import { StoresBlock } from './components/StoresBlock';
import { StickyIconBlock } from './components/StickyIconBlock';
import styles from './styles.pcss';
import { AppContainer } from './components/AppContainer';

const cx = classNames.bind(styles);

const _AppBlock: React.FC<AppBlockProps> = ({ content, stores, stickyImageUrl, phoneImageUrl }) => {
  const isMobile = useIsMobileDimension();
  return (
    <AppContainer>
      {stickyImageUrl && !isMobile && <StickyIconBlock stickyImageUrl={stickyImageUrl} />}
      <ContentBlock {...content} {...stores.links} />
      <img alt="" className={cx('app-phone')} src={phoneImageUrl} />
      <StoresBlock {...stores} className={cx('app-store')} />
    </AppContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.data.content.appBlock,
  };
};

export const AppBlock = connect(mapStateToProps)(_AppBlock);
