import { MAX_PHONE_NUMBER_LENGTH } from 'pages/FancyNumber2022/constants';

/** Функция предназначена для полного заполнения строки по формату остаточного пользовательского номера (без кода и региона) */
export const padString = (str: string) => {
  /* Проверяем длину строки */
  if (str.length < MAX_PHONE_NUMBER_LENGTH) {
    /* Добавляем пробелы до длины 7 (MAX_PHONE_NUMBER_LENGTH) */
    return str.padEnd(MAX_PHONE_NUMBER_LENGTH, ' ').split('');
  }
  /* Если длина MAX_PHONE_NUMBER_LENGTH, возвращаем строку без изменений */
  return str.split('');
};
