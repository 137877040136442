import type { ITrustPaymentProps, TDataModel } from './types';

export const dataConverter = ({ data }: TDataModel): ITrustPaymentProps => ({
  content:
    {
      layout: data?.layout,
      banner: data?.banner,
      infoCard: data?.infoCard,
      isAvailableToConnect: data?.trustedPaymentInfo?.isAvailable,
      trustedPaymentInfo: data?.trustedPaymentInfo,
    } || null,
  isAuthenticated: data?.isAuthorized,
  isConnected:
    !!data?.trustedPaymentInfo?.connectedSocs &&
    data?.trustedPaymentInfo?.connectedSocs?.length > 0,
});
