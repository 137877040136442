import React, { memo } from 'react';
import { RootStateOrAny, connect } from 'react-redux';
import classnames from 'classnames/bind';

import { CategoriesSelect } from 'pages/FancyNumber2024/components/NumbersBlock/components/CategoriesSelect';
import { NumbersList } from 'pages/FancyNumber2024/components/NumbersBlock/components/NumbersList';
import { NumberRow } from 'pages/FancyNumber2024/components/NumbersBlock/components/NumbersList/NumberRow';
import { getIsChangeNumber } from 'pages/FancyNumber2022/store/selectors/change';
import { NumbersTitle } from 'pages/FancyNumber2024/components/NumbersBlock/components/NumbersTitle';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

type NumbersBlockPropsType = {
  isChangeNumber: boolean;
};

// TODO: переписать компонент при рефакторинге
const _NumbersBlock = memo<NumbersBlockPropsType>(({ isChangeNumber }) => {
  return (
    <section className={cx('wrapper')}>
      <NumbersTitle />

      <div className={cx('categories')}>
        <CategoriesSelect />
      </div>

      <div className={cx('numbers-list')}>
        <NumbersList>
          {(number) => (
            <NumberRow isChangeNumber={isChangeNumber} key={number.value} number={number} />
          )}
        </NumbersList>
      </div>
    </section>
  );
});

const mapStateToProps = (state: RootStateOrAny) => ({
  isChangeNumber: getIsChangeNumber(state),
});

export const NumbersBlock = connect(mapStateToProps)(_NumbersBlock);
