import React, { FC } from 'react';
import classnames from 'classnames/bind';

import { ConditionsStar } from '../ConditionsStar';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

/** Компонент-обертка со "звёздами" для ConditionsNumber */
export const ConditionsStars: FC = () => (
  <div className={cx('wrapper')}>
    <ConditionsStar className={cx('wrapper--left')} />
    <ConditionsStar className={cx('wrapper--right')} type="right" />
  </div>
);
