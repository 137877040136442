import React, { memo, useEffect } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import type { Swiper as SwiperClass } from 'swiper/types';
import { A11y, FreeMode, Pagination } from 'swiper/modules';

import { RegionSelectorPropsType } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/NewNumberContainer/components/MainContent/types';
import { DEFAULT_DEF_CODE_DOTS } from 'pages/FancyNumber2022/constants';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const MAX_NUMBER_IN_VIEW = 5;

// TODO: переписать компонент при рефакторинге
export const RegionSelector = memo<RegionSelectorPropsType>(
  ({
    defCodes,
    selectedRegion,
    onSlideChange,
    swiperRef,
    hasAtLeastOneFilledInputCell,
    setPosition,
    setSelectedRegion,
    setHasInitialized,
    hasInitialized,
  }) => {
    useEffect(() => {
      if (!hasAtLeastOneFilledInputCell) {
        setPosition(0);
      }

      if (swiperRef.current && selectedRegion === DEFAULT_DEF_CODE_DOTS && !hasInitialized) {
        const initialIndex = 0;
        swiperRef.current.slideTo(initialIndex);
        setSelectedRegion(defCodes?.[initialIndex]);
        setHasInitialized(true);
      }
    }, [hasAtLeastOneFilledInputCell, defCodes, hasInitialized]);

    return (
      <div className={cx('region-container')}>
        <Swiper
          centeredSlides
          className={cx('swiper')}
          direction="vertical"
          freeMode={{ enabled: true, sticky: true, momentum: false }}
          modules={[Pagination, FreeMode, A11y]}
          onSlideChange={onSlideChange}
          onSwiper={(swiper: SwiperClass | null) => {
            swiperRef.current = swiper;
          }}
          slidesPerView={MAX_NUMBER_IN_VIEW}
        >
          {defCodes?.map((code: string) => (
            <SwiperSlide
              className={cx('swiper-slide', {
                'swiper-slide--active': selectedRegion === code,
              })}
              key={code}
            >
              <Text
                className={cx('region-code', {
                  'region-code--active': selectedRegion === code,
                })}
                color="white"
                size="size3-m"
              >
                {code}
              </Text>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  },
);
