import { useEffect } from 'react';

import { scrollElementIntoView } from '../utils/scrollElementIntoView';

/**
 * Хук для автоматического скролла
 * при отображении и скрытии виджета с ошибкой
 */
export const useErrorWidgetScroll = () => {
  useEffect(() => {
    scrollElementIntoView('error-widget');

    return () => {
      scrollElementIntoView('mnp-widget-input-block');
    };
  }, []);
};
