/** Функция формирует значения минут (minutes) и секунд (seconds) из time */
export const createTimerNumbers = (ms: number) => {
  /** Общее количество секунд */
  const totalSeconds = ms / 1000;

  /** Минуты */
  const minutes = Math.floor(totalSeconds / 60);

  /**  Секунды */
  const seconds = totalSeconds % 60;

  return { minutes, seconds };
};
