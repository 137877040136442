import { useIsMobileDimension } from '@carnica/utils/hooks';

import { useAppSelector } from 'pages/MNPLanding/store';
import {
  selectIsMnpTariffEnabled,
  selectQRBlockContent,
} from 'pages/MNPLanding/store/slices/commonSlice/selectors';

/**
 * Хук с данными для блока перехода в мобильное приложение
 */
export const useQRBlock = () => {
  const content = useAppSelector(selectQRBlockContent);
  const isMnpTariffEnabled = useAppSelector(selectIsMnpTariffEnabled);

  const isMobile = useIsMobileDimension();

  return {
    ...content,
    isMobile,
    isMnpTariffEnabled,
  };
};
