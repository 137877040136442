import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@carnica/ui-kit';

/** TODO Проверить можно ли отказаться от компонента Anchor */
import { Anchor } from 'pages/MNPLanding/components/Anchor';
import { Metric } from 'pages/MNPLanding/analytics';

import styles from './styles.pcss';
import { useStartSectionContent } from './hooks/useStartSectionContent';
import { Dots } from './components/Dots';
import { AnimatedIcons } from './components/AnimatedIcons';

const cx = classNames.bind(styles);

/**
 * Стартовый блок лендинга MNP
 */
export const StartSection = memo(() => {
  const {
    anchorId,
    title,
    description,
    buttonTitle,
    handleScrollToInfoAboutServiceSection,
    isLargeDesktop,
    isMobile,
    titleVariant,
    isMnpTariffEnabled,
  } = useStartSectionContent();

  return (
    <section className={cx('section')}>
      {isLargeDesktop && <Dots />}

      <Anchor anchorId={anchorId} />

      {isLargeDesktop && <AnimatedIcons />}

      <div className={cx('content', { 'content--tariff': isMnpTariffEnabled })}>
        <Metric asChild events={{ yaMNPMetric: ['handleViewStartBlock'] }} mode="viewport">
          <div className={cx('info')} id="info-block">
            <Text align={isLargeDesktop ? 'left' : 'center'} variant={titleVariant}>
              {title}
            </Text>
            <Text
              align={isLargeDesktop ? 'left' : 'center'}
              allowLineFeed={false}
              className={cx('description')}
              color="secondary"
              variant={isMobile ? 'body-small' : 'body-medium'}
            >
              {description}
            </Text>
          </div>
        </Metric>

        {isLargeDesktop && (
          <Metric events={{ yaMNPMetric: ['handleClickButtonMeet'] }} mode="click">
            <Button
              className={cx('button')}
              onClick={handleScrollToInfoAboutServiceSection}
              size="l"
              view="secondary"
            >
              {buttonTitle}
            </Button>
          </Metric>
        )}
      </div>
    </section>
  );
});
