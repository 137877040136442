import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FREE_PRICE_TEXT } from 'pages/FancyNumber2024/constants';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { breakdownPrice } from 'pages/FancyNumber2024/utils/breakdownPrice';
import { breakdownNumber } from 'pages/FancyNumber2024/utils/breakdownNumber';
import { getCurrentNumber } from 'pages/FancyNumber2022/store/selectors/change';
import { getConditionsPage } from 'pages/FancyNumber2022/store/selectors/content';
import { useFancyNumberLink } from 'pages/FancyNumber2024/router/hooks/useFancyNumberLink';
import { getBtnText } from 'pages/FancyNumber2024/components/ConditionsContainer/utils/getBtnText';
import { createPageTitle } from 'pages/FancyNumber2024/components/ConditionsContainer/utils/createPageTitle';
import { getNumberContent } from 'pages/FancyNumber2024/components/ConditionsContainer/utils/getNumberContent';
import { TConditionsBill } from 'pages/FancyNumber2024/components/ConditionsContainer/componetns/ConditionsBill';
import { TConditionsSteps } from 'pages/FancyNumber2024/components/ConditionsContainer/componetns/ConditionsSteps';
import { TConditionsButton } from 'pages/FancyNumber2024/components/ConditionsContainer/componetns/ConditionsButton';
import { createConditionsSteps } from 'pages/FancyNumber2024/components/ConditionsContainer/utils/createConditionsSteps';

/** Хук подготовки данных для компонента ConditionsContainer */
export const useConditionsContainer = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobileDimension();

  /** Ссылка для перехода на главную страницу */
  const mainUrl = useFancyNumberLink('main');

  /** Ссылка для перехода на страницу предупреждения */
  const attentionUrl = useFancyNumberLink('attention');

  /** Контентные данные страницы */
  const { pageTitle: title, typeNumbers, conditionsSteps } = useSelector(getConditionsPage);

  /** Текущий выбранный пользователем номер, цена, категория */
  const { value: phone, price, category } = useSelector(getCurrentNumber) ?? {};

  /* Если отсутствует номер телефона или категория, возвращаем пользователя на главную страницу.
   * ЛОКАЛЬНЫЙ предохранитель от перезагрузки страницы на этапе показа условий обновления (confirmation).
   * На prod такой сценарий невозможен */
  if (!phone || !category) {
    navigate(mainUrl);
  }

  /** Текст кнопки */
  const btnText = getBtnText(typeNumbers, category, price);

  /** Заголовок страницы на основе категории номера пользователя */
  const pageTitle: string = createPageTitle(
    title,
    getNumberContent(typeNumbers, category, 'declination'),
  );

  /** Props для компонента ConditionsSteps */
  const stepsProps: TConditionsSteps = {
    steps: createConditionsSteps(isMobile, conditionsSteps),
  };

  /** Props для компонента ConditionsBill */
  const billProps: TConditionsBill = {
    /* Props кнопки (ConditionsButton (desktop version)) */
    btnProps: {
      btnText,
      link: attentionUrl,
      isVisible: !isMobile,
    },
    /* Props для блока с номером телефона и ценой (ConditionsNumber) */
    settingsProps: {
      numberProps: {
        title: breakdownNumber(phone ? `7${phone}` : ''),
        tagText: price ? `${breakdownPrice(price)} ₽` : FREE_PRICE_TEXT,
        bgColor: getNumberContent(typeNumbers, category, 'bgColor'),
      },
      /* Props для блока бонусов/доплат (ConditionsInfo) */
      infoProps: getNumberContent(typeNumbers, category, 'info'),
    },
  };

  /** Props кнопки (ConditionsButton (mobile version)) */
  const btnProps: TConditionsButton = {
    btnText,
    link: attentionUrl,
    isVisible: isMobile,
  };

  return { pageTitle, stepsProps, billProps, btnProps };
};
