import { createSelector } from '@reduxjs/toolkit';
import { emptyArr, times } from '@beef/utils';

import { getRandomInt } from 'utils/fn';
import { CATEGORY_ALIAS } from 'pages/FancyNumber2022/constants';
import { getIsChangeNumber } from 'pages/FancyNumber2022/store/selectors/change';

import { getActiveCategoriesArray } from './category';
import { getPrices, getPricesForChangeNumber, selectIsRedesign2024Enabled } from './content';

const getNumbersState = (state) => state.numbers;

export const getDefCodes = createSelector(getNumbersState, (state) => state.defCodes);
export const getCurrentRandomNumber = createSelector(
  getNumbersState,
  (state) => state.randomNumber,
);
export const getRandomNumberCategory = createSelector(
  getNumbersState,
  (state) => state.randomNumberCategory,
);
export const getNumbersInCart = createSelector(getNumbersState, (state) => state.numbersInCart);
export const getError = (state) => getNumbersState(state).error;
export const selectIsCaptchaEnabled = createSelector(
  getNumbersState,
  (state) => state.isCaptchaEnabled,
);
export const selectIsFetching = createSelector(getNumbersState, (state) => state.isFetching);

export const selectIsCaptchaInvalid = createSelector(
  getNumbersState,
  (state) => state.isCaptchaInvalid,
);
export const selectCaptchaData = createSelector(getNumbersState, (state) => state.captchaData);
export const selectReCallAfterCaptchaRequest = createSelector(
  getNumbersState,
  (state) => state?.reCallRequestName,
);

// redesign2024
export const getDefCodesAndDefault = createSelector([getDefCodes], (defCodes) => [
  '•••',
  ...(defCodes || []),
]);

const mapListsByPrice = (state, prices, numbersInCart) => {
  if (!state.lists) return null;
  return Object.keys(state.lists).reduce(
    (acc, category) => ({
      ...acc,
      [category]: state.lists[category].map((number) => ({
        ...number,
        price: prices[category]?.price,
        discount: prices[category]?.discount,
        isInCart: numbersInCart.includes(number.value),
        featureCode: number.featureCode,
      })),
    }),
    {},
  );
};

export const getNumbers = createSelector(
  getNumbersState,
  getPrices,
  getNumbersInCart,
  mapListsByPrice,
);

export const getChangeNumbers = createSelector(
  getNumbersState,
  getPricesForChangeNumber,
  getNumbersInCart,
  mapListsByPrice,
);

const mapCategoryToNumberList = (lists, activeCategories, isRedesign2024, isChangeNumber) => {
  if (!lists) return emptyArr;

  const categories = activeCategories.length ? activeCategories : Object.keys(lists);
  const maxListLength = Math.max(...Object.values(lists).map((group) => group.length));
  const resultList = [];
  const resultListSortedObj = {};

  // redesign2024
  if (isRedesign2024) {
    if (categories.length === 1 && categories[0] === CATEGORY_ALIAS.all) {
      const keys = Object.keys(lists);

      return keys.reduce((acc, key) => {
        if (lists[key] && Array.isArray(lists[key]) && lists[key].length > 0) {
          // TODO: без аналитики невозможно точно определить корректность данной фильтрации (featureCode)!
          /* Выборка номеров.
           * Если активен таб "Изменить номер" (isChangeNumber) - фильтруем по featureCode, иначе по isBought */
          const filteredSubCat = lists[key]?.filter((numBlock) =>
            isChangeNumber ? numBlock.featureCode : numBlock.isBought === false,
          );
          acc[key] = filteredSubCat;
          return acc;
        }

        return acc;
      }, {});
    }

    categories.forEach((category) => {
      times(lists[category]?.length, (index) => {
        if (lists[category] && lists[category]?.[index] && !lists[category]?.[index]?.isBought) {
          if (!resultListSortedObj[category]) {
            resultListSortedObj[category] = [lists[category][index]];
          } else {
            resultListSortedObj[category].push(lists[category][index]);
          }
        }
      });
    });

    return resultListSortedObj;
  }

  times(maxListLength, (index) => {
    categories.forEach((category) => {
      if (lists[category] && lists[category][index]) {
        resultList.push(lists[category][index]);
      }
    });
  });

  return resultList;
};

/**
 * Returns shuffled by category array of numbers. Returns null if there is no numbers yet
 */
export const getNumbersList = createSelector(
  getNumbers,
  getActiveCategoriesArray,
  selectIsRedesign2024Enabled,
  getIsChangeNumber,
  mapCategoryToNumberList,
);

export const getChangeNumberList = createSelector(
  getChangeNumbers,
  getActiveCategoriesArray,
  selectIsRedesign2024Enabled,
  getIsChangeNumber,
  mapCategoryToNumberList,
);

export const getNextRandomNumber = createSelector(
  getNumbers,
  getCurrentRandomNumber,
  getRandomNumberCategory,
  (lists, currentNumber, category) => {
    if (!lists?.[category]?.length) return null;

    const numbers = lists[category];
    const indices = times(numbers.length, (i) => i);
    if (currentNumber) {
      const currentIndex = numbers.findIndex((number) => number.value === currentNumber.value);
      if (currentIndex !== -1) {
        indices.splice(currentIndex, 1);
      }
    }
    if (indices.length === 0) return currentNumber;

    const randomIndex = getRandomInt(0, indices.length - 1);
    return numbers[indices[randomIndex]];
  },
);

export const getIsLoading = createSelector(getNumbersState, (state) => state.loading);

export const getShowEmptyList = createSelector(
  getNumbers,
  getNumbersList,
  getError,
  (numbers, resultList, error) => !!numbers && resultList.length === 0 && !error,
);

// redesign2024

export const selectInputDataFields = createSelector(
  getNumbersState,
  (state) => state.inputDataFields,
);

export const getIsLoadNumbersSuccess = createSelector(
  getNumbersState,
  (state) => state.isLoadNumbersSuccess,
);
export const getIsLoadNumbersError = createSelector(
  getNumbersState,
  (state) => state.isLoadNumbersError,
);

export const getWidgetButtonState = createSelector(
  getNumbersState,
  (state) => state.widgetButtonState,
);

export const getLaunchCaptcha = createSelector(getNumbersState, (state) => state.launchCaptcha);
