import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { AnimatePresence } from 'framer-motion';

import styles from './styles.pcss';
import { useAppSelector } from '../../store';
import { selectIsMnpTariffEnabled } from '../../store/slices/commonSlice/selectors';
import { TariffMnpWidget } from './components/TariffMnpWidget';
import { selectIsErrorWidgetShown } from '../../store/slices/mnpFormSlice/selectors';
import { ErrorWidget } from './components/ErrorWidget';
import { DefaultMnpWidget } from './components/DefaultMnpWidget';

const cx = classNames.bind(styles);

/**
 * Виджет с формой для переноса номера
 */
export const MNPForm: FC = () => {
  const isMnpTariffEnabled = useAppSelector(selectIsMnpTariffEnabled);
  const isErrorWidgetShown = useAppSelector(selectIsErrorWidgetShown);

  return (
    <div className={cx('movement-area')}>
      <div className={cx('mnp-widget', { 'mnp-widget--tariff': isMnpTariffEnabled })}>
        <AnimatePresence initial={false} mode="wait">
          {/* eslint-disable-next-line no-nested-ternary */}
          {isErrorWidgetShown ?
            <ErrorWidget key="error-widget" />
          : isMnpTariffEnabled ?
            <TariffMnpWidget key="tariff-mnp-widget" />
          : <DefaultMnpWidget key="default-mnp-widget" />}
        </AnimatePresence>
      </div>
    </div>
  );
};
