import React, { FC } from 'react';
import classnames from 'classnames/bind';

import { ConditionsSettings, TConditionsSettings } from '../ConditionsSettings';
import { ConditionsButton, TConditionsButton } from '../ConditionsButton';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export type TConditionsBill = {
  btnProps: TConditionsButton;
  settingsProps: TConditionsSettings;
};

/** Компонент-обертка отображения результирующих условий по обновлению номера (чек) */
export const ConditionsBill: FC<TConditionsBill> = ({ btnProps, settingsProps }) => (
  <div className={cx('wrapper')}>
    <ConditionsSettings {...settingsProps} />
    <ConditionsButton {...btnProps} />
  </div>
);
