import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classnames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export type TConditionsInfo = {
  icon?: string;
  subTitle?: string;
  title?: string;
};

/** Компонент с дополнительной информацией по номеру (бонус, списание за обновление и т.п.) */
export const ConditionsInfo: FC<TConditionsInfo> = ({ icon, title, subTitle }) =>
  title ?
    <div className={cx('wrapper')}>
      {icon && <img alt="" className={cx('wrapper__icon')} loading="lazy" src={icon} />}
      <div className={cx('wrapper__content')}>
        <Text align="left" color="primary-invert" lineHeight={20} size="xs">
          {title}
        </Text>
        {subTitle && (
          <Text align="left" color="primary-invert" lineHeight={20} size="xs">
            {subTitle}
          </Text>
        )}
      </div>
    </div>
  : null;
