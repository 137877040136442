import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'pages/MNPLanding/store';
import { hideErrorWidget } from 'pages/MNPLanding/store/slices/mnpFormSlice';
import { selectErrorWidgetType } from 'pages/MNPLanding/store/slices/mnpFormSlice/selectors';

import { useMnpFormSubmit } from '../../InputBlock/components/InputForm/hooks/useMnpFormSubmit';
import { ErrorWidgetHandlers } from '../types';
import { openAuthMenu, openSupportChat } from '../utils/handlers';

/** Хук для определения обработчиков виджета с ошибками */
export const useErrorWidgetHandlers = () => {
  const errorType = useAppSelector(selectErrorWidgetType);
  const { submitMnpForm } = useMnpFormSubmit();

  const dispatch = useAppDispatch();

  /** Скрывает виджет с ошибкой */
  const hideError = useCallback(() => {
    dispatch(hideErrorWidget());
  }, [dispatch]);

  /** Определяем обработчики в зависимости от типа ошибки */
  const handlers: ErrorWidgetHandlers = useMemo(
    () => ({
      standardError: {
        onClickPrimaryButton: submitMnpForm,
      },
      isLastChangeDate: {
        onClickSecondaryButton: hideError,
      },
      ourNumber: {
        onClickPrimaryButton: hideError,
      },
      isOperatorCodeBeeline: {
        onClickPrimaryButton: openAuthMenu,
        onClickSecondaryButton: hideError,
      },
      isRequest: {
        onClickPrimaryButton: openSupportChat,
      },
    }),
    [hideError, submitMnpForm],
  );

  return handlers[errorType];
};
