import { createAction } from '@reduxjs/toolkit';

import { debounce } from 'utils/timed-functions';

import { setFancyNumberPageUrl, updatePageUrl } from './routing';
import { loadFancyNumberData, loadFilteredNumbers } from './numbers';
import { DEFAULT_DEF_CODE, FANCY_NUMBER_ALIAS, FILTER_FAVORITE } from '../../constants';
import { pushDefCodeChange, pushSearchChange } from '../../analytics/filter';
import { getActiveFilter } from '../selectors/filter';
import { setAliasAction } from './content';

export const setFilter = createAction('set filter');
export const setFilterQuery = createAction('set filter query');
export const setFilterDefCode = createAction('set fitler def code');
export const addNotification = createAction('add notification');
export const removeNotification = createAction('remove notification');

// redesign2024
export const setResetWidgetFilters = createAction('set reset widget filters');

export const setActiveFilter = (filter) => (dispatch) => {
  dispatch(setFilter(filter));
  dispatch(resetFilter);
  dispatch(setAliasAction(filter));
  updatePageUrl({ filter, numbers: null });
};

export const resetPageUrl = (filter) => (dispatch, getState) => {
  if (getActiveFilter(getState()) === filter) {
    dispatch(setFilter(FILTER_FAVORITE));
    dispatch(resetFilter);
    dispatch(setAliasAction(FANCY_NUMBER_ALIAS));
    setFancyNumberPageUrl();
  }
};

export const resetFilter = (dispatch) => {
  dispatch(setFilterQuery(''));
  dispatch(setFilterDefCode(DEFAULT_DEF_CODE));
};

// redesign2024

export const resetWidgetFilter = (dispatch) => {
  dispatch(setFilterQuery(''));
  dispatch(setFilterDefCode(DEFAULT_DEF_CODE));

  dispatch(loadFancyNumberData());
  dispatch(setResetWidgetFilters(false));
};

export const clearWidgetFilters = () => async (dispatch) => {
  dispatch(resetWidgetFilter);
};

const loadFilteredNumbersDebounced = debounce((dispatch, getState) => {
  const filter = getActiveFilter(getState());
  pushSearchChange(filter);
  dispatch(loadFilteredNumbers);
}, 500);

export const setFilterQueryValue = (value) => (dispatch) => {
  dispatch(setFilterQuery(value));
  dispatch(loadFilteredNumbersDebounced);
  updatePageUrl({ numbers: value });
};

export const setFilterDefCodeValue = (value) => (dispatch) => {
  dispatch(setFilterDefCode(value));
  dispatch(loadFilteredNumbers);
  pushDefCodeChange();
};
