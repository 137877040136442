import { BreakpointsMap, useBreakpoints } from '@carnica/utils/hooks';

const LARGE_DESKTOP = 'largeDesktop';

const BREAKPOINTS: BreakpointsMap<string> = {
  [LARGE_DESKTOP]: 1440,
};

/** Хук для определения большого экрана десктопного устройства */
export const useIsLargeDesktopScreen = () => {
  return useBreakpoints(BREAKPOINTS)[1][LARGE_DESKTOP];
};
