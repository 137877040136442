import React, { FC } from 'react';
import classnames from 'classnames/bind';

import { ConfirmationContent } from './components/ConfirmationContent';
import { useConfirmationContent } from './hooks/useConfirmationContent';
import { SMSContainer } from './components/SMSContainer';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

/** Компонент-обертка над страницей подтверждения обновления номера */
export const ConfirmationContainer: FC = () => {
  /* Готовим данные для компонента ConfirmationContainer */
  const { SMSProps, contentProps } = useConfirmationContent();

  return (
    <section className={cx('wrapper')}>
      <ConfirmationContent {...contentProps} />
      <SMSContainer {...SMSProps} />
    </section>
  );
};
