import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { LayoutGroup, motion } from 'framer-motion';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Лайаут, под который будет скрываться фиксированная
 * кнопка на мобильном разрешении
 */
export const LayoutForHideFixedButton: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <LayoutGroup>
      <motion.div className={cx('layout')}>{children}</motion.div>
    </LayoutGroup>
  );
};
