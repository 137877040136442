import React from 'react';
import classNames from 'classnames/bind';
import { Avatar, Text } from '@carnica/ui-kit';
import PropTypes from 'prop-types';

import { enhancedConnect } from 'utils/lowCodeHelpers';

import { TCard } from './types';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const _Card = ({ title, description, icon, href, target }: TCard) => {
  return (
    <a className={cn('card')} href={href} target={target}>
      <Text variant="body-small">{title}</Text>
      {description && (
        <Text className={cn('description')} color="secondary" variant="caption-medium">
          {description}
        </Text>
      )}
      {icon && <Avatar image={icon} size="s" />}
    </a>
  );
};

export const Card = enhancedConnect({
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
})(_Card);
