import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { useIsMobileDimension } from '@carnica/utils/hooks';

import { IInstructionItem } from 'pages/MNPLanding/store/slices/commonSlice/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IStepProps extends IInstructionItem {}

/**
 * Компонент одного шага в блоке
 * "как работает перенос"
 */
export const Step: FC<IStepProps> = ({ description, id, step }) => {
  const isMobile = useIsMobileDimension();

  return (
    <div className={cx('step')}>
      <Text className={cx('step__ordinal-number')} variant="body-small">
        {id}
      </Text>

      <div className={cx('step__info')}>
        <Text variant={isMobile ? 'body-medium' : 'headline-small'}>{step}</Text>

        <Text
          allowLineFeed={false}
          className={cx('description')}
          color="secondary"
          variant="body-small"
        >
          {description}
        </Text>
      </div>
    </div>
  );
};
