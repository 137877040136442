import { connect } from 'react-redux';
import { setCartData as deprecated_setCartData } from '@beef/layout-kit/actions';

import { sendEsimPurchaseEvent, showErrorPopup } from 'pages/ProductCard/actions/tariffConnect';

import type { IDispatchProps, IStateProps } from './types';

const mapDispatchToProps: IDispatchProps = {
  onRequestError: showErrorPopup,
  handleSetCartData: deprecated_setCartData,
  onStoreButtonClick: sendEsimPurchaseEvent,
};

const mapStateToProps = (state: any): IStateProps => ({
  additionalOptions: state.external?.tariffConnectionPopup?.additionalOptions,
  cartApi: state.external?.cartData?.cartApi,
  boughtNumbers: state?.external?.cartData?.currentCart?.numbers ?? [],
});

export const connector = connect(mapStateToProps, mapDispatchToProps);
