import React, { memo } from 'react';
import { RootStateOrAny, connect } from 'react-redux';
import classnames from 'classnames/bind';
import { CollapseContainer } from '@beef/smart-kit';
import { Button, Tag, Text } from '@beef/ui-kit';

import { LoadingWrap } from 'pages-components/LoadingWrap';
import { PreloadLayout } from 'pages/FancyNumber2022/components/NumbersList/PreloadLayout';
import { getIsChangeNumber } from 'pages/FancyNumber2022/store/selectors/change';
import {
  getChangeNumberList,
  getError,
  getIsLoading,
  getNumbersList,
  getShowEmptyList,
} from 'pages/FancyNumber2022/store/selectors/numbers';
import { getCartLoading } from 'pages/FancyNumber2022/store/selectors/cart';
import {
  getPreviewCounters,
  getPromotionContent,
} from 'pages/FancyNumber2022/store/selectors/content';
import { CATEGORY_PLURAL, pushShowMore } from 'pages/FancyNumber2022/analytics/filter';
import { NumbersListPropsType } from 'pages/FancyNumber2024/components/NumbersBlock/components/NumbersList/types';

import styles from './styles.pcss';
import { EmptyList, Error } from './Status/index';

const cx = classnames.bind(styles);

// TODO: переписать компонент при рефакторинге
export const NumbersListLayout = memo<NumbersListPropsType>(
  ({
    numbers,
    isLoading,
    showEmptyList,
    showError,
    previewCounters,
    onExpandClick,
    children,
    promotion,
  }) => (
    <ul className={cx('category-lists')}>
      {Object.entries(numbers).map(([categoryName, numsArr]) => {
        const cName = CATEGORY_PLURAL?.[categoryName]?.toLowerCase();
        const tagPromotionText = promotion?.[categoryName]?.tags?.category?.tagText;

        return (
          <li className={cx('category-block')} key={categoryName}>
            <div className={cx('category-info')}>
              <Text className={cx('category-title')} size="size4-r">
                {cName}
              </Text>

              {tagPromotionText && (
                <Tag className={cx('category-promotion')} testName="Tag-category-promotion">
                  {tagPromotionText}
                </Tag>
              )}
            </div>

            <LoadingWrap
              className={cx('numbers')}
              isLoading={isLoading}
              preloadLayout={!numsArr?.length && <PreloadLayout />}
            >
              <CollapseContainer
                className={cx('collapse-container-custom')}
                onExpandClick={onExpandClick}
                renderButton={({ onClick }) => (
                  <Button
                    className={cx('collapse-container-button')}
                    onClick={onClick}
                    size="s"
                    variant="plain"
                  >
                    {previewCounters.text}
                  </Button>
                )}
                {...previewCounters}
              >
                {numsArr?.map(children)}
              </CollapseContainer>
              {showEmptyList && <EmptyList />}
              {showError && <Error />}
            </LoadingWrap>
          </li>
        );
      })}
    </ul>
  ),
);

const mapStateToProps = (state: RootStateOrAny) => ({
  numbers: getIsChangeNumber(state) ? getChangeNumberList(state) : getNumbersList(state),
  isLoading: getIsLoading(state) || getCartLoading(state),
  showEmptyList: getShowEmptyList(state),
  showError: getError(state),
  previewCounters: getPreviewCounters(state),
  onExpandClick: pushShowMore,
  promotion: !getIsChangeNumber(state) && getPromotionContent(state),
});

export const NumbersList = connect(mapStateToProps)(NumbersListLayout);
