import { createCurrentSelector } from '@beef/redux';
import { emptyObj } from '@beef/utils';
import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'pages/LizaAlertLandingPage/store';
import { EFormInputNames } from 'pages/LizaAlertLandingPage/components/VolunteersForm/types';

export const selectForm = (state: RootState) => state.form || emptyObj;
export const selectPageContent = (state: RootState) => state.blocks || emptyObj;

export const selectVolunteersFormContent = (state: RootState) =>
  selectPageContent(state).volunteersForm || emptyObj;
export const selectFormHelpTypes = (state: RootState) => selectForm(state).helpTypes;
export const selectFormLocations = (state: RootState) => selectForm(state).locations;

export const selectVolunteersForm = (state: RootState) => state.volunteersForm || emptyObj;
export const selectVolunteersFormValues = (state: RootState) =>
  selectVolunteersForm(state).formValues;
export const selectVolunteersConfirmationModal = (state: RootState) =>
  selectVolunteersForm(state).confirmationModal;
export const selectVolunteersFormCaptcha = (state: RootState) =>
  selectVolunteersForm(state).smartCaptcha;
export const selectVolunteersLocationSelects = (state: RootState) =>
  selectVolunteersForm(state).locationSelects;

/** получение локаций для выпадаюащего списка компонента Select, которые еще не были выбраны в форме */
export const selectUnusedLocations = createSelector(
  selectFormLocations,
  selectVolunteersFormValues,
  (locations, formValues) => {
    const usedLocations = Object.entries(formValues).filter(([name]) =>
      name.startsWith(EFormInputNames.location),
    );

    if (!usedLocations.length) return locations;

    return locations.filter(
      (location) => usedLocations.filter(([_, value]) => value.key === location.key).length === 0,
    );
  },
);

export const selectSubmitButtonIsEnabled = createSelector(
  selectVolunteersFormValues,
  (formValues) => {
    const selectedLocations = Object.entries(formValues).filter(
      ([name, value]) => name.startsWith(EFormInputNames.location) && Boolean(value),
    );
    const selectedHelpTypes = Object.entries(formValues).filter(
      ([name, value]) => name.startsWith(EFormInputNames.helpType) && Boolean(value),
    );

    return (
      selectedLocations.length > 0 &&
      selectedHelpTypes.length > 0 &&
      formValues[EFormInputNames.consent]
    );
  },
);

export const {
  withCurrentProviderMapper: withHelpTypesMapper,
  selectCurrent: selectCurrentHelpType,
} = createCurrentSelector(selectFormHelpTypes, (item: any) => item.id);

export const {
  CurrentProviderMapper: LocationSelectMapper,
  selectCurrent: selectCurrentLocationSelect,
} = createCurrentSelector(selectVolunteersLocationSelects, (item: any) => item.id);

/** получение регионов/районов текущей выбранной локации в LocationSelect */
export const selectCurrentLocation = createSelector(
  selectCurrentLocationSelect,
  selectVolunteersFormValues,
  (currentSelect, formValues) => {
    if (!currentSelect) return null;
    const [chosenLocation]: any = Object.entries(formValues).filter(
      ([name]) => name === `${EFormInputNames.location}_${currentSelect.id}`,
    );
    const [_, value] = chosenLocation || [];

    return value;
  },
);

export const selectDistricts = (state: RootState) => selectCurrentLocation(state)?.districts || [];

export const {
  withCurrentProviderMapper: withDistrictMapper,
  selectCurrent: selectCurrentDistrict,
} = createCurrentSelector(selectDistricts, (item: any) => item.id);

export const selectRegionGroups = (state: RootState) => selectCurrentLocation(state)?.regions || [];

export const {
  withCurrentProviderMapper: withRegionGroupMapper,
  selectCurrent: selectCurrentRegionGroup,
} = createCurrentSelector(selectRegionGroups, (item: any) => item.id);

export const selectCurrentRegionGroupDistricts = (state: RootState) =>
  selectCurrentRegionGroup(state).districts;

export const {
  withCurrentProviderMapper: withRegionDistrictMapper,
  selectCurrent: selectCurrentRegionDistrict,
} = createCurrentSelector(selectCurrentRegionGroupDistricts, (item: any) => item.id);
