import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { cnTheme } from '@carnica/themes';
import { RootStateOrAny, connect } from 'react-redux';
import { compose } from '@beef/redux';

import { getError, selectIsCaptchaEnabled } from 'pages/FancyNumber2022/store/selectors/numbers';
import { CustomTabs } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/CustomTabs';
import { NumberSelectionWidgetTypes } from 'pages/FancyNumber2024/components/NumberSelectionWidget/types';
import { getWidgetTabsContent } from 'pages/FancyNumber2022/store/selectors/content';
import { useIsAuthenticated } from 'pages/FancyNumber2022/hooks/useIsAuthenticated';
import { TABS_ALIAS } from 'pages/FancyNumber2024/constants';

import styles from './styles.pcss';
import { NewNumberContainer } from './components/NewNumberContainer';
import { NoAuthContainer } from './components/NoAuthContainer';

const lTheme = cnTheme('light');
const dTheme = cnTheme('dark');

const cx = classNames.bind(styles);

// TODO: переписать компонент при рефакторинге. Некорректная изначальная реализация компонента!
const _NumberSelectionWidget = memo<NumberSelectionWidgetTypes>(({ tabsData, hasRequestError }) => {
  const isAuthenticated = useIsAuthenticated();

  const tabsWithChildren = tabsData?.reduce(
    (acc, tab) => {
      const newTab = { ...tab };

      /* Если пользователь НЕ авторизован и выбран таб "смена номера" (change), показываем блок авторизации */
      if (!isAuthenticated && tab?.id === TABS_ALIAS.change) {
        newTab.children = <NoAuthContainer />;
      } else {
        newTab.children = <NewNumberContainer />;
      }

      acc.push(newTab);
      return acc;
    },
    [] as typeof tabsData,
  );

  return (
    <div className={cx('widget-wrapper', lTheme, dTheme)}>
      <CustomTabs hasRequestError={hasRequestError} tabs={tabsWithChildren} />
    </div>
  );
});

const mapStateToProps = (state: RootStateOrAny) => {
  return {
    isCaptchaEnabled: selectIsCaptchaEnabled(state),
    tabsData: getWidgetTabsContent(state),
    hasRequestError: getError(state),
  };
};

export const NumberSelectionWidget = compose(connect(mapStateToProps))(_NumberSelectionWidget);
