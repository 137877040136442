import React from 'react';
import classNames from 'classnames/bind';

import { Metric } from 'pages/ContactPage/metric';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const StoreLink: React.FC<{
  className?: string;
  fill?: 'dark' | 'light';
  href: string;
  imageUrl: string;
  label: string;
  mode?: 'full' | 'small';
}> = ({ imageUrl, href, label, className, fill = 'light', mode = 'small' }) => (
  <Metric
    asChild
    data={label}
    events={{ contactPageMetric: ['handleAppBLockStoresClick'] }}
    mode="click"
  >
    <a
      aria-label={label}
      className={cx('icon-wrap', className, `icon-fill-${fill}`, `icon-size-${mode}`)}
      href={href}
      rel="noreferrer"
      target="_blank"
    >
      <img alt="" loading="lazy" src={imageUrl} />
    </a>
  </Metric>
);
