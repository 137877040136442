import { useSelector } from 'react-redux';
import { useIsDesktopDimension } from '@beef/ui-kit/hooks';

import { getHeaderContent } from 'pages/FancyNumber2022/store/selectors/content';

/** Хук подготовки данных для компонента заголовка страницы.
 * Готовит заголовок и флаг показа анимации падающих номеров для BannerLayout */
export const useBannerLayout = () => {
  /** Контентный заголовок */
  const { title } = useSelector(getHeaderContent);

  /** Флаг определяет, является ли текущая просматриваемая версия страницы десктопным вариантом */
  const isDesktop = useIsDesktopDimension();

  return {
    title,
    isVisible: isDesktop,
  };
};
