import React, { FC } from 'react';
import { Button } from '@carnica/ui-kit';
import classnames from 'classnames/bind';

import { useBackButton } from './hooks/useBackButton';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

/** Кнопка возврата на предыдущий шаг */
export const BackButton: FC = () => {
  /** Хук подготовки данных для компонента BackButton */
  const { icon, isVisible, handleClick } = useBackButton();

  return isVisible ?
      <section className={cx('wrapper')}>
        <Button
          className={cx('wrapper__button')}
          iconLeft={icon}
          onClick={handleClick}
          onlyIcon
          type="button"
          view="secondary"
        />
      </section>
    : null;
};
