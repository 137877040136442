import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TIcon = {
  icon: string;
};

/** Компонент иконки для Status */
export const Icon: FC<TIcon> = ({ icon }) => <img alt="" className={cx('icon')} src={icon} />;
