import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@carnica/ui-kit';
import { Link } from '@beef/ui-kit';

import { Metric } from 'pages/MNPLanding/analytics';
import { Card } from 'pages/MNPLanding/components/Card';

import styles from './styles.pcss';
import { useQRBlock } from './hooks/useQRBlock';

const cx = classNames.bind(styles);

/**
 * Блок с переходом в мобильное приложение
 */
export const QRBlock: FC = () => {
  const { buttonTitle, description, imgQR, title, deeplink, isMobile, isMnpTariffEnabled } =
    useQRBlock();

  return (
    <Metric
      data={title}
      events={{
        yaMNPMetric: [isMnpTariffEnabled ? 'handleViewTariffQrBlock' : 'handleViewQRBlock'],
      }}
      mode="viewport"
    >
      <Card
        className={cx('card', { 'card--tariff': isMnpTariffEnabled })}
        style={isMobile ? {} : { backgroundImage: `url(${imgQR})` }}
      >
        <div className={cx('info-block')}>
          <Text color={isMnpTariffEnabled ? 'primary' : 'primary-invert'} variant="headline-small">
            {title}
          </Text>

          <Text color={isMnpTariffEnabled ? 'secondary' : 'secondary-invert'} variant="body-small">
            {description}
          </Text>
        </div>

        {isMobile && (
          <Link className={cx('link')} href={deeplink} target="_blank">
            <Button size="m" view={isMnpTariffEnabled ? 'tertiary' : 'secondary'}>
              {buttonTitle}
            </Button>
          </Link>
        )}
      </Card>
    </Metric>
  );
};
