import { useAppDispatch, useAppSelector } from 'pages/MNPLanding/store';
import {
  changePhoneNumber,
  clearPhoneInput,
  clearPhoneInputError,
} from 'pages/MNPLanding/store/slices/mnpFormSlice';
import { isFilledPhone } from 'utils/format-string';
import {
  selectPhoneInputError,
  selectPhoneNumber,
} from 'pages/MNPLanding/store/slices/mnpFormSlice/selectors';
import { selectInputBlockContent } from 'pages/MNPLanding/store/slices/commonSlice/selectors';

import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';

/**
 * Хук c данными и обработчиками для инпута с номером телефона
 */
export const usePhoneInput = () => {
  const phoneNumber = useAppSelector(selectPhoneNumber);
  const phoneInputError = useAppSelector(selectPhoneInputError);
  const { placeholder: phonePlaceholder } = useAppSelector(selectInputBlockContent);

  const dispatch = useAppDispatch();

  const onChange = (newValue?: string) => {
    if (!newValue) {
      return;
    }

    /** Чистим ошибку перед вводом нового символа, если она есть */
    if (phoneInputError) {
      dispatch(clearPhoneInputError());
    }

    /**
     * Если номер имеет валидную длину, но пользователь продолжает
     * вводить символы - не меняем значение в стейте
     * */
    if (isFilledPhone(phoneNumber) && newValue.length > phoneNumber.length) {
      return;
    }

    /** Форматируем введенный номер под шаблон "+7 XXX XXX XX XX" */
    dispatch(changePhoneNumber(formatPhoneNumber(newValue)));
  };

  const onFocus = () => {
    /** Когда инпут оказывается в фокусе, то по-умолчанию ставим "+7" */
    if (!phoneNumber || !phoneNumber.startsWith('+7')) {
      dispatch(changePhoneNumber('+7'));
    }
  };

  const onBlur = () => {
    /** Если ничего не ввели, то чистим префикс (+7) и убираем ошибку */
    if (phoneNumber && phoneNumber.length <= 2) {
      dispatch(clearPhoneInput());
    }
  };

  const onClear = () => {
    /** Очистка инпута ввода номера и ошибки */
    dispatch(clearPhoneInput());
  };

  return {
    data: {
      phoneNumber,
      phoneInputError,
      phonePlaceholder,
    },
    handlers: {
      onChange,
      onFocus,
      onBlur,
      onClear,
    },
  };
};
