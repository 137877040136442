import React, { FC, memo, useMemo } from 'react';
import classNames from 'classnames/bind';
import { CloseIcon, DoneFilledIcon, Text } from '@beef/ui-kit';
import { cnTheme } from '@carnica/themes';
import { RootStateOrAny, connect } from 'react-redux';
import { IconLoading } from '@carnica/graphics';

import {
  getActiveFilter,
  getDefCodeValue,
  getQueryValue,
} from 'pages/FancyNumber2022/store/selectors/filter';
import { BUTTON_STATUS } from 'pages/FancyNumber2024/constants';
import {
  loadFilteredNumbers,
  setLaunchCaptchaRequest,
  setWidgetButtonState,
} from 'pages/FancyNumber2022/store/actions/numbers';
import { ThunkDispatchType } from 'pages/SPN/types';
import {
  getIsLoadNumbersError,
  getIsLoadNumbersSuccess,
  getIsLoading,
  getWidgetButtonState,
  selectInputDataFields,
  selectIsCaptchaEnabled,
  selectIsCaptchaInvalid,
} from 'pages/FancyNumber2022/store/selectors/numbers';
import { wrapAction } from 'pages/FancyNumber2024/utils';
import { CaptchaWrapper } from 'pages/FancyNumber2024/components/CaptchaWrapper';
import { getIsChangeNumber } from 'pages/FancyNumber2022/store/selectors/change';
import { setResetWidgetFilters } from 'pages/FancyNumber2022/store/actions/filter';
import { getWidgetButtonsContent } from 'pages/FancyNumber2022/store/selectors/content';
import { CustomSmartButtonProps } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/CustomSmartButton/types';
import { useCustomSmartButton } from 'pages/FancyNumber2024/components/NumberSelectionWidget/components/CustomSmartButton/hook';

import styles from './styles.pcss';

const cx = classNames.bind(styles);
const theme = cnTheme('light');

// TODO: переписать компонент при рефакторинге
export const _CustomSmartButton: FC<CustomSmartButtonProps> = memo(
  ({
    query,
    onLoadFilteredNumbers,
    isLoadingNumbers,
    isLoadNumbersError,
    onReset,
    isCaptchaEnabled,
    onSetLaunchCaptchaRequest,
    isAuth = true,
    content,
    inputDataFields,
    onSetResetWidgetFilters,
    stateButton,
    onSetWidgetButtonState,
    isChangeNumber,
  }) => {
    const { buttonDisabledConditions, getButtonText, handleResetClick, handleButtonClick } =
      useCustomSmartButton({
        isLoadingNumbers,
        isAuth,
        inputDataFields,
        onSetWidgetButtonState,
        stateButton,
        query,
        isLoadNumbersError,
        isCaptchaEnabled,
        onSetLaunchCaptchaRequest,
        onLoadFilteredNumbers,
        content,
        onReset,
        onSetResetWidgetFilters,
        isChangeNumber,
      });

    const CaptchaWrapperInstance = useMemo(() => {
      if (isCaptchaEnabled && isAuth) {
        return <CaptchaWrapper />;
      }
      return null;
    }, [isAuth, isCaptchaEnabled]);

    return (
      <div className={cx('wrapper')}>
        {CaptchaWrapperInstance && (
          <div className={cx('captcha-block')}>{CaptchaWrapperInstance}</div>
        )}

        <div
          className={cx('button-container', theme, {
            'button-container--success': stateButton === BUTTON_STATUS.success,
          })}
        >
          {stateButton === BUTTON_STATUS.reset && (
            <button
              aria-label="reset"
              className={cx('reset-button')}
              onClick={handleResetClick}
              type="button"
            >
              <CloseIcon fill="#98A0B0" size="m" />
            </button>
          )}

          <button
            className={cx('smart-button', `smart-button--${stateButton}`, {
              'smart-button--noAuth': !isAuth,
            })}
            disabled={buttonDisabledConditions}
            onClick={handleButtonClick}
            type="button"
          >
            {(stateButton === BUTTON_STATUS.default || stateButton === BUTTON_STATUS.reset) && (
              <Text size="size5-r-s">{getButtonText()}</Text>
            )}
            {stateButton === BUTTON_STATUS.loading && (
              <div className={cx('loader')}>
                <IconLoading />
              </div>
            )}
            {stateButton === BUTTON_STATUS.success && (
              <span className={cx('checkmark')}>
                <DoneFilledIcon fill="#28303F" size="m" />
              </span>
            )}
          </button>
        </div>
      </div>
    );
  },
);

const mapStateToProps = (state: RootStateOrAny) => ({
  content: getWidgetButtonsContent(state),
  query: getQueryValue(state),
  defCode: getDefCodeValue(state),
  filter: getActiveFilter(state),
  inputDataFields: selectInputDataFields(state) || [],
  isLoadingNumbers: getIsLoading(state),
  isLoadNumbersSuccess: getIsLoadNumbersSuccess(state),
  isLoadNumbersError: getIsLoadNumbersError(state),
  isCaptchaEnabled: selectIsCaptchaEnabled(state),
  isCaptchaInvalid: selectIsCaptchaInvalid(state),
  stateButton: getWidgetButtonState(state),
  isChangeNumber: getIsChangeNumber(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  onLoadFilteredNumbers: () => dispatch(loadFilteredNumbers),
  onSetLaunchCaptchaRequest: wrapAction(dispatch, setLaunchCaptchaRequest),
  onSetResetWidgetFilters: wrapAction(dispatch, setResetWidgetFilters),
  onSetWidgetButtonState: wrapAction(dispatch, setWidgetButtonState),
});

export const CustomSmartButton = connect(mapStateToProps, mapDispatchToProps)(_CustomSmartButton);
