import React, { memo } from 'react';
import { Text } from '@beef/ui-kit';
import { RootStateOrAny, connect } from 'react-redux';
import classnames from 'classnames/bind';

import { isEmptyValue } from 'utils/isEmptyValue';
import {
  getActiveFilter,
  getDefCodeValue,
  getQueryValue,
} from 'pages/FancyNumber2022/store/selectors/filter';
import {
  getChangeNumberList,
  getError,
  getIsLoadNumbersError,
  getIsLoading,
  getNumbersList,
  selectInputDataFields,
} from 'pages/FancyNumber2022/store/selectors/numbers';
import {
  getMainNumberBlockContent,
  getMainNumberEmptyTitle,
} from 'pages/FancyNumber2022/store/selectors/content';
import { useNumbersTitle } from 'pages/FancyNumber2024/components/NumbersBlock/components/NumbersTitle/hook';
import { NumbersTitlePropsType } from 'pages/FancyNumber2024/components/NumbersBlock/components/NumbersTitle/types';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const _NumbersTitle = memo<NumbersTitlePropsType>(
  ({
    inputDataFields,
    defCode,
    loading,
    content,
    emptyContent,
    filter,
    query,
    isLoadNumbersError,
    isError,
    isEmptyNumbers,
  }) => {
    const { headerTitle, headerDesc } = useNumbersTitle({
      content,
      inputDataFields,
      isLoadNumbersError,
      isError,
      emptyContent,
      filter,
      loading,
      defCode,
      query,
      isEmptyNumbers,
    });

    return (
      <div className={cx('info-number-wrapper')}>
        <Text className={cx('title')} size="size2-m">
          {headerTitle}
        </Text>

        {headerDesc && (
          <Text className={cx('description')} size="size4-m">
            {headerDesc}
          </Text>
        )}
      </div>
    );
  },
);

const mapStateToProps = (state: RootStateOrAny) => ({
  query: getQueryValue(state),
  defCode: getDefCodeValue(state),
  inputDataFields: selectInputDataFields(state) || [],
  loading: getIsLoading(state),
  content: getMainNumberBlockContent(state),
  emptyContent: getMainNumberEmptyTitle(state),
  filter: getActiveFilter(state),
  isLoadNumbersError: getIsLoadNumbersError(state),
  isError: getError(state),
  /* Если по какой-то причине нет номеров, флаг вернет true */
  isEmptyNumbers: isEmptyValue(getNumbersList(state) || getChangeNumberList(state)),
});

export const NumbersTitle = connect(mapStateToProps)(_NumbersTitle);
