import { useCallback, useEffect, useMemo } from 'react';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { BUTTON_STATUS } from 'pages/FancyNumber2024/constants';
import { redirectToLogin } from 'utils/redirectToLogin';
import { smoothScrollToTop } from 'pages/FancyNumber2024/utils';

// TODO: перепишем компонент в рамках рефакторинга!
export const useCustomSmartButton = ({
  isLoadingNumbers,
  isAuth,
  inputDataFields,
  onSetWidgetButtonState,
  stateButton,
  query,
  isLoadNumbersError,
  isCaptchaEnabled,
  onSetLaunchCaptchaRequest,
  onLoadFilteredNumbers,
  content,
  onReset,
  onSetResetWidgetFilters,
  isChangeNumber,
}) => {
  const filledFieldsCount = useMemo(
    () => inputDataFields.filter((field) => field?.value !== '').length,
    [inputDataFields],
  );
  const isMobile = useIsMobileDimension();

  useEffect(() => {
    if (!isAuth) {
      onSetWidgetButtonState('default');
      return;
    }

    if (isLoadingNumbers) {
      onSetWidgetButtonState(BUTTON_STATUS.loading);
    } else if (stateButton === BUTTON_STATUS.reset) {
      onSetWidgetButtonState(BUTTON_STATUS.reset);
    } else if (
      (stateButton === BUTTON_STATUS.success && filledFieldsCount) ||
      (stateButton === BUTTON_STATUS.success && query)
    ) {
      onSetWidgetButtonState(BUTTON_STATUS.success);

      const timer = setTimeout(() => {
        onSetWidgetButtonState(BUTTON_STATUS.reset);
      }, 1500);

      // eslint-disable-next-line consistent-return
      return () => clearTimeout(timer);
    } else if (isLoadNumbersError) {
      onSetWidgetButtonState(BUTTON_STATUS.default);
    } else {
      onSetWidgetButtonState(BUTTON_STATUS.default);
    }
  }, [isAuth, isLoadingNumbers, isLoadNumbersError, stateButton, onSetWidgetButtonState]);

  const handleButtonClick = useCallback(() => {
    if (!isAuth) {
      redirectToLogin();
      return;
    }

    if (stateButton === BUTTON_STATUS.default || stateButton === BUTTON_STATUS.reset) {
      if (isCaptchaEnabled) {
        onSetLaunchCaptchaRequest(true);
        return;
      }

      onLoadFilteredNumbers();

      if (!isMobile) {
        smoothScrollToTop({ top: 485 });
      } else {
        smoothScrollToTop({ top: 795 });
      }
    }
  }, [
    isAuth,
    isCaptchaEnabled,
    isMobile,
    onLoadFilteredNumbers,
    onSetLaunchCaptchaRequest,
    stateButton,
  ]);

  const handleResetClick = useCallback(() => {
    if (onReset) {
      onReset?.();
      return;
    }

    if (!isMobile) {
      smoothScrollToTop({ top: 485 });
    } else {
      smoothScrollToTop({ top: 795 });
    }

    onSetResetWidgetFilters(true);
    onSetWidgetButtonState(BUTTON_STATUS.default);
  }, [isMobile, onReset, onSetResetWidgetFilters, onSetWidgetButtonState]);

  const getButtonText = () => {
    if (stateButton === BUTTON_STATUS.default) {
      return !isAuth ? content?.auth : content?.default;
    }
    if (stateButton === BUTTON_STATUS.reset) {
      return content?.reset;
    }
    return '';
  };

  /** Переменная вернет true, если длина цифр в поле поиска номера меньше двух (query < 2) */
  const isQueryTooShort = query.length < 2;

  /** Если номера грузятся (isLoadingNumbers),
   *  текущий статус кнопки success,
   *  isQueryTooShort и мы находимся в НЗ на вкладке смены номера,
   *  мы авторизованы и isQueryTooShort - блокируем кнопку */
  const buttonDisabledConditions =
    isLoadingNumbers ||
    stateButton === BUTTON_STATUS.success ||
    (isQueryTooShort && !isChangeNumber && !isAuth) ||
    (isAuth && isQueryTooShort);

  return {
    buttonDisabledConditions,
    getButtonText,
    handleResetClick,
    handleButtonClick,
    isMobile,
    filledFieldsCount,
  };
};
